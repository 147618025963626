import * as React from 'react';
import { styled } from '@mui/system';
import { Tabs, Tab, useMediaQuery } from '@mui/material';
import { tabClasses } from '@mui/material/Tab';
import { buttonClasses } from '@mui/material/Button';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

export default function DynamicToggleTabs({ onSelectTab, value1, value2 }) {
  const colorObj = {
    50: '#fff',
    100: '#C2E0FF',
    200: '#80BFFF',
    300: '#66B2FF',
    400: '#F0F7FF',
    500: 'rgba(217, 217, 217, 1)',
    600: '#000000',
    700: '#0059B2',
    800: '#004C99',
    900: '#003A75',
  };

  const StyledTab = styled(Tab)`
    font-family: 'IBM Plex Sans', sans-serif;
    color: rgba(76, 78, 100, 0.87);
    text-transform: capitalize;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: bold;
    background-color: transparent;
    line-height: 1.5;
    padding: 8px 12px;
    margin: 6px;
    border: none;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    min-height: 32px;

    &:hover {
      background-color: rgba(237, 50, 55, 1);
      color: white;
    }

    &:focus {
      color: ${colorObj[600]};
      outline: 3px solid ${colorObj[200]};
    }

    &.${tabClasses.selected} {
      background-color: rgba(237, 50, 55, 1);
      color: white;
    }

    &.${buttonClasses.disabled} {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `;

  const StyledTabs = styled(Tabs)`
    min-width: ${props => (props.smScreen ? '100px' : '255px')};
    height: 40px;
    background-color: ${colorObj[500]};
    border-radius: 12px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: ${props => (props.theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.4)' : 'rgba(0,0,0, 0.2)')};
  `;

  const [selectedTab, setSelectedTab] = React.useState(2);
  const smScreen = useMediaQuery('(max-width:600px)');
  const switchValue = useSelector((state) => state.royaltyDataReducer.switchRevenueValue);

  useEffect(() => {
    if (switchValue && (value1 === "Overall Royalty" || value1 === "Studio Royalty")) {
      setSelectedTab(switchValue);
      onSelectTab(switchValue);
    }
  }, [switchValue, onSelectTab, value1]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    onSelectTab(newValue);
  };

  return (
    <StyledTabs
      value={selectedTab}
      onChange={handleTabChange}
      variant="scrollable"
      scrollButtons="auto"
      aria-label="scrollable auto tabs example"
      smScreen={smScreen}
      TabIndicatorProps={{
        style: {
          display: 'none',
        },
      }}
    >
      <StyledTab label={value1} value={2} />
      <StyledTab label={value2} value={1} />
    </StyledTabs>
  );
}
