import axios from "axios";
import { getArtistDataApi } from "../_constants/api.constant";
import { getSelfToken } from "../../_helpers/auth/secureToken";
// import { getSelfToken } from '../../_helpers/auth/secureToken'
export async function getCreaterData(postData,actiontype,limit,offset) {
    switch(actiontype)
    {
     case 'GET':
            {
    const response = await axios.get(
        getArtistDataApi.GET, 
        {
            headers: {
                Authorization: 'Token ' + getSelfToken(),
            },
            params:{
                limit:limit,offset:offset
            }
        }
    ).then(function (response) {
        console.log(response);
        return response;
    }).catch(function (error) {
        // handle error
        console.log(error);
        console.log(error.response);
        return error.response;
    });
    return response;
}
case 'GETARTIST':
    {
        const response = await axios.get(
            getArtistDataApi.GETARTIST+'/'+postData.artist_id+'/',
            { 
                headers: {
                    Authorization: 'Token ' + getSelfToken(),
                },
            }
        ).then(function (response) {
            return response;
        }).catch(function (error) {
            // handle error
            console.log(error);
            return error.response;
        });
        return response;
    }
    case 'SEARCH':
        {
            const response = await axios.get(
                getArtistDataApi.SEARCH,
                {   
                params:{search:postData.searchData,
                    limit:limit,offset:offset
                },
                    headers: {
                        Authorization: 'Token ' + getSelfToken(),
                    },
                }
            ).then(function (response) {
                return response;
            }).catch(function (error) {
                // handle error
                console.log(error);
                return error.response;
            });
            return response;
        }  
         
    default:{return}
}
}