import React, { useEffect, useState } from 'react';
import TableComponent from './Tablecomponent';
import { Link, Paper, Box, Typography, Avatar } from '@mui/material';
import { getSongStatusBgColor } from '../../../_helpers/reusablefunctions/getColor';
export default function AlbumDetail({ studioDetail }) {

    const [albumDetailList, setAlbumDetailList] = useState([])

    useEffect(() => {
        if (studioDetail.length) {
            setAlbumDetailList(studioDetail[0].albums)
        }
    }, [studioDetail])
    const rows = albumDetailList.length ? albumDetailList : [];
    const columns = [
        {
            id: 'album_name', label: 'Album Name', headerName: '', flex: 1, template: (row) => {
                return (
                    <Box className='d-flex gap-2 align-items-center'>
                        <Avatar alt="user" src={row.cover_image ? process.env.REACT_APP_SONGAPIURL_MEDIA + 'media/' + row.cover_image : ''} />{row.album_name}</Box>
                );
            }
        },
        {
            id: 'upc_code', label: 'UPC Code', headerName: '', flex: 1, template: (row) => {
                return (
                    <Box>{row.upc_code ? row.upc_code : '--'}</Box>
                );
            }
        },
        {
            id: 'song_status', label: 'Song Status', headerName: '', flex: 1, template: (row) => {
                return (
                    <Box className="status text-light p-2" style={{ background: getSongStatusBgColor(row.song_status), minWidth: '80px' }}>
                        {row.song_status}
                    </Box>
                );
            }
        }
    ];

    return (
        <Paper sx={{ width: '100%', mb: 5, mt: 4 }}>
            <Typography variant='h6' className='p-3'>Album Detail</Typography>
            <TableComponent columns={columns} data={rows} />
        </Paper>
    );
}
