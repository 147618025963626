import React from 'react'
import Dashboard from '../dashboard/dashboard'
import Button from '@mui/material/Button';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

// import { Link } from 'react-router-dom'

function Invoice() {
  return (
    <div>
        {/* <Dashboard> */}
            <div className="row">
                <div className="col-md-12">
                    <div className="">
                    <a href="#" className="p-0">
                    <Button className="text-black text-capitalize" href="#text-buttons" startIcon={<KeyboardBackspaceIcon />}>
                      Back
                    </Button>
                    </a>                   
                     </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2 className="py-2">Invoice</h2>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow">
                            <div className="row p-5">
                                <div className="col-md-3">
                                    <h5>Groovenexus</h5>
                                    <p className="text-break">814, 8TH FLOOR,TOWER -1 ASSOTECH BUSINESS CRESTERRA PLOT NO. 22 SECTOR 135, NOIDA -201305</p>
                                </div>
                                <div className="col-md-7">
                                    <div className="">
                                        <p>Number: <span>Pending </span></p>
                                        <p>Unique Id:<span>#759089080</span></p>
                                        <p>Issued:<span>mm-dd-yyyy </span></p>
                                        <p>Due: <span></span>mm-dd-yyyy</p>
                                    </div>
                                </div>
                                <div className="border-bottom py-3"></div>
                            </div>
                            <div className="row p-5">
                                <div className="col-md-3">
                                    <h5>Invoiced To: </h5>
                                    <p className="text-break">Sonal Kandhway India</p>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow">
                           <div className="card-header bg-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <h6>Item</h6>
                                    </div>
                                    <div className="col-md-4">
                                        <h6>Price</h6>
                                    </div>
                                    <div className="col-md-4">
                                        <h6>Item Total</h6>
                                    </div>
                                </div>
                           </div>
                           <div className="card-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <p>Press Release</p>

                                    </div>
                                    <div className="col-md-4">
                                       <p>$300.00</p>
                                    </div>
                                    <div className="col-md-4">
                                       <p>$200.00</p>
                                       <p>Discount:<span>$50</span></p>
                                       <p>Tax:<span>$28.00</span></p>
                                       <p>Total:<span>$170.00</span></p>
                                    </div>
                                </div>
                           </div>
                        </div>
                    </div>
                </div>
            </div>

        {/* </Dashboard> */}
    </div>
  )
}

export default Invoice