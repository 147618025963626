import axios from "axios";
import {  getSelfToken } from '../../_helpers/auth/secureToken'
import { showAllDemo} from "../_constants/api.constant";
export async function getDemo(limit,offset) {
    const response = await axios.get(
        showAllDemo.GET,
        {
            headers: {
                Authorization: 'Token ' + getSelfToken(),
            },
            params:{
                limit:limit,offset:offset
            }
        }
    ).then(function (response) {
        return response;
    }).catch(function (error) {
        // handle error
        console.log(error);
        console.log(error.response);
        return error.response;
    });
    return response;
}