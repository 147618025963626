// export const authApi = {
//    LOGIN: process.env.REACT_APP_BASEURL + 'user_auth/login/',
// }
 /*Login User Details Api*/

// export const registerApi = {
//    REGISTER: process.env.REACT_APP_BASEURL + 'user_auth/register',
//    // REGISTER:'https://content_creater_gnapi.eela.tech/api/register',
// }
/*Register Api*/
export const registerApi = {
   REGISTER: process.env.REACT_APP_BASEURL + 'user/register/',
   // REGISTER:'https://content_creater_gnapi.eela.tech/api/register',
}
export const  activateUserAccount = {
   GET: process.env.REACT_APP_BASEURL + 'user/activate/',
}
export const resetPasswordApi = {
   RESET:process.env.REACT_APP_BASEURL + 'user/passwordreset/',
}
export const ordersApi = {
   ORDERS: process.env.REACT_APP_BASEURL + 'api/order',
}
export const userInfoApi = {
   // https://adminapidev.gncreators.com/user/users?id=3
   // VIEW: 'https://content_creater_gnapi.eela.tech/api/v1/user/1',
   VIEW: process.env.REACT_APP_BASEURL + 'user/users',//'http://gncreatorsdev.gncreators.com/user_auth/users' //process.env.REACT_APP_BASEURL+'api/login-user',
}
export const loginUserApi = {
   VIEW: process.env.REACT_APP_BASEURL + 'user/users',//'https://content_creater_gnapi.eela.tech/api/v1/userTT/ Need to remove this service call',
   // VIEW: process.env.REACT_APP_BASEURL+'api/login-user',
}
export const userTypeApi = {
   VIEW: process.env.REACT_APP_BASEURL + '/content_creator/userType/' //'https://content_creater_gnapi.eela.tech/api/v1/userType',
   // VIEW: process.env.REACT_APP_BASEURL+'api/login-user',
}
export const updateUserInfoApi = {
   UPDATE:  process.env.REACT_APP_BASEURL + 'user_auth/users/edit',//'content_creator/user',//'https://content_creater_gnapi.eela.tech/api/v1/user/',
   // VIEW: process.env.REACT_APP_BASEURL+'api/login-user',
}

export const userApi = {
   LIST: process.env.REACT_APP_BASEURL + 'api/v1/users',
}

// ___________________ PROFILE API'S________________________
// export const socialApi = {
//    LIST: 'https://content_creater_gnapi.eela.tech/api/v1/link/',
// }
export const userProfileApi = {
   UPDATE: process.env.REACT_APP_BASEURL +'user_auth/users/edit',//'https://content_creater_gnapi.eela.tech/api/v1/user/', to update the user profile data
   UPDATEPROFILE:process.env.REACT_APP_BASEURL +'user_auth/user'
}
export const getCategoryApi = {
   GET: process.env.REACT_APP_BASEURL + 'content_creator/category/', //'https://content_creater_gnapi.eela.tech/api/v1/category',
}
export const getLanguageApi = {
   GET: process.env.REACT_APP_BASEURL + 'content_creator/language/',// 'https://content_creater_gnapi.eela.tech/api/v1/language',
}
export const updateLanguageApi = {
   UPDATE: process.env.REACT_APP_BASEURL + 'content_creator/language',//'https://content_creater_gnapi.eela.tech/api/v1/language/',
}
export const updateCategoryApi = {
   ADD: process.env.REACT_APP_BASEURL + 'content_creator/category', //'https://content_creater_gnapi.eela.tech/api/v1/category/',
   UPDATE: process.env.REACT_APP_BASEURL + 'content_creator/category'
}
export const getGenreApi = {
   GET: process.env.REACT_APP_BASEURL + 'user/releases/genre/'//'https://content_creater_gnapi.eela.tech/api/v1/genre',
}
export const updateGenreApi = {
   UPDATE: process.env.REACT_APP_BASEURL + 'content_creator/genre', //'https://content_creater_gnapi.eela.tech/api/v1/genre/',
}
export const getExpertiseApi = {
   GET: process.env.REACT_APP_BASEURL + 'content_creator/expertiseLevel/', //'https://content_creater_gnapi.eela.tech/api/v1/expertiseLevel',
   UPDATE:process.env.REACT_APP_BASEURL + 'content_creator/expertiseLevel'
}
export const getExperienceApi = {
   GET: process.env.REACT_APP_BASEURL + 'content_creator/experience/user',//'https://content_creater_gnapi.eela.tech/api/v1/experience',
   POST:process.env.REACT_APP_BASEURL + 'content_creator/experience',
   UPDATE:process.env.REACT_APP_BASEURL + 'content_creator/experience',
}
export const deleteExperienceApi = {
   DELETE: process.env.REACT_APP_BASEURL + 'content_creator/experience',//'https://content_creater_gnapi.eela.tech/api/v1/experience/',
}
export const userHandleApi = {
   GET: process.env.REACT_APP_BASEURL + 'user_auth/check-userHandle/',//https://content_creater_gnapi.eela.tech/api/v1/check-userHandle'
}

export const userSocialTypeApi = {
   GET: process.env.REACT_APP_BASEURL + 'content_creator/social-type/' //'https://content_creater_gnapi.eela.tech/api/v1/social-type'
}

export const userSocialLinkApi = {
   GET: process.env.REACT_APP_BASEURL + 'content_creator/social-link/user/' //'https://content_creater_gnapi.eela.tech/api/v1/social-link'
}
export const userPostSocialLinkApi = {
   POST: process.env.REACT_APP_BASEURL + 'content_creator/social',//'https://content_creater_gnapi.eela.tech/api/v1/social-link',
   UPDATE:  process.env.REACT_APP_BASEURL + 'content_creator/social',
}

export const userDeleteSocialLinkApi = {
   DELETE: process.env.REACT_APP_BASEURL + 'content_creator/social-link',//'https://content_creater_gnapi.eela.tech/api/v1/social-link/'
}
export const getCityApi= {
   GET: process.env.REACT_APP_BASEURL + 'content_creator/city/' ,//'https://content_creater_gnapi.eela.tech/api/v1/city'
}
export const getCountryApi= {
   GET: process.env.REACT_APP_BASEURL + 'content_creator/country/',//'https://content_creater_gnapi.eela.tech/api/v1/country'
}
export const getStateApi= {
   GET: process.env.REACT_APP_BASEURL + 'content_creator/state/', //'https://content_creater_gnapi.eela.tech/api/v1/state'
}
export const createrLink= {
   GET: process.env.REACT_APP_BASEURL + 'content_creator/link/user/', //"https://content_creater_gnapi.eela.tech/api/v1/link/"
   POST: process.env.REACT_APP_BASEURL + 'content_creator/link'
}

export const getThumbnailLink = {
   GET:process.env.REACT_APP_BASEURL  + 'content_creator/thumbnail/', //"http://gncreatorsdev.gncreators.com/content_creator/thumbnail/"
}
export const updateSequenceLink = {
   PUT:process.env.REACT_APP_BASEURL  + 'content_creator/update-sequence/', //"http://gncreatorsdev.gncreators.com/content_creator/thumbnail/"
}

export const commonMusicLink = {
   GETALL:process.env.REACT_APP_BASEURL + 'content_creator/getSongList/',
   UPDATE:process.env.REACT_APP_BASEURL + 'content_creator/createSongList',
   GETSINGLE:process.env.REACT_APP_BASEURL + 'content_creator/createSongList',
   POST:process.env.REACT_APP_BASEURL + 'content_creator/createSongList',
   DELETE:process.env.REACT_APP_BASEURL + 'content_creator/createSongList',
   GETBYUSER:process.env.REACT_APP_BASEURL + 'content_creator/getsongList/user',
   SETSONGSEQUENCE:process.env.REACT_APP_BASEURL + 'content_creator/song-sequence/',

}

export const tagsListURL = {
   GET:process.env.REACT_APP_BASEURL + 'content_creator/tags/',
   POST:process.env.REACT_APP_BASEURL + 'content_creator/tags',
}

export const streamingPlatformURL = {
   GET:process.env.REACT_APP_BASEURL + 'content_creator/streamingPlatforms/',
}

export const songRelatedPlatformURL = {
   POST:process.env.REACT_APP_BASEURL + 'content_creator/relatedStreamingPlatforms',
   GETALL:process.env.REACT_APP_BASEURL + 'content_creator/streamingPlatforms/',
   GETBYSONG:process.env.REACT_APP_BASEURL + 'content_creator/getrelatedStreamingPlatforms/song',
   PUT:process.env.REACT_APP_BASEURL + 'content_creator/relatedStreamingPlatforms',
   DELETE:process.env.REACT_APP_BASEURL + 'content_creator/relatedStreamingPlatforms',
}
/* export const getUserTypesLink = {
   GET:"http://gncreatorsdev.gncreators.com/content_creator/userType/"
} */

export const verifyArtistURL = {
   //POST:process.env.REACT_APP_BASEURL + 'content_creator/verifiedUser',
   POST:process.env.REACT_APP_BASEURL+'user/artistVerificationDetails',
   PUT:process.env.REACT_APP_BASEURL + 'content_creator/verifiedUser',
   
}
export const artistEnquiryURL = {
   GET:process.env.REACT_APP_BASEURL + 'content_creator/enquiry/user/',
   DELETE:process.env.REACT_APP_BASEURL + 'content_creator/enquiry',
   POST:process.env.REACT_APP_BASEURL + 'content_creator/enquiry/delete_multiple/',


} 
// for song service
export const submitAsongURL={
   POST:process.env.REACT_APP_SONGAPIURL+'user/releases/songmeta',
   GET:process.env.REACT_APP_SONGAPIURL+'user/releases/songmeta/',
   DELETE:process.env.REACT_APP_SONGAPIURL+'user/releases/songmeta/',
   GETFORSPECIFC:process.env.REACT_APP_SONGAPIURL+'user/releases/songmeta/creator/',
   UPDATE:process.env.REACT_APP_SONGAPIURL+'user/releases/songmeta/',
   GETFORSEPECICARTIST:process.env.REACT_APP_SONGAPIURL+'user/releases/songmetaderived/creator/',
   SEARCH:process.env.REACT_APP_SONGAPIURL+'user/releases/getall',
   GETASSOCIATEDARTISTINFO:process.env.REACT_APP_SONGAPIURL+'user/releases/associatedArtist/',
   ADVANCESEARCH:process.env.REACT_APP_SONGAPIURL+'user/releases/songsearchall',
   GETSINGLESONG:process.env.REACT_APP_SONGAPIURL+'user/releases/songmetaId/creator',
   GETSINGLESONGDISPLAY:process.env.REACT_APP_SONGAPIURL+'user/releases/songmetaId',
}
export const showAllSong={
   GET:process.env.REACT_APP_SONGAPIURL+'user/releases/songmeta/',
   GETFORSEPECICARTIST:process.env.REACT_APP_SONGAPIURL+'user/releases/songmetaderived/creator/',
   GETFORSPECIFC:process.env.REACT_APP_SONGAPIURL+'user/releases/songmeta/creator/',



}
export const contentTypeURL = {
   GET:process.env.REACT_APP_BASEURL + 'user/releases/content_type/',
}

export const submitDemoURL={
   POST:process.env.REACT_APP_CREATORURL + 'user/demo',
   GET:process.env.REACT_APP_CREATORURL + 'user/demo/',
   DELETE:process.env.REACT_APP_CREATORURL + 'user/demo',
   SEARCH:process.env.REACT_APP_CREATORURL + 'user/demosearch/',
}
export const showAllDemo={
   GET:process.env.REACT_APP_CREATORURL + 'user/demo/',
}

export const moodURL={
   GET:process.env.REACT_APP_BASEURL + 'user/releases/mood/',
}
export const subgenreURL={
   GET:process.env.REACT_APP_BASEURL + 'user/releases/sub_genre/',
}
// export const assoicatedArtistURL={
//    GET:process.env.REACT_APP_BASEURL + 'user/releases/content_creator/artists/',
//    SPECIFICGET:process.env.REACT_APP_BASEURL + 'content_creator/artist',
//    POST:process.env.REACT_APP_BASEURL + 'content_creator/artist',
//    UPDATE:process.env.REACT_APP_BASEURL + 'content_creator/artist',
//    DELETE:process.env.REACT_APP_BASEURL + 'content_creator/artist',
//    SEARCH:process.env.REACT_APP_BASEURL + 'content_creator/artistname/',
// }
/*Show all artist api*/
export const getArtistDataApi = {
   GET: process.env.REACT_APP_BASEURL + 'user/showall/', //'https://content_creater_gnapi.eela.tech/api/v1/category',
   SEARCH: process.env.REACT_APP_BASEURL +'user/details/'
}
// export const authApi = {
//    LOGIN: process.env.REACT_APP_BASEURL + 'user_auth/login/',
//    USER: process.env.REACT_APP_BASEURL + 'user/user/'
// }
export const authApi = {
   // VIEW: 'https://content_creater_gnapi.eela.tech/api/v1/user/1',
   LOGIN: process.env.REACT_APP_BASEURL + 'user/login/',//'http://gncreatorsdev.gncreators.com/user_auth/users' //process.env.REACT_APP_BASEURL+'api/login-user',
   //USER: process.env.REACT_APP_BASEURL + 'user/user/' ,//https://adminapidev.gncreators.com/user/user/
}
export const getArtistVerificationDetailsApi = {
   GET: process.env.REACT_APP_BASEURL + 'user/artistVerificationDetails/',//https://adminapidev.gncreators.com/user/artistVerificationDetails/
 }

 export const getArtistDataByUsrHandleApi  = {
   GET: process.env.REACT_APP_BASEURL + 'user/findbyuserhandle/',//https://adminapidev.gncreators.com/user/artistVerificationDetails/
 }
 export const setArtistVerificationStatus = {
   PUT: process.env.REACT_APP_BASEURL + 'user/verify/',//https://adminapidev.gncreators.com/user/verify/
}

export const songApproverApi = {
   POST: process.env.REACT_APP_BASEURL + 'user/songApproval',
   UPDATE: process.env.REACT_APP_BASEURL + 'user/song_verify/',
   GET: process.env.REACT_APP_BASEURL+'user/songApproval/',
   ALBUM_POST: process.env.REACT_APP_BASEURL+'user/albumApproval',
   ALBUM_APPROVER: process.env.REACT_APP_BASEURL+'user/album_verify/',
 }
// To get the creator and studio dashboard data

export const creatorAnalysisData = {
   GET: process.env.REACT_APP_BASEURL + 'user/artist-statistics/',
 }

export const studioAnalysisData = {
GET: process.env.REACT_APP_BASEURL + 'user/studio-statistics/',
}
export const AlbumEndpoint = {
GETALLALBUMDATA: process.env.REACT_APP_BASEURL + 'user/releases/albummeta',

POST: process.env.REACT_APP_BASEURL + 'user/releases/albummeta',
GETALL: process.env.REACT_APP_BASEURL + 'user/playlist/',
GETALLOFUSER: process.env.REACT_APP_BASEURL + 'user/releases/albummeta/studio/',
DELETE_ALBUM: process.env.REACT_APP_BASEURL + 'user/playlists',
GET_SINGLE_ALBUM: process.env.REACT_APP_BASEURL + 'releases/albummetaId',
UPDATE_ALBUM: process.env.REACT_APP_BASEURL + 'user/releases/albummeta/',
GET_ALL_SONGS_OF_USER: process.env.REACT_APP_BASEURL + 'user/getsongList/user/',
REMOVE_SONG_FROM_ALBUM: "https://gnsongapidev.gncreators.com/" + 'releases/albummeta/removesong/',
SEARCH_ALBUM: process.env.REACT_APP_BASEURL + 'user/playlist/',
SONG_REORDER_INALBUM: process.env.REACT_APP_BASEURL + 'user/releases/album-song-sequence/',
GET_ALBUM_SONG:process.env.REACT_APP_BASEURL + "user/releases/albumsongs",
GET_ALL_PLATFORM:process.env.REACT_APP_BASEURL+'user/releases/streamingPlatforms/'
}

export const importRevenue = {
 LIST  : process.env.REACT_APP_BASEURL + 'user/releases/import-log/',
 ADD  : process.env.REACT_APP_BASEURL + 'user/releases/import-release-collection',

}

export const updateGeneralApi = {
   UPDATE: process.env.REACT_APP_BASEURL +  'studio/studioedit/', 
}
export const PlanEndpoint = {
   GET_PLAN_LIST :process.env.REACT_APP_BASEURL + 'user/releases/plan/',
   GET_ORDER_DETAIL:process.env.REACT_APP_BASEURL + 'user/releases/payment/details/'
 }

 export const assoicatedArtistURL={
   GET:process.env.REACT_APP_BASEURL + 'user/releases/associated_artist/artists',
   SPECIFICGET:process.env.REACT_APP_BASEURL+'user/associated_artist/artist',
   POST:process.env.REACT_APP_BASEURL + 'user/associated_artist/artist',
   UPDATE:process.env.REACT_APP_BASEURL + 'user/associated_artist/artist',
   DELETE:process.env.REACT_APP_BASEURL + 'user/associated_artist/artist',//https://gncreatorsdev.gncreators.com/studio_info/artist/337/
   SEARCH:process.env.REACT_APP_BASEURL + 'userassociated_artist/artistname',
   //https://studioapidev.gncreators.com/associated_artist/artists?search=2
}