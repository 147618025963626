import React from 'react'
import { Box, Typography, Button } from '@mui/material';

const SingleDataCard = ({ icon, cardHeading, count, bg, cardbg, p5 }) => {
    const gridStyle = {
        width: '65px',
        height: '65px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
    return (

        <Box sx={{ width: '25%', display: 'flex', alignItems: 'center', gap: '10px', backgroundColor: cardbg, padding: '15px', borderRadius: '16px' }}>
            <Box className={`rounded-pill ${p5}`} sx={{ ...gridStyle, backgroundColor: bg }}><img src={icon} alt='table-view-icon' /></Box>
            <Box>
                <Typography variant="h6" fontSize={'14px'} fontWeight={'500'}>{cardHeading}</Typography>
                <Typography className='' variant="h4" fontSize={'24px'} fontWeight={'600'}>{count}</Typography>
            </Box>
        </Box>
    )
}

export default SingleDataCard