import axios from "axios";
import {  getSelfToken } from '../../_helpers/auth/secureToken'
import { showAllSong } from "../_constants/api.constant";
export async function getSong(actiontype,postData,limit,offset) {
    switch(actiontype)
    {
        case 'GET':
            {
              const response = await axios.get(
                    showAllSong.GET,
                    {
                        headers: {
                            Authorization: 'Token ' + getSelfToken(),
                        },
                    params:{
                        limit:limit,offset:offset
                    }
                    }
                ).then(function (response) {
                    return response;
                }).catch(function (error) {
                    // handle error
                    console.log(error);
                    console.log(error.response);
                    return error.response;
                });
                return response;
            }
            case 'GETFORSEPECICARTIST':
                {
                    const response = await axios.get(
                        showAllSong.GETFORSEPECICARTIST+postData.userId+'/',
                        {
                            headers: {
                                Authorization: 'Token ' + getSelfToken(),
                            },
                        }
                    ).then(function (response) {
                        return response;
                    }).catch(function (error) {
                        // handle error
                        console.log(error);
                        console.log(error.response);
                        return error.response;
                    });
                    return response;
                } 
            default:{return}
        }
}