import * as React from 'react';
import Box from '@mui/material/Box';
import { Button, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory, useLocation } from 'react-router-dom';

export default function EditRevenue({ payoutData, setPayoutData, payoutDataErrors, handleInputChange }) {
  const history = useHistory()
  const location = useLocation();
  const inputList = [
    { id: 'release_title', label: 'Release Title', value: location?.state?.state?.data.release_title },
    { id: 'track_title', label: 'Track Tittle', value: location?.state?.state?.data.track_title },
    { id: 'transaction_month', label: 'Transaction Month', value: location?.state?.state?.data.transaction_month },
    { id: 'label', label: 'Label', value: location?.state?.state?.data.label },
    { id: 'artist', label: 'Artist', value: location?.state?.state?.data.artist },
    { id: 'upc_code', label: 'UPC', value: location?.state?.state?.data.upc_code },
    { id: 'isrc_code', label: 'ISRC', value: location?.state?.state?.data.isrc_code },
    { id: 'release_catalog_id', label: 'Release Catalog ID', value: location?.state?.state?.data.release_catalog_id },
    { id: 'track_catalog_id', label: 'Track Catalog ID', value: location?.state?.state?.data.track_catalog_id },
    { id: 'format', label: 'Format', value: location?.state?.state?.data.format },
    { id: 'parent_sale_id', label: 'Parent Sale Id', value: location?.state?.state?.data.parent_sale_id },
    { id: 'transaction_id', label: 'Transaction ID', value: location?.state?.state?.data.transaction_id },
    { id: 'account_id', label: 'Account ID', value: location?.state?.state?.data.account_id },
    { id: 'contract_id', label: 'Contract ID', value: location?.state?.state?.data.contract_id },
    { id: 'payee_id', label: 'Payee ID', value: location?.state?.state?.data.payee_id },
    { id: 'platformName', label: 'Service', value: location?.state?.state?.data.platformName },
    { id: 'channel_type', label: 'Channel', value: location?.state?.state?.data.channel_type },
    { id: 'country_name', label: 'Territory', value: location?.state?.state?.data.country_name },
    { id: 'count', label: 'Quantity', value: location?.state?.state?.data.count },
    { id: 'gross_revenue', label: 'Gross Revenue in INR', value: location?.state?.state?.data.gross_revenue },
    { id: 'contract_rate', label: 'Contract Rate %', value: location?.state?.state?.data.contract_rate },
    { id: 'net_revenue', label: 'Net Revenue in INR', value: location?.state?.state?.data.net_revenue },
    { id: 'your_share', label: 'Your Share %', value: location?.state?.state?.data.your_share },
    { id: 'opening_balance', label: 'Opening Balance in INR', value: location?.state?.state?.data.opening_balance },
    { id: 'closing_balance', label: 'Closing Balance in INR', value: location?.state?.state?.data.closing_balance },
  ];

  const backroute = () => {
    history.push('/viewrevenue', { state: { data: location?.state?.state?.data } })
  }

  return (
    <Box className='m-3 mb-5 pb-5'>
      <Button className="icononly-btn w-100 d-flex align-items-center justify-content-start gap-3 text-body" onClick={backroute} ><ArrowBackIcon />{location?.state?.state?.data.release_title}</Button>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item container xs={12} direction={'row'} sx={{ margin: '0.5rem' }}>
          {inputList.map(d => (
            <Grid item xs={6} >
              <FormControl margin='dense' sx={{ padding: '5px' }}
                name={d.release_title}
                noValidate
                fullWidth={true}>
                <TextField
                  value={d.value}
                  defaultValue={d.value}
                  name={d.release_title}
                  label={d.label}
                  fullWidth size='small'
                  disabled />
              </FormControl>
            </Grid>
          ))}

        </Grid>
      </Grid>
    </Box>
  );
}