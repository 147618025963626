import React, { useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { Box, TableFooter } from "@mui/material";
import { Skeleton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { record_limits } from '../../_services/_constants/recordLimitset.constant';

const TableComponent = ({ columns, data, totalCount, page, setPage, rowsPerPage, setRowsPerPage, removeStyle, hidepagination }) => {
    const [orderBy, setOrderBy] = useState('');
    const [order, setOrder] = useState('asc');
    const loader = useSelector((state) => state.loader.loader);
    const skeletonData = [1, 2, 3, 4, 5, 6]
    const handleSort = (property) => (event) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const sortedData = data?.sort((a, b) => {
        if (order === 'asc') {
            return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
            return a[orderBy] > b[orderBy] ? -1 : 1;
        }
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const cellWidth = 200;

    return (
        <Box>
            <TableContainer className="table-container" sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column, index) => (
                                <TableCell
                                    sx={removeStyle ? {} : {
                                        position: 'sticky',
                                        left: index === 0 ? 0 : 'auto',
                                        zIndex: index === 0 ? 1 : 'auto',
                                        '&:firstOfType': { width: '50%' },
                                        borderRight: index === 0 ? '1px solid #0000001F' : 'none',
                                        boxShadow: index === 0 ? '0px 0px 2px 0px #0000004D' : 'none',
                                        width: cellWidth,
                                        minWidth: cellWidth,
                                        maxWidth: cellWidth,
                                    }}
                                    key={index}
                                    align={column.align}
                                >
                                    <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : 'asc'}
                                        onClick={handleSort(column.id)}
                                    >
                                        {column.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loader ? (
                            <TableRow>
                                <TableCell colSpan={columns.length}>
                                    {skeletonData.map((d, i) => (
                                        <Skeleton key={i} animation="wave" height={50} />
                                    ))}
                                </TableCell>
                            </TableRow>
                        ) : loader === false && data.length === 0 ? (
                            <TableRow>
                                <TableCell className="text-center p-4 text-bold" colSpan={columns.length}>
                                    NO DATA FOUND
                                </TableCell>
                            </TableRow>
                        ) : (
                            sortedData.map((row, rowIndex) => (
                                <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
                                    {columns.map((column, columnIndex) => (
                                        <TableCell
                                            sx={removeStyle ? {} : {
                                                position: columnIndex === 0 ? 'sticky' : 'static',
                                                left: columnIndex === 0 ? 0 : 'auto',
                                                zIndex: columnIndex === 0 ? 0 : 'auto',
                                                backgroundColor: columnIndex === 0 ? ' #FFF' : 'inherit',
                                                '&:firstOfType': { width: '50%' },
                                                borderRight: '1px solid #0000001F',
                                                boxShadow: columnIndex === 0 ? '0px 0px 2px 0px #0000004D' : 'none',
                                                width: cellWidth,
                                                minWidth: cellWidth,
                                                maxWidth: cellWidth,

                                            }}
                                            className="cursorPointer"
                                            key={column.id}
                                            align={column.align}
                                        >
                                            {column.template ? column.template(row) : row[column.id]}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {console.debug("tablecomponent: order", order)}
            <TablePagination
                rowsPerPageOptions={hidepagination ? [] : [record_limits]}
                component="div"
                count={!hidepagination ? parseInt(totalCount) : data.length}
                rowsPerPage={hidepagination ? data.length : rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>

    );
};

export default TableComponent;
