import React, { useEffect, useState } from 'react';
import { Grid, TablePagination } from '@mui/material';
import CardLayout from '../../components/reusableComponents/CardLayout';
import ResponsiveCardList from '../../components/reusableComponents/songResponsiveList';
import useMediaQuery from '@mui/material/useMediaQuery';
import NoResults from '../../components/reusableComponents/NoResults';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLoginUser, fetchSongReleaseList, setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { submitSong } from '../../_services/submitAsong/submitAsong.service';
import { formSnackbar } from '../../_services/_constants/snackbar.constant';
import { default_limit, record_limits } from '../../_services/_constants/recordLimitset.constant';

const SubmitSongCard = ({ songListData,handleChangePage,handleChangeRowsPerPage,page,rowsPerPage }) => {
  const smScreen = useMediaQuery('(max-width:768px)');
  const [songList, setSongList] = useState(songListData && songListData.results?songListData.results:[]);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData.userData);
  const [totalCount,setTotalCount] = useState(songListData?songListData.total_count:0)
  const isNextButtonDisabled = songList.length < rowsPerPage || (page + 1) >= Math.ceil(totalCount / rowsPerPage);


/*   useEffect(() => {
    if (userData.id) {
      dispatch(fetchLoginUser(userData.id));
      dispatch(fetchSongReleaseList());
    }
  }, [userData.id, dispatch]); */

  useEffect(() => {
    if (songListData && songListData.results && songListData.results.length > 0) {
        setSongList(songListData.results);
        setTotalCount(songListData.total_count)
    }
  }, [songListData]);

  return (
    <div>
      {songList && songList.length > 0 ? (
        !smScreen ? (
          <Grid container direction="row" alignItems="center" justify="center" className="d-flex align-items-center px-2">
            {songList.map((song, index) => (
              <div key={song.song_id}>
                <CardLayout cardData={song} />
              </div>
            ))}
            <TablePagination
              rowsPerPageOptions={record_limits}
              colSpan={3}
              sx={{ width: '100%', marginTop: '20px', padding: '32px 0px 32px 32px' }}
              className="cardPagination"
              component="div"
              count={totalCount}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              nextIconButtonProps={{ disabled: isNextButtonDisabled }}

            />
          </Grid>
        ) : (
          <React.Fragment>
            {songList.map((song, index) => (
              <div key={song.song_id}>
                <ResponsiveCardList cardData={song} moduleName="songList" />
              </div>
            ))}
            <TablePagination
              rowsPerPageOptions={record_limits}
              colSpan={3}
              sx={{ width: '100%', marginTop: '20px', padding: '32px 0px 32px 32px', marginBottom: '2rem' }}
              className="cardPagination"
              component="div"
              count={totalCount}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              nextIconButtonProps={{ disabled: isNextButtonDisabled }}

            />
          </React.Fragment>
        )
      ) : (
        <NoResults />
      )}
    </div>
  );
};

export default SubmitSongCard;
