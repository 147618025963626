import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Public } from '@mui/icons-material'
import logo from "../img/logo1.png"
import profile from "../img/shweta.jpeg"
import InputBase from '@mui/material/InputBase';
import { Lock } from '@mui/icons-material'
import { Person } from '@mui/icons-material'
import { Email } from '@mui/icons-material'
import { RegisterUser } from '../_services/register';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

function RegisterUsers(props) {
    const [showConPassword, setShowConPassword] = useState(false);
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [submit, setSubmit] = useState(false);
    let history = useHistory()
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [c_password, setC_Password] = useState("");
    const [emailErr, setEmailErr] = useState("");
    const [passErr, setPassErr] = useState("");
    const [c_passErr, setC_PassErr] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [country, setCountry] = useState("");
    const [lastName, setLastName] = useState("");
    const [type, setType] = useState("");

    const formInputChangeHandler = (e) => {
        e.preventDefault();
        if (e.target.name == 'email') {
            setEmail(e.target.value)
            setEmailErr("")
        }
        if (e.target.name == 'name') {
            setName(e.target.value)
            setNameError("")
        }
        if (e.target.name == 'lname') {
            setLastName(e.target.value)
        }
        if (e.target.name == 'password') {
            setPassword(e.target.value)
        }
        if (e.target.name == 'country') {
            setCountry(e.target.value)
        }

    }

    const submitClickHandler = (e) => {
        e.preventDefault();

        let postData = {};
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        var regexp = " /^[0-9\b]+$/"
        var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/

        if (name) {
            postData.name = name;
        } else {
            setNameError(
                <span className="text-danger">Please fill the required field</span>)
        }
        if (lastName) {
            postData.lastName = lastName;
        }
        if (country) {
            postData.countryId = country;
        }
        if (mailformat.test(email)) {
            postData.email = email;
            setEmailErr("")
        } else {
            setEmailErr(<div className="form-group">
                <span className="text-danger">Please enter valid email</span>
            </div>)
        }
        if (password.match(regexp) || format.test(password)) {
            postData.password = password;
            setPassErr("")
        } else {
            setPassErr(<div className="form-group">
                <span className="text-danger">Please enter valid password </span>
            </div>)
        }
        if (type) {
            postData.type = type
        }

        if (mailformat.test(email) && name && (password.match(regexp) || format.test(password) && submit)) {
            // if (submit) {
            // RegisterUser(postData).then(function (response) {
            //     history.push("/")
            //     console.log('Registered Successfully');
            //     console.log(response);
            // })
        }
        // } 
        // else {
        //     console.log("error")
        // }
    }
    return (
        <div className="container-fluid p-0">
            <div className="row">
                <nav className="navbar navbar-light  border pb-0">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="#"><img src={logo} /></a>
                    </div>
                </nav>
                <div className="col-md-6 offset-md-3">
                    <div className="login-form  mt-4 p-4 border rounded shadow">
                        {/* <div className='col-12 text-center pb-3'>
                            <img src={profile} className="signProfile" /><span className="ps-2">shweta@thehigherpitch.com</span>
                        </div> */}
                        <form action="" method="" className="row g-3">
                            <h2 className="text-center">Complete your account setup</h2>
                            {/* <div className="row"> */}
                            <div className="col-md-6">
                                <div className="border  d-flex p-2 rounded">
                                    <Person className="  text-secondary" />
                                    <InputBase
                                        type="text"
                                        className="ms-2 w-100"
                                        placeholder="Name"
                                        name="name"
                                        onChange={formInputChangeHandler}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="border  d-flex p-2 rounded">
                                    <Person className="  text-secondary" />
                                    <InputBase
                                        type="text"
                                        className="ms-2 w-100"
                                        placeholder="Last Name"
                                        name="lname"
                                        onChange={formInputChangeHandler}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="border  d-flex p-2 rounded">
                                    <Email className="  text-secondary" />
                                    <InputBase
                                        type='text'
                                        className="ms-2 w-100"
                                        placeholder="Email Address"
                                        name="email"
                                        onChange={formInputChangeHandler}
                                    />
                                </div>
                                {emailErr}
                            </div>
                            {/* <div className='input-group'>
                                    <div className="input-group-prepend ">
                                        <span className="input-group-text bg-white text-secondary" id="basic-addon1"><Public /></span>
                                    </div>
                                    <input type="button" className="border bg-white border-start-0 form-control" placeholder='Country' data-bs-toggle="dropdown" aria-expanded="false" />
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                    </ul>
                                </div> */}
                            {/* </div> */}
                            <div className="col-md-6">
                                <div className="border  d-flex p-2 rounded">
                                    <Lock className="  text-secondary" />
                                    <InputBase
                                        type={showPassword ? "text" : "password"}

                                        className="ms-2 w-100"
                                        placeholder="Password"
                                        name="password"
                                        onChange={formInputChangeHandler}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={(e) => setShowPassword(!showPassword)}
                                                    // onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </div>
                                {passErr}
                            </div>
                            {/* <div className="col-md-6">
                                <div className="border  d-flex p-2 rounded">
                                    <Lock className="  text-secondary" />
                                    <InputBase
                                        type={showConPassword ? "text" : "password"}

                                        className="ms-2 w-100"
                                        placeholder="Confirm Password"
                                        name="c_password"
                                        onChange={formInputChangeHandler}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={(e) => setShowConPassword(!showConPassword)}
                                                    // onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showConPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </div>
                                {c_passErr}
                             
                            </div> */}
                            <div className="col-md-12">
                                <div className='input-group'>
                                    <div className="input-group-prepend ">
                                        <span className="input-group-text bg-white text-secondary" id="basic-addon1"><Public /></span>
                                    </div>
                                    <select className='border bg-white border-start-0 form-control' name='country'
                                        onChange={formInputChangeHandler}>
                                        <option value="1">India</option>
                                        <option value="2">US</option>
                                    </select>
                                    {/* <input type="button" className="border bg-white border-start-0 form-control" placeholder='Country' data-bs-toggle="dropdown" aria-expanded="false" />
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a className="dropdown-item" href="#">Action</a></li>
                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                    </ul> */}
                                </div>
                            </div>
                            <div className="col-12 my-3">
                                <div className="btn-group w-100 text-white " role="group" aria-label="Basic radio toggle button group">
                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" defaultChecked />
                                    <label className="btn btn-outline-primary" htmlFor="btnradio1">Music Artist</label>
                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" />
                                    <label className="btn btn-outline-primary" htmlFor="btnradio2">Infulencer</label>
                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio3" autoComplete="off" />
                                    <label className="btn btn-outline-primary" htmlFor="btnradio3">Micro Influencer</label>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className="form-check">
                                    <input type="checkbox" className="form-check-input checkbox" id="exampleCheck1" onClick={(e) => setSubmit(true)} />
                                    <label className="form-check-label p-1" htmlFor="exampleCheck1">Yes, I understand and agree to the GrooveNexus Services</label>
                                </div>
                            </div>

                            <div className="col-12">
                                <button type="submit" className="btn btn-danger float-end w-25" onClick={(e) => submitClickHandler(e)} disabled={submit ? false : true}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <footer className="bg-light fixed-bottom text-center text-lg-start">
                <div className="text-center p-3" style={{ bgcolor: "red" }}>
                    © 2021 GrooveNexus spotlight
                </div>
            </footer>
        </div>
    );
}

export default RegisterUsers;