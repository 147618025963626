export function getMonthValue(monthName){
    const monthIndex = new Date(Date.parse(monthName + " 1, 2000")).getMonth()+1; 
    const month = monthIndex.toString();
    return month;
}

export function formatIndianNumber(x) {
    // Convert to string if not already
    x = x !== undefined ? x.toString() : '0';

    // Split into integer and decimal parts
    const parts = x.split('.');
    let integerPart = parts[0];
    let decimalPart = parts[1] || '';

    // Apply formatting to integer part
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(integerPart))
        integerPart = integerPart.replace(pattern, "$1,$2");

    // Truncate or round decimal part to two decimal places
    decimalPart = decimalPart.length > 2 ? decimalPart.slice(0, 2) : decimalPart;
    
    // Combine integer and decimal parts
    if (decimalPart !== '') {
        return integerPart + '.' + decimalPart;
    } else {
        return integerPart;
    }
}
