import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

export default function DateImportRevenue({ dateFrom, setDateTo, setDateFrom }) {
    const today = dayjs(); 
    const threeMonthsAgo = today.subtract(5, 'month');

    const dateRangeHandler = (date, range) => {
        if (range === 'from') {
            setDateFrom(date);
            if (!dateFrom || date.isAfter(dateFrom)) {
                setDateTo(date);
            }
        } else {
            setDateTo(date);
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer sx={{ alignItems: 'baseline', flexDirection: 'row', gap: '1rem' }} components={['DatePicker', 'DatePicker', 'DatePicker']}>
                <DatePicker
                    // value={dateFrom}
                    onChange={(date) => dateRangeHandler(date, 'from')}
                    label={'From'}
                    views={['month', 'year']}
                    minDate={threeMonthsAgo}
                    maxDate={today}
                />
                <DatePicker
                    // value={dateTo}
                    onChange={(date) => dateRangeHandler(date, 'to')}
                    label={'To'}
                    views={['month', 'year']}
                    minDate={dateFrom}
                    maxDate={today}
                    disabled={!dateFrom}
                />
            </DemoContainer>
        </LocalizationProvider>
    );
}
