import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TableComponent from './Tablecomponent';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Breadcrumbs, Button, Paper, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import labels from '../../img/labels.svg'

export default function PayoutList({ hideTotal }) {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    // const rows = location?.state?.state?.data;

    const viewDetails = () => {
        history.push('/payout-detail')
    }

    const rows = [
        {
            id: 1, customer: 'GNStudio', payment_type: 'Bank Transfer', requested_date: '02 Jan, 2024 09:12 am', payment_date: '10 Dec, 2024 09:12 am',
            requested_amt: '400', status: 'Requested'
        }
    ];

    const columns = [
        {
            id: 'customer', label: 'CUSTOMER', headerName: '', template: (row) => {
                return (
                    <Box className='d-flex gap-2 align-items-center cursorPointer' onClick={() => viewDetails()} ><img src={labels} alt='label-icon' />{row.customer}</Box>
                )
            }
        },
        { label: 'PAYMENT TYPE', field: "payment_type", id: 'payment_type' },
        { label: 'REQUESTED DATE & TIME', field: "requested_date", id: 'requested_date' },
        { label: 'PAYMENT DATE & TIME', field: "payment_date", id: 'payment_date' },
        { label: 'REQUESTED AMOUNT', field: "requested_amt", id: 'requested_amt' },
        {
            id: 'status', label: 'STATUS', headerName: '', flex: 1, template: (row) => {
                return (
                    <Button className='text-capitalize rounded-pill px-3' sx={{ backgroundColor: 'rgba(255, 234, 182, 1) !Important', fontSize: 12, color: 'rgba(58, 58, 58, 1)' }}>{row.status}</Button>
                )
            }
        },
        {
            id: 'action', label: 'ACTION', headerName: '', flex: 1, template: (row) => {
                return (
                    <Box className='text-decoration-none viewDetailgradient cursorPointer' ><MoreVertIcon /></Box>
                )
            }
        }
    ];

    return (
        <Box className="py-3 px-4">
            <h2 style={{ marginLeft: '10px' }}>Payout</h2>
            {/* <Typography variant='h2' className='' >Payout</Typography> */}
            {/* <BreadcrumbsList /> */}
            <Paper sx={{ width: '100%', mb: 2, mt: 3 }}>
                <TableComponent removeStyle='removeStyle' columns={columns} data={rows} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
            </Paper>
        </Box>
    );
}
