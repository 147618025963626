import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import useMediaQuery from '@mui/material/useMediaQuery';
import zIndex from '@mui/material/styles/zIndex';
import { Radio, TextField } from '@mui/material';

const AlbumApprovalModal = React.forwardRef(({ openApprovalModal, setOpenApprovalModal, albumData,handleRadioButtonChange, selectedValue,
     buttonText, error, comment, isSubmitDisabled, scrollRef, handleCommentChange, submitButtonText, smScreen, lgScreen},ref) => {
// const [open, setOpen] = React.useState(false);
const handleClose = () => setOpenApprovalModal(false);
const mediumViewport = useMediaQuery('(max-width:720px)');

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: mediumViewport?'95%':'50%',
    bgcolor: 'background.paper',
    border: '0px solid #000',
    borderRadius:'8px',
    boxShadow: 24,
    p: 4,
    maxWidth:600,
    zIndex:2
};

React.useImperativeHandle(ref, () => ({
    handleOpen(){setOpenApprovalModal(true)},
    modalClose(){setOpenApprovalModal(false)}
  }));

return (
    <div>
    <Modal
        open={openApprovalModal}
        /* onClose={handleClose} */
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus={true}
    >
        <Box sx={style}>
        <div ref={scrollRef}>
            {albumData.song_status !== 'draft' ? (buttonText === 'Lock-in' ? (
              <div className={`justify-content-between ${smScreen ? 'px-0' : ''}`}>
                {/* <div style={{ width: '20px', height: '20px', display: `${lgScreen ? 'none' : 'block'}` }}></div> */}
                <div className="bg-white rounded">
                  <div className="d-flex align-items-center">
                    <span className="fs-6 text-secondary">Approval</span>
                    <hr className="ms-2" style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                  </div>
                  <div>
                    <p style={{ fontSize: '12.8px' }}>Song metadata approved or rejected based on compliance with standards and accuracy of information.</p>
                  </div>
                  <div className="col-md-12">
                    <Radio checked={selectedValue === 'True'} onChange={handleRadioButtonChange} value="True" disabled inputProps={{ 'aria-label': 'Approved' }} />
                    <span className="text-secondary">Approve</span>
                    <Radio checked={selectedValue === 'False'} onChange={handleRadioButtonChange} value="False" disabled inputProps={{ 'aria-label': 'Rejected' }} />
                    <span className="text-secondary">Reject</span>
                  </div>
                  <Box component="form" noValidate autoComplete="off">
                    <div>
                      <TextField
                        id="outlined-multiline-flexible"
                        label="Comment"
                        multiline
                        fullWidth
                        maxRows={4}
                        disabled
                      />
                    </div>
                  </Box>
                  <div className="pt-4">
                    <button className="gn-actionbtn" disabled>
                      Ready For Release
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className={`d-flex flex-wrap justify-content-between ${smScreen ? 'px-0' : 'px-3'} mt-4`}>
                <div style={{ width: '20px', height: '20px', display: `${lgScreen ? 'none' : 'block'}` }}></div>
                <div className="p-4 shadow bg-white rounded">
                  <div className="d-flex align-items-center">
                    <span className="fs-6 text-secondary">Approval</span>
                    <hr className="ms-2" style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                  </div>
                   
                      <div>
                        <p style={{ fontSize: '12.8px' }}>Song metadata approved or rejected based on compliance with standards and accuracy of information.</p>
                      </div>
                      {albumData.song_status === 'In Review' ? (
                        <React.Fragment>
                          <div className="col-md-12">
                            <Radio checked={selectedValue === 'True'} onChange={handleRadioButtonChange} value="True" inputProps={{ 'aria-label': 'Approved' }} />
                            Approve
                            <Radio checked={selectedValue === 'False'} onChange={handleRadioButtonChange} value="False" inputProps={{ 'aria-label': 'Rejected' }} />
                            Reject
                          </div>
                          <Box component="form" noValidate autoComplete="off">
                          <div>
                                <TextField
                                 id="outlined-multiline-flexible"
                                 label="Comment"
                                 fullWidth
                                 disabled={!selectedValue}
                                 value={comment}
                                 error={!!error}
                                 helperText={error || ''}
                                 multiline
                                 maxRows={4}
                                 onChange={handleCommentChange}
                                 placeholder="Add minimum of 30 characters..."
                                 required
                                  />
                          </div>
                          </Box>
                          <div className="pt-4">
                            <button className="gn-actionbtn" disabled={isSubmitDisabled} onClick={""}>
                              {submitButtonText}
                            </button>
                          </div>
                      </React.Fragment>):(
                        <>
                        <Box component="form" noValidate autoComplete="off">
                          <div>
                            <Typography variant="caption" fontWeight={500}>{comment}</Typography>
                          </div>
                        </Box>
                        {albumData.song_status === 'Rejected' ?
                          (<div className="pt-4">
                            <button className="gn-actionbtn" disabled>
                              {submitButtonText}
                            </button>
                          </div>)
                          :
                          (<div className="pt-4">
                          <button className="gn-actionbtn" onClick={""} disabled={isSubmitDisabled}>
                            Release
                          </button>
                        </div>)
                        }
                        </>
                       ) }
                    </div>
              </div>
            )):''}
          </div>
        </Box>
    </Modal>
    </div>
);
}) //forward ref ends here

export default AlbumApprovalModal
