import axios from "axios";
import {  getSelfToken } from '../../_helpers/auth/secureToken'
import {  songApproverApi } from "../_constants/api.constant";
export async function getAprrover(actiontype,postData) {
  
              const response = await axios.get(
                songApproverApi.GET,
                    {
                        headers: {
                            Authorization: 'Token ' + getSelfToken(),
                        },
                    }
                ).then(function (response) {
                    return response;
                }).catch(function (error) {
                    // handle error
                    console.log(error);
                    console.log(error.response);
                    return error.response;
                });
                return response;
          
}