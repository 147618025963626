import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import TableComponent from './Tablecomponent';
import { monthDynamic } from './TabConstData';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { formatIndianNumber, getMonthValue } from './formateRevenueData';
import Search from './Search';
import { fetchRoyaltyDetail } from '../../redux/royalty/royaltyDataActions';

export default function Months({ hideTotal }) {
    const history = useHistory();
    const { studioId } = useParams()
    const dispatch = useDispatch();
    const location = useLocation();
    const monthData = useSelector((state) => state.royaltyDataReducer.monthData);
    const userData = useSelector((state) => state.userData.userData);
    const switchValue = useSelector((state) => state.royaltyDataReducer.switchRevenueValue);
    const singlemonthData = useSelector((state) => state.royaltyDataReducer.singlemonthData);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const tabDynamic = location?.state?.state
    const isDetailsPage = location.pathname === '/details';
    const total_count = monthData?.total_count
    const publisherId = userData.id;
    const partener_detail = { partner_type: 'studio', partner_id: studioId, share_type: 'contract_partner' }
    const partener_admin_detail = { partner_type: 'admin', partner_id: publisherId, share_type: 'publisher' }

    const redirectDetails = (data) => {
        if (studioId == undefined || studioId == null || studioId == "") {
            let pagecellUrl = switchValue == 1 ? 'adminallsingleMonthDetails' : 'singleMonthDetails'
            history.push('/details', { state: {data: monthDynamic, value: [data], pageCall: pagecellUrl, name: data?.month } });
        } else {
            history.push(`/details/${studioId}`, { state: { data: monthDynamic, value: [data], pageCall: 'adminsingleMonthDetails', name: data?.month } });
        }
    }

    useEffect(function () {
        if (studioId == undefined || studioId == null || studioId == "") {
            if (isDetailsPage) {
                const monthName = tabDynamic?.name;
                let songId = tabDynamic?.id ? tabDynamic?.id : getMonthValue(monthName)
                let parterdetail = switchValue == 1 ? { ...partener_admin_detail, publisher_type: 'admin' } : null
                dispatch(fetchRoyaltyDetail(tabDynamic.pageCall, { offset: page, limit: rowsPerPage, ...parterdetail }, songId + '/month', `SINGLE_MONTH`))
            } else {
                if (publisherId && switchValue == 1) {
                    dispatch(fetchRoyaltyDetail("adminmonthData", { page, limit: rowsPerPage, ...partener_admin_detail }, 'admin/', `MONTH`));
                } else {
                    dispatch(fetchRoyaltyDetail("monthData", { offset: page, limit: rowsPerPage }, null, `MONTH`))
                }
            }
        }
    }, [dispatch, page, rowsPerPage, publisherId, switchValue]);

    useEffect(function () {
        if (studioId && publisherId) {
            const details = switchValue == 2 ? partener_detail : partener_admin_detail;
            if (location.pathname == `/details/${studioId}`) {
                const monthName = tabDynamic?.name;
                let songId = tabDynamic?.id ? tabDynamic?.id : getMonthValue(monthName)
                dispatch(fetchRoyaltyDetail(tabDynamic.pageCall, { offset: page, limit: rowsPerPage, publisher_type: 'studio', publisher_id: studioId, ...details }, songId + '/month', `SINGLE_MONTH`))
            } else {
                dispatch(fetchRoyaltyDetail("adminmonthData", { offset: page, limit: rowsPerPage, ...details }, `studio/${studioId}/`, `MONTH`))
            }
        }
    }, [publisherId, switchValue]);

    // const rows = (monthData.total_data?.length ? monthData.total_data : []) || [];
    const rows = (isDetailsPage || location.pathname == `/details/${studioId}` ? singlemonthData?.counts_per_month : monthData.total_data?.length ? monthData.total_data : []) || [];

    const monthHead = [
        { id: 'month', numeric: false, field: 'month', label: 'Months' },
        // {
        //     id: 'primary_artist', label: 'Artist', headerName: '', flex: 1, template: (row) => {
        //         return (
        //             <Box className='d-flex gap-2 flex-wrap align-items-center'>
        //                 {row.primary_artist.length ? (
        //                     <>
        //                         {row.primary_artist.slice(0, 2).map((d, i) => (
        //                             <Typography key={i} className='rounded-pill px-3 py-1' sx={{ background: "#EBEBEB", fontSize: '12px' }}>{d}</Typography>
        //                         ))}
        //                         {row.primary_artist.length > 2 && (
        //                             <Tooltip title={row.primary_artist.slice(2).map(d => d).join(', ')} arrow>
        //                                 <Typography className='rounded-pill px-3 py-1' sx={{ background: "#EBEBEB", fontSize: '12px' }}> {"+" + row.primary_artist.length}</Typography>
        //                             </Tooltip>
        //                         )}
        //                     </>
        //                 ) : null}</Box>
        //         );
        //     }
        // },
        { id: 'total_stream_count', numeric: true, field: 'total_stream_count', label: 'Streams' },
        { id: 'release_download_count', numeric: true, field: 'release_download_count', label: 'Release Downloads' },
        { id: 'track_download_count', numeric: true, field: 'track_download_count', label: 'Track Downloads' },
        { id: 'video_download_count', numeric: true, field: 'video_download_count', label: 'Video Downloads' },
        {
            id: 'total_stream_earning', label: 'Earnings', headerName: '', flex: 1, template: (row) => {
                const totalDownloads = row.total_stream_earning + row.total_release_download_earning + row.total_track_download_earning + row.total_video_download_earning;
                return (
                    <Box className="d-flex align-items-center"><CurrencyRupeeIcon sx={{ fontSize: '15px' }} />{formatIndianNumber(totalDownloads)}</Box>
                );
            }
        },
        {
            id: 'action', label: 'Action', headerName: '', flex: 1, template: (row) => {
                return (
                    <Box className='text-decoration-none viewDetailgradient cursorPointer' onClick={() => redirectDetails(row)} >View Details</Box>
                )
            }
        },
    ];

    const monthFooter = [
        { id: 'name', numeric: false, label: 'Totals' },
        // { id: 'artist', numeric: false, label: '-' },
        { id: 'streams', numeric: true, label: formatIndianNumber(monthData.overall_total_streams) },
        { id: 'releasedownloads', numeric: true, label: formatIndianNumber(monthData.overall_release_downloads) },
        { id: 'trackdownloads', numeric: true, label: formatIndianNumber(monthData.overall_track_downloads) },
        { id: 'videodownloads', numeric: true, label: formatIndianNumber(monthData.overall_video_downloads) },
        {
            id: 'earnings', numeric: true, label: <Box className="d-flex align-items-center"><CurrencyRupeeIcon sx={{ fontSize: '15px' }} />
                {formatIndianNumber(monthData.overall_streams_earning + monthData.overall_release_downloads_earnings + monthData.overall_track_downloads_earnings + monthData.overall_video_downloads_earnings)}</Box>
        },
        { id: 'action', numeric: false, label: '' }
    ];

    return (
        <Paper sx={{ width: '100%', mb: 2, mt: 1 }}>
            <Search headValue={'Months'} />
            <TableComponent columns={monthHead} data={rows} headFooter={monthFooter} hideTotal={hideTotal} total_count={total_count} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
        </Paper>
    );
}
