import axios from "axios";
import {getSelfToken} from '../../_helpers/auth/secureToken'
import {PlanEndpoint} from '../_constants/api.constant'
export async function getPlanList() {
    const response = await axios.get(
        PlanEndpoint.GET_PLAN_LIST,
        {  
            //  params:paramsObj,
            headers: {
                'Authorization': 'Token ' + getSelfToken(),
            },
        }
    ).then(function (response) {
        return response;
    }).catch(function (error) {
        return error.response;
    });
    return response;
}