import {
  SET_LOADER,
  SET_SELECTED_VALUE
} from './loaderTypes'


export const setLoader = loader => {
  return {
    type: SET_LOADER,
    payload: loader
  }
}

export const setSelectedValue = value => {
  return {
    type: SET_SELECTED_VALUE,
    payload: value
  }
}
