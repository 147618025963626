import axios from "axios";
import {getSelfToken} from '../../_helpers/auth/secureToken'
import {AlbumEndpoint} from '../_constants/api.constant'
export async function getSingleAlbum(albumId) {
    const response = await axios.get(
        AlbumEndpoint.GET_SINGLE_ALBUM,
        {   params:albumId,
            // headers: {
            //     'Authorization': 'Token ' + getSelfToken(),
            // },
        }
    ).then(function (response) {
        return response;
    }).catch(function (error) {
        return error.response;
    });
    return response
}