import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { formSnackbar } from '../../_services/_constants/snackbar.constant';
import { useMediaQuery } from '@mui/material';
import ImportrevenueDataTable from './ImportRevenueDataTable';
import { default_limit } from '../../_services/_constants/recordLimitset.constant';
import ImportRevenueModal from '../../components/reusableComponents/ImportRevenueModal';
import { ListOfImportedRevenue } from '../../_services/importRevenue/getListOfImportRevenue.service';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ApiService from '../../_services/ApiService';

function ImportRevenueList(props) {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData.userData);
  const smScreen = useMediaQuery('(max-width:768px)')
  //to get the search input value
  const scrollRef = useRef(null);
  const [openImportModal, setOpenImportModal] = useState(false)
  const [importList, setImportList] = useState()
  const [renderComponent, setRenderComponent] = useState()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(default_limit);
  useEffect(() => {
    if (userData.id) {
      getData(userData.id, page)
    }
  }, [userData.id, rowsPerPage, page])

  // --------------SNACKBAR FUNCTION --------------

  const snackBarFunction = (message, varient) => {
    dispatch(setSnackBar(true));
    dispatch(setSnackBarMessage(message));
    dispatch(setSnackBarVariant(varient));
    setTimeout(() => dispatch(setSnackBar(false)), 3000)
  }

  function getData(id, page) {
    const offset = page;
    const limit = rowsPerPage;
    dispatch(setLoader(true))
    ListOfImportedRevenue({ limit: limit, offset: offset }).then(function (response) {
      dispatch(setLoader(false))
      if (response.status === 200) {
        setPage(page);
        setImportList(response?.data)
        setRenderComponent(null)
      }
      else {
        snackBarFunction(formSnackbar.errors.errorMsg, 'error')
      }
    }).catch(function (error) {
      snackBarFunction(formSnackbar.errors.errorMsg, 'error')
      dispatch(setLoader(false))
      return error.response;
    })
  }

  useEffect(() => {
    if (importList) {
      revenueComponentCall()
    }
  }, [importList])

  const handleDelete = (e, id, data) => {
    e.stopPropagation()
    dispatch(setLoader(true))
    ApiService.delete('deleteRevenueLog', { "import_id": id }).then(response => {
      dispatch(setLoader(false))
      let results = data?.results.filter(d => d.import_id !== id)
      setImportList({ 'total_count': results.length, 'results': [...results] })
      snackBarFunction('Log deleted successfully.', 'success')
    }).catch(function (error) {
      snackBarFunction(formSnackbar.errors.errorMsg, 'error')
      return error.response;
    })
  }

  function revenueComponentCall() {
    setRenderComponent(<ImportrevenueDataTable handleDelete={handleDelete} demoListData={importList}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
      page={page}
      rowsPerPage={rowsPerPage}
    />)
  }

  const OpenImportModal = (value) => {
    setOpenImportModal(true)
  }

  return (
    <div className={`row ${smScreen ? 'p-2' : ''}`} style={{ padding: '1rem 1rem 3rem 1rem' }}>
      <div className={`d-flex justify-content-between align-items-center`}>
        {/* <Typography variant='h5' >Revenue</Typography> */}
        <h2 style={{ marginLeft: '10px' }}>Royalty</h2>

        <div className='d-flex justify-content-end' style={{ marginRight: '10px' }}>
          <button className="gn-actionbtn rounded gap-2 d-flex" onClick={OpenImportModal}>
            <UploadFileIcon />Import Royalty
          </button>
        </div>
      </div>
      <div className='mt-3'>
        {renderComponent}
      </div>
      <ImportRevenueModal getData={getData} setImportList={setImportList} openImportModal={openImportModal} setOpenImportModal={setOpenImportModal} scrollRef={scrollRef} />
    </div>
  )
}

export default ImportRevenueList