import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TableComponent from './Tablecomponent';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Breadcrumbs, Button, Paper, Typography } from '@mui/material';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { getDateAsPerFormat } from '../../_helpers/reusablefunctions/dateSetter';
import ApiService from '../../_services/ApiService';
import { setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ViewRevenueCard from './ViewRevenueCard';
import SnackbarContainer from '../Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
export default function ViewRevenue({ hideTotal }) {
    const dispatch = useDispatch();
    const location = useLocation();
    const [page, setPage] = useState(0);
    const [rows, setRows] = useState([]);
    const [splitCheck, setSplitCheck] = useState();
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const history = useHistory()
    // const rows = location?.state?.state?.data;
    const viewRevenue = JSON.parse(localStorage.getItem("revenueData"));
    const [loaderSpinner, setLoaderSpinner] = useState(false)

    useEffect(() => {
        const offset = page;
        const limit = rowsPerPage;
        dispatch(setLoader(true))
        ApiService.get('getExcelData', { limit, offset }, viewRevenue.import_id).then(response => {
            const res = response.data
            if (response.status === 200) {
                dispatch(setLoader(false))
                setRows(res.data)
            }
            else {
            }
        }).catch(function (error) {
            return error.response;
        })

        ApiService.get('checkSplit', {}, viewRevenue.import_id).then(response => {
            if (response.status === 200) {
                const res = response.data
                dispatch(setLoader(false))
                setSplitCheck(res.split)
            }
            else {
            }
        }).catch(function (error) {
            return error.response;
        })

    }, [])

    const editRevenue = (data) => {
        history.push('/editrevenue', { state: { data: data } });
    }

    const columns = [
        {
            id: 'release_title', label: 'Release Title', headerName: '', flex: 1, template: (row) => {
                return (
                    <Box className="d-flex align-items-center gap-5"><UnfoldMoreIcon className='cursorPointer' onClick={() => editRevenue(row)} sx={{ transform: 'rotate(45deg)' }} />{row.release_title}</Box>
                );
            }
        },
        { id: 'track_title', label: 'Track Title', field: "track_title", },
        {
            id: 'transaction_month', label: 'Transaction Month', headerName: '', flex: 1, template: (row) => {
                return (
                    <Box className="d-flex align-items-center gap-2">{getDateAsPerFormat(row.transaction_month, "DD Mon, YYYY")}</Box>
                );
            }
        },
        { id: 'label', label: 'Label', field: "label", },
        { id: 'artist', label: 'Artist', field: "artist", },
        { id: 'upc_code', label: 'UPC', field: "upc_code", },
        { id: 'isrc_code', label: 'ISRC', field: "isrc_code", },
        { id: 'release_catalog_id', label: 'Release Catalog ID', field: "release_catalog_id", },
        { id: 'track_catalog_id', label: 'Track Catalog ID', field: "track_catalog_id", },
        { id: 'format', label: 'Format', field: "format", },
        { id: 'parent_sale_id', label: 'Parent Sale Id', field: "parent_sale_id", },
        { id: 'transaction_id', label: 'Transaction ID', field: "transaction_id", },
        { id: 'account_id', label: 'Account ID', field: "account_id", },
        { id: 'contract_id', label: 'Contract ID', field: "contract_id", },
        { id: 'payee_id', label: 'Payee ID', field: "payee_id", },
        { id: 'platformName', label: 'Service', field: "platformName", },
        { id: 'channel_type', label: 'Channel', field: "channel_type", },
        { id: 'country_name', label: 'Territory', field: "country_name", },
        { id: 'count', label: 'quantity', field: "count", },
        { id: 'gross_revenue', label: 'Gross Revenue in INR', field: "gross_revenue", },
        { id: 'contract_rate', label: 'Contract Rate %', field: "contract_rate", },
        { id: 'net_revenue', label: 'Net Revenue in INR', field: "net_revenue", },
        { id: 'your_share', label: 'Your Share %', field: "your_share", },
        { id: 'opening_balance', label: 'Opening Balance in INR', field: "opening_balance", },
        { id: 'closing_balance', label: 'Closing Balance in INR', field: "closing_balance", },
    ];

    const backroute = () => {
        history.push('/importrevenue')
    }

    function splitRevenue() {
        setLoaderSpinner(true)
        const payload = {
            split: 'True'
        }
        ApiService.put('splitRevenue', payload, viewRevenue.import_id).then(response => {
            if (response.status == 200) {
                setLoaderSpinner(false)
                setSplitCheck(true)
                dispatch(setSnackBar(true))
                dispatch(setSnackBarMessage(response.data.message));
                dispatch(setSnackBarVariant('success'));
                setTimeout(() => dispatch(setSnackBar(false)), 3000);
            }
        }).catch(function (error) {
            return error.response;
        })
    }

    return (
        <Box className="py-4 px-4">
            <Box className='d-flex align-items-center'>
                <Button className="icononly-btn w-100 d-flex align-items-center justify-content-start gap-3 text-body" onClick={backroute} ><ArrowBackIcon />{viewRevenue.file}</Button>
                <Button disabled={splitCheck} onClick={() => loaderSpinner ? null : splitRevenue()} variant="contained" sx={{ width: '200px', backgroundColor: 'rgba(237, 50, 55, 1)' }} >SPLIT REVENUE
                    {loaderSpinner ? <CircularProgress sx={{ color: 'white', width: '20px !important', height: '20px !important' }} /> : null}
                </Button>
            </Box>
            <ViewRevenueCard />
            {/* <BreadcrumbsList /> */}
            <Paper className="shadow-table" sx={{ width: '100%', mb: '3rem', mt: 4}}>
                <TableComponent columns={columns} data={rows} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} hidepagination={true}/>
            </Paper>
            <SnackbarContainer />
        </Box>
    );
}
