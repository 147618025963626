import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
// import { SnackbarContext } from "../../App";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import General from './General';
import SnackbarContainer from '../Snackbar';

function Account(props) {
    // const { snack, setSnack } = useContext(SnackbarContext);
    const [type, setType] = useState("services")
    const [active, setActive] = useState("6")
    const [accountTabs, setAccountTabs] = useState([
        {
        id:6,
        tabActiveId:"6",    
        tabTitle:'General',
        tabType:'general'
        },
      
    
    ])
    useEffect(function () {
       let currentURL = window.location.href.split('/account?user=')[1]
        if(currentURL === undefined)
            currentURL = window.location.href.split('/?user=')[1]
        const currentTab = accountTabs.find(item=>item.tabType === currentURL)
        currentTab && setActive(currentTab.tabActiveId)  
      
    }, []);

   const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const mediumViewport = useMediaQuery('(min-width:1100px)');
    const tabStyle = {
        bgcolor: 'background.paper', 
        border:'solid 1px #868686', 
        borderRadius:'10px', 
        margin:mediumViewport?'20px':'10px 0px 10px 0px' ,
        // padding:'20px'
    }
    return (
        <div>
        <div className={`container ${mediumViewport? 'p-4':'px-0 pb-4'}`}>
        <div className="d-flex main-account-container">
            <div className="account-tab-content">
                <Box sx={{ /* maxWidth: { xs: 320, sm: 200 }, */ bgcolor: 'background.paper', border:'solid 1px #868686', borderRadius:'10px', margin:'20px 0px', padding:'20px'}}>
                <General />
                </Box>
            </div>
        </div>
        </div>

        <SnackbarContainer />   
        </div>
    );
}

export default Account;