// app constant
export const authentication = {
  ssoAuthType: 'sso',
  selfAuthType: 'self',
};

// cookies constant
export const user = {
  user:'user2',
  selfToken: 'selfToken',
  ssoToken: 'ssoToken',
  authType: 'authType',
  appToken: 'appToken',
};