import React, { useState, useEffect, useContext } from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Overview from './Overview';
import Track from './Track';
import MusicRelease from './MusicRelease';
import { Typography } from '@mui/material';
import VideoRoyalty from './VideoRoyalty';
import Stores from './Stores';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import Months from './Months';
import Countries from './Countries';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { setRevnueValue, setTabValue } from '../../redux/royalty/royaltyDataActions';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DynamicToggleTabs from '../../components/reusableComponents/DynamicToggleTabs';

function Royalty({ title, hidePadding }) {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory()
    const [accountTabs, setAccountTabs] = useState([
        { id: 1, tabActiveId: "1", tabTitle: 'OVERVIEW', tabType: 'overview', icons: <AlternateEmailIcon sx={{ fontSize: '15px' }} /> },
        { id: 2, tabActiveId: "3", tabTitle: 'MUSIC RELEASES', icons: <QueueMusicIcon sx={{ fontSize: '15px' }} />, tabType: 'musicrelease' },
        { id: 3, tabActiveId: "4", tabTitle: 'TRACKS', icons: <AudiotrackIcon sx={{ fontSize: '15px' }} />, tabType: 'track' },
        { id: 4, tabActiveId: "5", tabTitle: 'VIDEOS', icons: <VideoLibraryIcon sx={{ fontSize: '15px' }} />, tabType: 'videos' },
        { id: 5, tabActiveId: "6", tabTitle: 'STORES', icons: <LibraryMusicIcon sx={{ fontSize: '15px' }} />, tabType: 'stores' },
        { id: 6, tabActiveId: "7", tabTitle: 'MONTHS', icons: <CalendarTodayIcon sx={{ fontSize: '15px' }} />, tabType: 'months' },
        { id: 7, tabActiveId: "8", tabTitle: 'COUNTRIES', icons: <FmdGoodIcon sx={{ fontSize: '15px' }} />, tabType: 'country' }])
    const [value, setValue] = useState('1');
    const tabValue = useSelector((state) => state.royaltyDataReducer.tabValue);

    useEffect(() => {
        if (tabValue) {
            setValue(tabValue);
        }
    }, [tabValue])

    const handleChange = (event, newValue) => {
        setValue(newValue);
        dispatch(setTabValue(newValue))
    };

    function backtostudio() {
        history.push('/studiolist')
    }

    const handleTabSelect = (newValue) => {
        dispatch(setRevnueValue(newValue))
    };

    return (
        <Box className={hidePadding ? "" : "mx-2 container-padding"}>
            <Box className={`${hidePadding ? "p-0" : "" } mb-5 pb-3`}>
                <Box className='d-flex justify-content-between align-items-center'>
                    {console.debug("sakbbkskjsa:sakbsbkj", title)}
                    <Typography variant='h5' className='cursorPointer' onClick={backtostudio}>{title ? null : <ArrowBackIcon />} {title ? title : localStorage.getItem('studio_name')} </Typography>
                    {title ?
                        <DynamicToggleTabs value2="Overall Admin Royalties" value1="Overall Royalty" onSelectTab={handleTabSelect} />
                        :
                        <DynamicToggleTabs value2="Admin Royalty" value1="Studio Royalty" sx={{' & .css-1aquho2-MuiTabs-indicator': {
                            position: 'absolute',
                            height: '2px',
                            bottom: '0px',
                            backgroundColor:"rgba(237, 50, 55, 1) !important" // Change this to the desired color for the indicator
                        }, }} onSelectTab={handleTabSelect} />
                    }
                </Box>
                <Box className="bg-white shadow-table rounded mt-3">
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList
                                scrollButtons="auto" // Change this to "auto" or "on" to enable scroll buttons
                                variant="scrollable"
                                orientation="horizontal"
                                scroller='true'
                                sx={{
                                    '& .Mui-selected': {
                                        color: '#E41116 !important', // Change this to the desired color
                                    },
                                    '& .MuiTabs-indicator': {
                                        backgroundColor: '#E41116', // Change this to the desired color for the indicator
                                    },
                                }}
                                onChange={handleChange} aria-label="lab API tabs example">
                                {accountTabs ? accountTabs.map((item, i) => {
                                    return (
                                        <Tab sx={{ fontSize: '13px' }} icon={item.icons} iconPosition="start" label={item.tabTitle} key={i} value={item.tabActiveId} />)
                                }) : null}
                            </TabList>
                        </Box>

                        {(() => {
                            switch (value) {
                                case '1':
                                    return <TabPanel value='1'><Overview /></TabPanel>;
                                case '3':
                                    return <TabPanel value='3'><MusicRelease /></TabPanel>;
                                case '4':
                                    return <TabPanel value='4'><Track /></TabPanel>;
                                case '5':
                                    return <TabPanel value='5'><VideoRoyalty /></TabPanel>;
                                case '6':
                                    return <TabPanel value='6'><Stores /></TabPanel>;
                                case '7':
                                    return <TabPanel value='7'><Months /></TabPanel>;
                                case '8':
                                    return <TabPanel value='8'><Countries /></TabPanel>;
                            }
                        })()}
                    </TabContext>
                </Box>
            </Box>
        </Box>
    );
}

export default Royalty;