
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import TableComponent from './Tablecomponent';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { formatIndianNumber } from './formateRevenueData';
import Paper from '@mui/material/Paper';
import Search from './Search';
import { fetchRoyaltyDetail } from '../../redux/royalty/royaltyDataActions';
import { useParams } from 'react-router-dom';

export default function Overview() {
    const { studioId } = useParams()
    const userData = useSelector((state) => state.userData.userData);
    const overviewData = useSelector((state) => state.royaltyDataReducer.overviewData?.overall_data);
    const switchValue = useSelector((state) => state.royaltyDataReducer.switchRevenueValue);
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const total_count = overviewData?.total_count
    const publisherId = userData.id;
    const partener_detail = { partner_type: 'studio', partner_id: studioId, share_type: 'contract_partner' }
    const partener_admin_detail = { partner_type: 'admin', partner_id: publisherId, share_type: 'publisher' }


    useEffect(function () {
        if (studioId && publisherId) {
            const details = switchValue == 2 ? partener_detail : partener_admin_detail;
            dispatch(fetchRoyaltyDetail("adminoverviewData", { page, limit: rowsPerPage, ...details }, `studio/${studioId}/`, `OVERVIEW`));
        }
        if (studioId == undefined || studioId == null || studioId == "") {
            if (publisherId && switchValue == 1) {
                dispatch(fetchRoyaltyDetail("adminoverviewData", { page, limit: rowsPerPage, ...partener_admin_detail }, 'admin/', `OVERVIEW`));
            } else {
                dispatch(fetchRoyaltyDetail("overviewData", { page: page, limit: rowsPerPage }, null, `OVERVIEW`))
            }
        }
    }, [publisherId, switchValue])

    const rows = [
        { id: 1, name: 'Release Downloads', totalsale: formatIndianNumber(overviewData?.overall_release_downloads), totalearning: `\u20B9 ${formatIndianNumber(overviewData?.overall_release_downloads_earnings)}` },
        { id: 2, name: 'Video Downloads', totalsale: formatIndianNumber(overviewData?.overall_video_downloads), totalearning: `\u20B9 ${formatIndianNumber(overviewData?.overall_video_downloads_earnings)}` },
        { id: 3, name: 'Track Downloads', totalsale: formatIndianNumber(overviewData?.overall_track_downloads), totalearning: `\u20B9 ${formatIndianNumber(overviewData?.overall_track_downloads_earnings)}` },
        { id: 4, name: 'Streams', totalsale: formatIndianNumber(overviewData?.overall_total_streams), totalearning: `\u20B9 ${formatIndianNumber(overviewData?.overall_streams_earning)}` }
    ];
    const columns = [
        { id: 'name', numeric: false, field: 'name', label: 'Overview' },
        { id: 'totalsale', numeric: true, field: 'totalsale', label: 'Total Sales' },
        { id: 'totalearning', numeric: true, field: 'totalearning', label: 'Earnings' },
    ]

    const headFooter = [
        { id: 'name', numeric: false, label: 'Totals' },
        { id: 'totalsale', numeric: true, label: formatIndianNumber(overviewData?.overall_sales) },
        { id: 'totalearning', numeric: true, label: <Box className="d-flex align-items-center"><CurrencyRupeeIcon sx={{ fontSize: '15px' }} />{formatIndianNumber(overviewData?.overall_earnings)}</Box> },
    ];

    return (
        <Paper sx={{ width: '100%', mb: 2, mt: 1 }}>
            <Search headValue={'Overview'} />
            <TableComponent footerHide={true} columns={columns} data={rows} headFooter={headFooter} total_count={total_count} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
        </Paper>
    );
}