import axios from "axios";
import { setArtistVerificationStatus } from "../../_constants/api.constant";
import { getSelfToken } from "../../../_helpers/auth/secureToken";
export async function verifyServiceHandler(data, id){
    const response=await axios.put(
        setArtistVerificationStatus.PUT+id,data,
        { 
            headers:{
                Authorization: 'Token '+getSelfToken(),
            },
        }
        ).then(function(response) {
        return response;
        }).catch(function (error) {
        // handle error
        console.log(error);
        console.log(error.response);
        return error.response;
        });
    return response;
}