import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import { useRef } from 'react';
import { useState } from 'react';
import { AddRevenueData } from '../../_services/importRevenue/addRevenueData.service';
import { setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { useDispatch, useSelector } from 'react-redux';
import DateImportRevenue from '../../module/importRevenue/DateImportRevenue';
import { Button, Card, CardContent } from '@mui/material';
import FileUploadCard from '../../module/importRevenue/FileUpload';
import ApiService from '../../_services/ApiService';

const ImportRevenueModal = React.forwardRef(({ openImportModal, setOpenImportModal, getData }, ref) => {
  const mediumViewport = useMediaQuery('(max-width:720px)');
  const [selectedFile, setSelectedFile] = useState(null);
  const [flag, setFlag] = useState(false);
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const userData = useSelector((state) => state.userData.userData);
  const [dateFrom, setDateFrom] = useState(null)
  const [dateTo, setDateTo] = useState(null)
  const dispatch = useDispatch()
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: mediumViewport ? '95%' : '50%',
    bgcolor: 'background.paper',
    border: '0px solid #000',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
    maxWidth: 600,
    zIndex: 2
  };

  React.useImperativeHandle(ref, () => ({
    handleOpen() { setOpenImportModal(true) },
    modalClose() { setOpenImportModal(false) }
  }));

  const handleUploadProgress = (progressEvent) => {
    setFileUploadProgress(progressEvent);
  };

  const handleremovefile = () => {
    setSelectedFile(null)
  }

  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file)
    }
  };
  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      setSelectedFile(file)
      // fileValidation(file)
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleClose = (e) => {
    setSelectedFile(null)
    setFlag(false)
    setOpenImportModal(!openImportModal);
  }

  const OnSubmit = (e) => {
    let postData = new FormData()
    postData.append("file", selectedFile)
    postData.append("uploader_id", userData.id)
    postData.append("from_date", dateFrom)
    postData.append("to_date", dateTo)
    dispatch(setLoader(true))
    ApiService.post('postRevenueData', postData).then(response => {
      dispatch(setSnackBar(true));
      if (response.status == 200 || response.status == 201) {
        setFlag(false)
        dispatch(setSnackBarVariant('success'));
        dispatch(setSnackBarMessage(response.data?.message?.message));
        setOpenImportModal(!openImportModal)
        setSelectedFile(null)
      } else {
        dispatch(setSnackBarVariant('error'));
        dispatch(setSnackBarMessage(response.data.message));
        downloadErrorResponse(response.data.message);
      }
      setTimeout(() => dispatch(setSnackBar(false)), 6000);
      dispatch(setLoader(false));
      getData(10, 0)
      handleClose()
    }).catch((err) => {
      handleClose()
      getData(10, 0)
    });
  }

  const downloadErrorResponse = (errorData) => {
    const errorJson = JSON.stringify(errorData, null, 2); // Formatting the error data
    const blob = new Blob([errorJson], { type: 'application/json' });
    const url = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement('a');
    link.href = url;
    link.download = 'royalty-error-response.txt'; // Set the file name for download
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };


  const disabledValue = selectedFile && (dateFrom && dateTo);

  return (
    <Box>
      <Modal
        open={openImportModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus={true}
      >
        <Box sx={style}>
          <Box>
            <Box className='d-flex justify-content-between'>
              <h4 className="text-secondary">File Upload</h4>
              <CloseIcon className='cursorPointer' onClick={handleClose} />
            </Box>
            <Box >
              <DateImportRevenue
                setDateTo={setDateTo}
                setDateFrom={setDateFrom}
                dateFrom={dateFrom}
              />
              <Box
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              >
                <input
                  type="file"
                  accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onChange={handleFileChange}
                  style={{ display: 'none', }}
                  ref={fileInputRef}
                />
                <Box onClick={() => fileInputRef.current.click()} className="bg-white border audio-uploader custom-height ">
                  <Typography className='text-primary'><UploadFileIcon style={{ height: '1rem', width: '1rem' }} /></Typography>
                  <Box className='d-flex'>
                    <Box style={{ marginRight: '10px' }}>
                      <span className='text-primary'>Click to upload</span>
                    </Box>
                    <Box>
                      <span>or drag and drop</span>
                    </Box>
                  </Box>
                  <Typography className='text-secondary' style={{ fontSize: '0.8rem' }}>XLSX (max. 10MB)</Typography>
                </Box>

              </Box>
              <Box className="my-4" sx={{ display: 'flex', justifyContent: 'center' }}>
                {selectedFile && (
                  <Card sx={{ width: '100%', maxWidth: 600, overflowWrap: 'anywhere' }}>
                    <CardContent>
                      <FileUploadCard
                        file={selectedFile}
                        removePdf={handleremovefile}
                        progress={fileUploadProgress}
                        progressHandler={handleUploadProgress}
                      />
                    </CardContent>
                  </Card>
                )}
              </Box>
              <Box className='mt-4 d-flex justify-content-end gap-2'>
                <Button variant="text" className='text-black-50 cursorPointer' onClick={handleClose} >Cancel</Button>
                <Button sx={{ backgroundColor: '#e41116 !important' }} className={`${!disabledValue ? 'gn-actionbtn' : ''} text-white px-4`} disabled={disabledValue ? false : true} onClick={OnSubmit} >Done</Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
})
export default ImportRevenueModal
