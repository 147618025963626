
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button, IconButton, Menu, MenuItem, Paper, Typography, useMediaQuery } from '@mui/material';
import SnackbarContainer from '../Snackbar';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import artist from '../../img/userAdmin.png'
import StatusDetailDialog from './cardformate/statusDetailDialog';
import TableComponent from '../importRevenue/Tablecomponent';

export default function StudioTable({ studioListData, page, rowsPerPage, setRowsPerPage, setPage }) {
  const [rows, setRows] = useState(studioListData.results);
  const [openDialog, setOpenDialog] = useState(false);
  const [rowId, setRowId] = useState('');
  const [totalCount, setTotalCount] = useState('');
  const [anchorEl, setAnchorEl] = useState(null)
  const [menuRowId, setMenuRowId] = useState(null);
  const history = useHistory();

  useEffect(() => {
    setRows(studioListData?.results)
    setTotalCount(studioListData?.total_count)
  }, [studioListData])


  const columns = [{
    id: 'studio_name', label: 'Studio Name', headerName: '', flex: 1, template: (row) => {
      return (
        <Box className="d-flex align-items-center gap-2">
          <img className="rounded-pill" src={row.avatar ? process.env.REACT_APP_STUDIOURL_MEDIA + 'media/' + row.avatar : artist} style={{ width: '40px', height: '40px' }} />
          <Typography variant='span'>{row.studio_name}</Typography>
        </Box>
      )
    }
  },
  { id: 'email', field: 'email', label: 'Email' },
  { id: 'phone_number', field: 'phone_number', label: 'Phone Number' },
  { id: 'location', field: 'location', label: 'Location' },
  {
    id: 'action', label: 'Action', headerName: '', flex: 1, template: (row) => {
      return (
        <Box className='text-decoration-none viewDetailgradient cursorPointer' ><Button
          id={`basic-button-${row.studio_id}`}
          aria-controls={menuRowId === row.studio_id ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={menuRowId === row.studio_id ? 'true' : undefined}
          onClick={(event) => handleMenuClick(event, row.studio_id)}
        >
          {/* <IconButton> */}
            <MoreVertIcon />
          {/* </IconButton> */}
        </Button>
          <Menu
            id={`basic-menu-${row.studio_id}`}
            anchorEl={anchorEl}
            open={menuRowId === row.studio_id}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': `basic-button-${row.studio_id}`,
            }}
          >
            <MenuItem onClick={() => opendetail(row)} className='px-4 py-2 d-flex gap-3'>View Detail</MenuItem>
            <MenuItem onClick={() => openRevenue(row)} className='px-4 py-2 d-flex gap-3'>View Revenue</MenuItem>
            <MenuItem className='px-4 py-2 d-flex gap-3' onClick={() => dialogHandler('songStatus', row.studio_id)}>Song Status</MenuItem>
            <MenuItem className='px-4 py-2 d-flex gap-3' onClick={() => dialogHandler('revenue', row.studio_id)}>Share Revenue</MenuItem>
          </Menu>
        </Box>
      )
    }

  },
  ];

  const handleMenuClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setMenuRowId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuRowId(null);
  };

  const opendetail = (data) => {
    history.push('/studio-view-detail', { state: { data: data } })
  }

  const dialogHandler = (data, id) => {
    setOpenDialog(true)
    setRowId({ id: id, value: data })
  }

  const openRevenue = (data) => {
    localStorage.setItem('studio_name', data.studio_name)
    history.push(`/analytics/${data.studio_id}`)
  }

  return (
    <Box className="container-fluid py-4 creator-playlist-panel">
      <Paper className="shadow-table" sx={{ width: '100%', mb: '3rem' }}>
        <TableComponent columns={columns} data={rows} page={page} setPage={setPage} removeStyle={'removeStyle'} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} totalCount={totalCount} />
      </Paper>
      <SnackbarContainer />
      <StatusDetailDialog rowId={rowId} open={openDialog} setOpen={setOpenDialog} />
    </Box>
  );
}