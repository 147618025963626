
import {
  SET_LOADER,
  SET_SELECTED_VALUE
} from './loaderTypes'
const initialState = {
  loader: false,
  switchedValue: 2,
  error: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADER:
      return {
        ...state,
        loader: action.payload,
      }
    case SET_SELECTED_VALUE:
      return {
        ...state,
        switchedValue: action.payload,
      }
    default: return state
  }

}


export default reducer
