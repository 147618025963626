import { combineReducers } from 'redux'
import loader from './loader/loaderReducer'
import userData from './user/userDataReducer'
import userDetail from './loginUser/loginUserReducer'
import snackBar from './snackBar/snackBarReducer'
import releaseSongList from './songRelease/songReleaseReducer'
import releaseAlbumReducer from './releaseAlbum/releaseAlbumDataReducer'
import royaltyDataReducer from "./royalty/royaltyDataReducer"
import musicCategorgyReducer from "./musicalCategory/musicCategoryReducer"

const rootReducer = combineReducers({
    loader:loader,
    userData:userData,
    userDetail:userDetail,
    snackBar:snackBar,
    releaseSongList:releaseSongList,
    releaseAlbumReducer:releaseAlbumReducer,
    royaltyDataReducer:royaltyDataReducer,
    musicCategorgyReducer:musicCategorgyReducer

})

export default rootReducer
