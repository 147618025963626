import React from 'react'
import { Box, FormControl, IconButton, Stack, TextField, ToggleButton } from '@mui/material'
import { SearchOutlined } from '@mui/icons-material'

const SearchBox = ({ handleSearchInput, searchValue, placeholder }) => {
  return (
    <div style={{width:'300px'}}>
      <div>
        <TextField
          className='searchicon bg-white'
          id="standard-bare"
          variant="outlined"
          fullWidth={true}
          size='small'
          input="true" type="text" placeholder={placeholder}
          defaultValue=""
          value={searchValue}
          onChange={(e) => handleSearchInput(e.target.value)}
          InputProps={{
            startAdornment: (
              <IconButton edge="start">
                <SearchOutlined />
              </IconButton>
            ),
          }}
        />
      </div>
    </div>
  )
}

export default SearchBox