import React, { useState } from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Track from './Track';
import MusicRelease from './MusicRelease';
import { Button, Typography } from '@mui/material';
import VideoRoyalty from './VideoRoyalty';
import Stores from './Stores';
import Months from './Months';
import Countries from './Countries';
import { useLocation } from 'react-router-dom';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useHistory, useParams } from 'react-router-dom';
function Details(props) {
    const location = useLocation();
    const tabDynamic = location?.state?.state
    const { studioId } = useParams()
    const history = useHistory();
    const initialValue = tabDynamic.data && tabDynamic.data.length > 0 ? tabDynamic.data[0].tabType : null;
    const [value, setValue] = useState(initialValue);
    const handleGoBack = () => {
        if (studioId == undefined || studioId == null || studioId == "") {
            history.push("dashboard");
        } else {
            history.push(`/analytics/${studioId}`);
        }
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const tabIcon = (iconName) => {
        switch (iconName) {
            case "audioicon": {
                return <AudiotrackIcon sx={{ fontSize: '15px' }} />
            }
            case "storeicon": {
                return <LibraryMusicIcon sx={{ fontSize: '15px' }} />
            }
            case "storeicon": {
                return <LibraryMusicIcon sx={{ fontSize: '15px' }} />
            }
            case "videoicon": {
                return <VideoLibraryIcon sx={{ fontSize: '15px' }} />
            }
            case "countryicon": {
                return <FmdGoodIcon sx={{ fontSize: '15px' }} />
            }
            case "musicicon": {
                return <QueueMusicIcon sx={{ fontSize: '15px' }} />
            }
            case "monthicon": {
                return <CalendarTodayIcon sx={{ fontSize: '15px' }} />
            }
                // code block
                break;
            default:
            // code block
        }

    }
    return (
        <Box>
            <Box className='container mt-3'>
                <Box>
                    <Button className='justify-content-start text-dark gap-2' onClick={handleGoBack}><ArrowBackIcon />{tabDynamic.name}</Button>
                </Box>
                <Box className="bg-white shadow-sm rounded mt-3">
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList
                                scrollButtons="auto" // Change this to "auto" or "on" to enable scroll buttons
                                variant="scrollable"
                                orientation="horizontal"
                                sx={{
                                    '& .Mui-selected': {
                                        color: '#E41116 !important', // Change this to the desired color
                                    },
                                    '& .MuiTabs-indicator': {
                                        backgroundColor: '#E41116', // Change this to the desired color for the indicator
                                    },
                                }}
                                onChange={handleChange} aria-label="lab API tabs example">
                                {tabDynamic?.data ? tabDynamic?.data.map(item => {
                                    return (
                                        <Tab icon={tabIcon(item.icons)} iconPosition="start" label={item.tabTitle} key={item.id} value={item.tabType} />)
                                }) : null}
                            </TabList>
                        </Box>
                        {tabDynamic?.data ? tabDynamic?.data.map(item => (
                            item.tabType === value ? (
                                <TabPanel key={item.id} value={value}>
                                    <>
                                        {item.tabType === 'track' ? <Track hideTotal={true} /> : null}
                                        {item.tabType === 'musicrelease' ? <MusicRelease hideTotal={true} /> : null}
                                        {item.tabType === 'stores' ? <Stores hideTotal={true} /> : null}
                                        {item.tabType === 'country' ? <Countries hideTotal={true} /> : null}
                                        {item.tabType === 'months' ? <Months hideTotal={true} /> : null}
                                        {item.tabType === 'videos' ? <VideoRoyalty hideTotal={true} /> : null}
                                    </>
                                </TabPanel>
                            ) : null
                        )) : null}
                    </TabContext>
                </Box>
            </Box>

        </Box>
    );
}

export default Details;