export const getSongStatusBgColor = (songStatus) => {
    songStatus = songStatus?.replaceAll(' ', '_').toLowerCase();
    if (songStatus) {
        switch (songStatus) {
            case 'in_review':
                {
                    return "#26C6F9"
                }
            case 'pending':
                {
                    return "#26C6F9"
                }
            case 'ready_for_release':
                {
                    return "#FDB528"
                }
            case 'no_status':
                {
                    return "#FDB528"
                }
            case 'released':
                {
                    return ' #787EFF'
                }
            case 'verified':
                {
                    return ' #787EFF'
                }
            case 'accepted':
                {
                    return ' #787EFF'
                }
            case 'rejected':
            case 'reject':
                {
                    return '#FF4D49'
                }
            case 'unverified':
                {
                    return '#FF4D49'
                }
            case 'completed':
                {
                    return ' #787EFF'
                }
            case 'success':
                {
                    return ' #787EFF'
                }
            case 'approved':
                {
                    return '#787EFF'
                }
            case 'success':
                {
                    return ' #2E7D32'
                }
            default:
                return '#6c757d'
        }
    }
}

