import React, { useState } from 'react'
import { Box, Card, CardContent, CardMedia, Checkbox, Divider, Grid, Stack } from '@mui/material'
import artist from '../../img/userAdmin.png'
import { getDateAsPerFormat } from '../../_helpers/reusablefunctions/dateSetter'
import { useHistory } from 'react-router-dom'
// import { getSongStatusBgColor } from '../../_helpers/reusablefunctions/getColor';

const cardStyle = {
  display: 'flex',
  minWidth: '15rem',
  minHeight: '12rem',
}

const StudioListCardLayout = ({ cardData, moduleName }) => {
  const [selected, setSelected] = useState([]);
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const history = useHistory();
  const genreNames = cardData.genres.map((genre) => (<span key={genre.id}>{genre.genre}</span>));
  const genreString = genreNames.join(', ');

  return (
    <Card variant="outlined" className='shadow-table mt-4' sx={cardStyle}>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', }} >
        <Stack direction='row' spacing={1} alignItems='center'>
          <div className='d-flex justify-content-space-between w-100 align-items-center'>
            <div className="item p-2 " >
              <CardMedia
                component="img"
                sx={{ width: '56px', height: '56px', borderRadius: '50%' }}
                image={cardData.avatar ? process.env.REACT_APP_STUDIOURL_MEDIA + 'media/' + cardData.avatar : artist}
              />
            </div>
            {cardData.studio_name}
          </div>
        </Stack>
        <CardContent className='p-2' sx={{ flex: '1 0 auto' }} >
          <div>
            <div className='text-truncate  cardDetailFontSize'>
              <span style={{ fontWeight: 500, marginRight: '40px' }}>
                Genre
              </span>
              <span
                title={cardData.genres.map((genre) => genre.genre).join(", ")}
                style={{ fontWeight: 400 }}
              >
                {cardData.genres.map((genre, index) => (
                  <React.Fragment key={genre.id}>
                    <span>{genre.genre}</span>
                    {index !== cardData.genres.length - 1 && <span>, </span>}
                  </React.Fragment>
                ))}
              </span>
            </div>
            <div className='py-1'><Divider /></div>
            <div className='text-truncate cardDetailFontSize'><span style={{ fontWeight: 500, marginRight: '22px' }}>Email</span><span style={{ fontWeight: 400 }}>{cardData.email}</span></div>
            <div className='py-1'><Divider /></div>
            <div className='text-truncate cardDetailFontSize'><span style={{ fontWeight: 500, marginRight: '45px' }}>Phone Number </span><span style={{ fontWeight: 400 }}>{cardData.phone_number}</span></div>
          </div>
        </CardContent>
      </Box>
    </Card>
  )
}

export default StudioListCardLayout