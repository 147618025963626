
import React, { useEffect, useState } from 'react';
import { Box, Chip, Paper, Stack, Typography, useMediaQuery } from '@mui/material';
import SnackbarContainer from '../Snackbar';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import TableComponent from '../importRevenue/Tablecomponent';
import { getSongStatusBgColor } from '../../_helpers/reusablefunctions/getColor';
import playlist from '../../img/Frame 48096170.png'
import { getDateAsPerFormat } from '../../_helpers/reusablefunctions/dateSetter';

export default function ReleaseTable({ albumListData, page, rowsPerPage, setRowsPerPage, setPage }) {
  const [rows, setRows] = useState(albumListData.results);
  const [totalCount, setTotalCount] = useState('');
  const history = useHistory();

  useEffect(() => {
    setRows(albumListData.results)
    setTotalCount(albumListData?.total_count)
  }, [albumListData])


  const columns = [
    {
      id: 'album_name', label: 'Release', headerName: '', flex: 1, template: (row) => {
        return (
          <Box className="d-flex align-items-center gap-2" onClick={() => redirectToAlbumDetail(row.album_id)}>
            <img className="rounded-pill" src={row.cover_image != null ? process.env.REACT_APP_SONGAPIURL_MEDIA + 'media/' + row.cover_image : playlist} style={{ width: '40px', height: '40px' }} />
            <Typography variant='span'>{row.album_name}</Typography>
          </Box>
        )
      }
    },
    { id: 'album_song_count', field: 'album_song_count', label: 'Song Count' },
    { id: 'genre', field: 'genre', label: 'Genre' },
    {
      id: 'primary_artists', label: 'Artist', headerName: '', flex: 1, template: (row) => {
        return (
          <Box>
            {row?.primary_artists.length ? row.primary_artists.map(p => p.artist_name).join(', ') : "--"}
          </Box>
        )
      }
    },
    {
      id: 'release_date', label: 'Release Date', headerName: '', flex: 1, template: (row) => {
        return (
          <Box>
            {row.release_date == (null || '') ? '--' : getDateAsPerFormat(row.release_date, "DD Mon, YYYY")}
          </Box>
        )
      }
    },
    {
      id: 'song_status', label: 'Status', headerName: '', flex: 1, template: (row) => {
        return (
          <Stack direction="row" spacing={1}>
            <Chip className='p-2 text-white' sx={{ background: getSongStatusBgColor(row?.song_status) }} label={row.song_status == "draft" ? "Draft" : row.song_status} />
          </Stack>
          // <Box className='leftStatusCard' style={{ textAlign: "center", bottom: '14px', background: getSongStatusBgColor(row.song_status), borderRadius: "15px", color: '#fff', padding: '5px', fontSize: '12px' }}>
          //   {row.song_status == "draft" ? "Draft" : row.song_status}
          // </Box>
        )
      }
    },
  ];

  function redirectToAlbumDetail(id) {
    history.push(`/albumdetail/${id}`);
  }


  return (
    <div className="container-fluid py-4 creator-playlist-panel">
      <Paper className="shadow-table" sx={{ width: '100%', mb: '3rem' }}>
        <TableComponent columns={columns} data={rows} page={page} setPage={setPage} removeStyle={'removeStyle'} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} totalCount={totalCount} />
      </Paper>
      <SnackbarContainer />
    </div>
  );
}