import { FETCH_SONG_RELEASE_DATA_FAILURE,
    FETCH_SONG_RELEASE_DATA_SUCCESS,
    FETCH_SONG_RELEASE_DATA_REQUEST } from "./songReleaseTypes";
import {getUserId} from '../../_helpers/auth/secureToken' //to pass the user id 
import { submitSong } from "../../_services/submitAsong/submitAsong.service";
import { getSong } from "../../_services/submitAsong/showAllSong";

export function fetchSongReleaseList()
{
    return (dispatch)=>{
        fetchSongReleaseDataRequest()
        // submitSong({'userId':getUserId()},'GETFORSEPECICARTIST')
        getSong('GET')
        .then((response)=>{
            const releaseSongList = response.data;
            dispatch(fetchSongReleaseDataSuccess(releaseSongList))
        })
        .catch(error => {
            dispatch(fetchSongReleaseDataFailure(error.message))
          })
    }
}

export function fetchSongReleaseDataRequest(){
    return{
        type:FETCH_SONG_RELEASE_DATA_REQUEST
    }
} 

export function fetchSongReleaseDataSuccess(releaseSongList){
    return{
        type:FETCH_SONG_RELEASE_DATA_SUCCESS,
        payload:releaseSongList
    }
}

export function fetchSongReleaseDataFailure(error){
    return{
        type:FETCH_SONG_RELEASE_DATA_FAILURE,
        payload:error
    }
}  