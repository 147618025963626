import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { setLoader } from '../../../redux';
import ApiService from '../../../_services/ApiService';
import { useLocation, useHistory } from 'react-router-dom';
import { Box, Button, Grid, Link, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import UserDetail from './userDetail';
import PaymentAddress from './paymentaddress';
import AccountDetail from './accountDetail';
import AlbumDetail from './albumDetail';
import SongDetail from './songDetail';
import ContractDetail from './contractDetail';
import PaymentDetail from './paymentDetail';

function StudioCardDetail(props) {
  const dispatch = useDispatch();
  const history = useHistory()
  const location = useLocation()
  const getId = location.state.state.data
  const [userDetail, setUserDetail] = useState([])
  const [studioDetail, setStudioDetail] = useState([])
  const [value, setValue] = React.useState(0);

  const revenueViewDetail = [{ id: 1, label: 'Studio Detail' },
  { id: 2, label: 'Payment Address' },
  { id: 3, label: 'Account Detail' },
  { id: 4, label: 'Album Detail' },
  { id: 5, label: 'Song Detail' },
  { id: 6, label: 'Contract Detail' },
  { id: 7, label: 'Payment Detail' },
  ]

  useEffect(() => {
    getData()
  }, [])

  function getData() {
    dispatch(setLoader(true))
    ApiService.get('studioUser', {}, getId.studio_id).then(response => {
      dispatch(setLoader(false))
      if (response.status == 200) {
        setUserDetail(response.data)
      }

    }).catch(function (error) {
      dispatch(setLoader(false))
      return error.response;
    })
    ApiService.get('studioDetail', {}, getId.studio_id).then(response => {
      dispatch(setLoader(false))
      if (response.status == 200) {
        setStudioDetail([response.data])
      }

    }).catch(function (error) {
      dispatch(setLoader(false))
      return error.response;
    })
  }

  function backNavigate() {
    history.push('/studiolist')
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className='container-padding mx-3' >
      <Typography variant='h5' className='cursorPointer d-flex align-items-center gap-2' onClick={backNavigate} ><ArrowBackIcon /> {getId.studio_name} </Typography>
      <Box
        mt={3}
        sx={{
          flexGrow: 1,
          // maxWidth: { xs: 320, sm: 480 },
          bgcolor: 'background.paper',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          aria-label="visible arrows tabs example"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': { opacity: 0.3 },
            },
          }}
        >
          {revenueViewDetail.map((d, i) => (
            <Tab key={i} label={d.label} />
          ))}
        </Tabs>
      </Box>
      {(() => {
        switch (value) {
          case 0:
            return <UserDetail userDetail={userDetail} />;
          case 1:
            return <PaymentAddress studioDetail={studioDetail} />;
          case 2:
            return <AccountDetail studioDetail={studioDetail} />;
          case 3:
            return <AlbumDetail studioDetail={studioDetail} />;
          case 4:
            return <SongDetail studioDetail={studioDetail} />;
          case 5:
            return <ContractDetail studioDetail={studioDetail} />;
          case 6:
            return <PaymentDetail studioDetail={studioDetail} />;
          // case 7:
          //   return;
          // case 8:
          // return;
        }
      })()}
    </Box>
  )
}

export default StudioCardDetail;