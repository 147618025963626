import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SnackbarContainer from '../Snackbar';
import NoResults from '../../components/reusableComponents/NoResults';
import AlbumCard from './AlbumCard';
import InfiniteScroll from 'react-infinite-scroll-component';
import { fetchReleaseAlbum } from '../../redux/releaseAlbum/releaseAlbumDataActions';
import { useEffect } from 'react';
import SearchBox from '../../components/reusableComponents/SearchBox';
import { setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import ApiService from '../../_services/ApiService';

function AlbumList(props) {
  const userData = useSelector((state) => state.userData.userData);
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [albumList, setAlbumList] = useState([]);
  const [hasMore, setHasMore] = useState(false)
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [flag, setFlag] = useState(false);
  const [searchInput, setSearchInput] = useState(null)

  const albumData = useSelector((state) => state.releaseAlbumReducer?.releaseAlbumData)
  const subGenreList = useSelector((state) => state.musicCategorgyReducer?.subGenreData ? state.musicCategorgyReducer?.subGenreData.data : []);
  const genre = useSelector((state) => state.musicCategorgyReducer?.genreData ? state.musicCategorgyReducer?.genreData.data : []);
  useEffect(() => {
    if (userData.id != undefined) {
      let d = albumData ? albumData.data : []
      if (albumData.data) { // Check if albumList is empty before setting
        // setPage(page+1)
        setAlbumList(albumData.data);
      } else {
        if (page == 0 && albumList.length == 0) {
          dispatch(fetchReleaseAlbum({ limit: 25, offset: page }, d));

          // getAssociatedArtist();
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }
    }
    // getAlbumList()
  }, [userData.id && albumData]); // Make sure to add albumList and page as dependencies



  const fetchMoreData = () => {
    setFlag(true);
    setPage(page + 1)
    const nextPage = page + 1; // Calculate the next page value
    if (albumList.length != albumData.totalCount) {
      setPage(nextPage); // Update the page state
      dispatch(fetchReleaseAlbum({ limit: 25, offset: nextPage }, albumData.data)); // Dispatch action with updated page value
    } else {
      setFlag(false);
    }
  };

  const getSongListSearch = (value, page) => {
    const offset = page;
    const limit = 25;
    dispatch(setLoader(true))
    ApiService.get('searchSong', { 'search': value, limit, offset }).then(function (response) {
      dispatch(setLoader(false))
      console.debug("songlist: response", response)
      if (response.status === 200) {
        setAlbumList(response.data.results)
        setPage(page);
      }
      else {
        dispatch(setSnackBar(true));
        dispatch(setSnackBarMessage('Something went wrong'));
        dispatch(setSnackBarVariant('error'));
        setTimeout(() => dispatch(setSnackBar(false)), 3000)
      }
    }).catch(function (error) {
      dispatch(setLoader(false))
      dispatch(setSnackBar(true));
      dispatch(setSnackBarMessage('Something went wrong'));
      dispatch(setSnackBarVariant('error'));
      setTimeout(() => dispatch(setSnackBar(false)), 3000)
      return error.response;
    })
  }
  const handleSearchInput = (value) => {
    if (value) {
      setSearchInput(value)
    } else {
      dispatch(fetchReleaseAlbum({ limit: 25, offset: page }, albumData.data));
    }
  }
  useEffect(
    function () {
      if (searchInput) {
        let delaySearch;
        delaySearch = setTimeout(() => {
          getSongListSearch(searchInput, 0)
        }, 1000);
        return () => clearTimeout(delaySearch);
      } else {
      }
    }, [searchInput])

  return (
    <div className="container-fluid py-4 creator-playlist-panel">
      <div className="row m-0">
        <div className="col-12">
          <div className='d-flex justify-content-between'>
            <h2>Release Song</h2>
            <SearchBox placeholder="Search" handleSearchInput={handleSearchInput} />
          </div>
          <>
            <div >
              {/* <button className="gn-actionbtn" onClick={openDrawer}>
                CREATE ALBUM
              </button> */}
              <div >
                {albumList.length == 0 ?
                  <div className="wrapper-card py-4">

                    {/* {skeletonCards} */}
                  </div> :

                  albumList.length > 0 ?
                  <InfiniteScroll
                  dataLength={albumList !=undefined?albumList.length:[]}
                  next={fetchMoreData}
                  hasMore={true}
                  loader={flag == true ? <>Loading........</>: false}
                >
                    <div className="wrapper-card">
    
                      {albumList.map((album, index) => (
                        <AlbumCard
                        key={index}
                        albumInfo={album}
                        subGenreList={subGenreList}
                        genre={genre}
                        // assoArtistList={assoArtistList}
                        // getArtistName={getArtistName}
                        />
                      ))}</div> </InfiniteScroll> :
                  <NoResults />
                    }
              </div>
            </div>
          </>
        </div>
      </div>
      <SnackbarContainer />
    </div>
  );
}

export default AlbumList;
