import React, { useState, useEffect } from 'react'
import Chip from '@mui/material/Chip';
import DoneIcon from '@mui/icons-material/Done';
import { getOrders } from "../../_services/orders/getOrders.service"
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { useDispatch } from 'react-redux'
import { setLoader } from '../../redux';
import 'react-loading-skeleton/dist/skeleton.css'
function Order(props) {
  const dispatch = useDispatch();
  const [orderData, setOrderData] = useState([])
  const [data, setData] = useState([])
  const [plan, setPlan] = useState([])
  const [childDom, setChildDom] = useState([""]);

  useEffect(function () {
    dispatch(setLoader(true))

    getOrders().then(function (response) {
      setData(response.data.data);

      dispatch(setLoader(false))

      setOrderData(response.data.data)
      setPlan(response.data.data.plan)

      // childRender(response.data.data)
    }).catch(function (error) {
      dispatch(setLoader(false))

      console.log(error);
      console.log(error.response);
      return error.response;
    })
  }, []);
 
  return (
    <div>
      <h4>Order</h4>
      {
  data==null ? 
  <Skeleton variant="rectangular" height={70}/>:
      <div>  {orderData.map(data => {
        return (
          <div className="card my-2 shadow" key={data.id}>
            <div className="card-header">
              <div className="row justify-content-evenly">
                <div className="col-md-2">
                  <h6>Order ID</h6>
                </div>
                <div className="col-md-2">
                  <h6>Plan Name</h6>
                </div>
                <div className="col-md-2">
                  <h6>Purchase Date</h6>
                </div>
                <div className="col-md-2">
                  <h6>Amount </h6>
                </div>
                <div className="col-md-2 text-center">
                  <h6>Status</h6>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div id="accordion">
                <div className="row justify-content-evenly">
                  <div className="col-md-2">
                    <h6>{data.id} </h6>
                  </div>
                  <div className="col-md-2">
                    <h6>{data.plan != null ? data.plan.planName : ""}</h6>
                  </div>
                  <div className="col-md-2">
                    <h6>{data.dateOfPurchase} </h6>
                  </div>
                  <div className="col-md-2">
                    <h6>{data.amount}</h6>
                  </div>
                  <div className="col-md-2 text-center">
                    <Chip icon={<DoneIcon />} color="success" label="confirmed" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 px-5 m-1 py-3">
                  <span data-bs-toggle="collapse" href="#collapseOne" className="btn p-0 "><h6>View Order Detail</h6></span>
                  <div id="collapseOne" className="collapse show" data-bs-parent="#accordion">
                    <div className="border-bottom py-1"></div>
                    <div className="row py-2 ">
                      <div className="col-md-4">
                        <h6>Email</h6>
                        <p>{data.email}</p>
                      </div>
                      <div className="col-md-4">
                        <h6>Payment Method</h6>
                        <p>{data.paymentMethod}</p>
                      </div>
                      <div className="col-md-4">
                        <h6>Order Summary</h6>
                        <p>{data.orderSummary}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>)
      })}</div>
    }
    </div>
  );
}

export default Order