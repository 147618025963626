import { Box, Card, CardContent, Grid, TablePagination, useMediaQuery } from '@mui/material'
import React, { useRef } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import AudioPlayer from '../../components/reusableComponents/customAudioPlayer';
import DeleteModal from '../../components/reusableComponents/DeleteModal';
import { getDateAsPerFormat } from '../../_helpers/reusablefunctions/dateSetter';
import NoResults from '../../components/reusableComponents/NoResults';

function DemoCard({ cardData, handleDeleteItem, id, index, deleteHandler, handleChangePage, handleChangeRowsPerPage, page, rowsPerPage }) {
    const ref = useRef()
    const smScreen = useMediaQuery('(max-width:600px)');
    const cardStyle = {
        display: 'flex',
        minWidth: smScreen ? '100%' : '19rem',
        // width:'364.67px',
        minHeight: smScreen ? '100%' : '10rem',
        // height:'222px',
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1) !important',
        borderRadius: '6px',
    }

    return (
        <div >
            {cardData && cardData?.results.length > 0 ? (
                <Grid
                    className='px-2'
                    container
                    spacing={2}
                    columns={{ xs: 12, sm: 12, md: 12 }}
                >
                    {cardData.results.map((cardD, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={cardD.id}>
                            <Card variant="outlined" className='shadow-table mt-4' sx={cardStyle}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }} >
                                    <CardContent className='p-3' sx={{ flex: '1 0 auto' }} >
                                        <div>
                                            <div className='text-truncate'><strong style={{ fontSize: '0.8rem' }} className='text-danger' >
                                                Upload date: <span>{getDateAsPerFormat(cardD.created, "DD Mon, YYYY")}</span>
                                            </strong>
                                            </div>
                                            <div className='mt-2 text-truncate'><h5>{cardD.audio_name}</h5></div>
                                            <div className='py-2' style={{ minHeight: '50px', maxHeight: '50px' }}>
                                                <div>
                                                    <p title={cardD.description} className="text-secondary"> {cardD?.description?.length > 80 ? `${cardD.description.slice(0, 75)}...` : cardD.description}</p>
                                                </div>
                                            </div>
                                            <div className='d-flex justify-content mt-4'>
                                                <AudioPlayer type="demoAudio" audioTitle={cardD.audio_name} audioDescription={cardD.description} audioSrc={cardD.audio_clip ? `${process.env.REACT_APP_BASEURL}/media/${cardD.audio_clip}` : ''} />
                                                {/* <div className='text-secondary  my-1 mx-2'>
                                                    <button title="Delete" className='gn-btn-icononly text-secondary' key={`delete_${cardD.id}`}> {cardData && cardD.id !== undefined ? <span id={cardD.id} onClick={() => ref.current.handleOpen()}> <DeleteIcon /></span> : <span id={cardD.id}></span>}</button>
                                                </div> */}
                                            </div>

                                        </div>

                                    </CardContent>
                                </Box>
                            </Card>
                            {/* <DeleteModal cardId={cardData && cardD.id} deleteHandler={handleDeleteItem} ref={ref} /> */}
                        </Grid>))}
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        colSpan={3}
                        sx={{ width: '100%', marginTop: '20px', padding: '32px 0px 32px 32px' }}
                        className="cardPagination"
                        component="div"
                        count={cardData.total_count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        SelectProps={{
                            inputProps: {
                                'aria-label': 'rows per page',
                            },
                            native: true,
                        }}
                    />
                </Grid>
            )
                : (
                    <NoResults />
                )}
        </div>
    )
}

export default DemoCard