import { Grid, TablePagination } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ResponsiveCardList from '../../components/reusableComponents/songResponsiveList';
import useMediaQuery from '@mui/material/useMediaQuery'
import NoResults from '../../components/reusableComponents/NoResults';
import ArtistListCardLayout from '../../components/reusableComponents/ArtistListCardLayout';
import StudioListCardLayout from './studioListCardLayout';


const StudioCard = ({ studioListData, handleChangePage, handleChangeRowsPerPage, page, rowsPerPage }) => {
  const smScreen = useMediaQuery('(max-width:768px)');
  const [studiolist, setStudioList] = useState(studioListData.results)
  useEffect(() => {
    setStudioList(studioListData.results)
  })

  return (
    <div >
      {studiolist && studiolist.length > 0 ? (
        !smScreen ? (
          <Grid
            className='px-2'
            container
            spacing={2}
            columns={{ xs: 12, sm: 12, md: 12 }}
          >
            {studiolist
              .map((studio, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={studio.studio_id}>
                  <StudioListCardLayout cardData={studio} />
                </Grid>
              ))}
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              colSpan={3}
              sx={{ width: '100%', marginTop: '20px', padding: '32px 0px 32px 32px' }}
              className="cardPagination"
              component="div"
              count={studioListData.total_count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
            />
          </Grid>
        ) : (
          <React.Fragment>
            {studiolist
              .map((artist, index) => (
                <div key={artist.artist_id}>
                  <ResponsiveCardList
                    cardData={artist}
                    moduleName="artistList"
                  />
                </div>
              ))}
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              colSpan={3}
              sx={{ width: '100%', marginTop: '20px', padding: '32px 0px 32px 32px' }}
              className="cardPagination"
              component="div"
              count={studioListData.total_count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
            />
          </React.Fragment>
        )
      ) : (
        <NoResults />
      )}
    </div>

  )
}//end

export default StudioCard