import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, TextField, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

export default function SongConfirmationDialog({ onchangeRjectHandler, approvePitch, open, setOpen, statusValue }) {
    const location = useLocation()
    const songDataView = location?.state?.state || ''
    const upcMessage = (!songDataView?.songData?.upc_code) ? "album UPC " : "";
    const isrcMessage = (!songDataView?.songData?.isrc_code) ? "song ISRC" : "";
    const message = upcMessage || isrcMessage ? `Please add the ${upcMessage}${upcMessage && isrcMessage ? "and " : ""}${isrcMessage} to proceed with the submission.` : "";

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Dialog
                maxWidth={'sm'}
                fullWidth={true}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {(!songDataView?.songData?.upc_code || !songDataView?.songData?.isrc_code) && statusValue != "Reject" ? (<Box>
                    <DialogTitle className='px-4 p-4' id="alert-dialog-title">
                        {"Review the Information carefully"}
                    </DialogTitle>
                    <DialogContent className='px-4'>
                        {message}
                    </DialogContent>
                    <DialogActions className='px-4 pb-4'>
                        <Button onClick={handleClose} variant="contained" className='gn-actionbtn'>Ok</Button>
                    </DialogActions>
                </Box>) : (<Box>
                    <DialogTitle className='px-4 p-4' id="alert-dialog-title">
                        {"Review the Information carefully"}
                    </DialogTitle>
                    <DialogContent className='px-4'>
                        <Typography sx={{ color: '#161513', marginBottom: '10px' }}>
                            Are you sure you want to {statusValue == "Reject" ? 'reject' : 'submit for pitching'}
                        </Typography>
                        <Typography sx={{ color: '#00000066' }} variant='span'>
                            You can not undo this action.
                        </Typography>
                        {statusValue == "Reject" ?
                            <Box className="mt-2">
                                <TextField fullWidth id="comment" label="Comment" variant="outlined" onChange={onchangeRjectHandler} required />
                            </Box>
                            : ''
                        }
                    </DialogContent>
                    <DialogActions className='px-4 pb-4'>
                        <Button onClick={handleClose} className='textBtn'>Cancel</Button>
                        <Button onClick={approvePitch} autoFocus variant="contained" className='gn-actionbtn'>
                            Submit Now
                        </Button>
                    </DialogActions>
                </Box>)}
            </Dialog>
        </React.Fragment>
    );
}
