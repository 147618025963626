import React, { useState } from 'react';
import { Email } from '@mui/icons-material'
import { Lock } from '@mui/icons-material'
import { useHistory, useLocation, Redirect,useParams } from 'react-router-dom';
import logo from "../img/groovenexusLogo.png"
import Footer from './footer';
import { Link } from 'react-router-dom';
import InputBase from '@mui/material/InputBase';
import google from '../img/google.svg'
import { login } from '../_services/auth/auth.service';
import { resetPassword } from "../_services/resetPassword"
import {
    setSsoToken,
    setSelfToken,
} from '../_helpers/auth/secureToken';
import { changePassword } from '../_services/changePassword/changePassword.service';
import { setLoader } from '../redux';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { useDispatch } from 'react-redux'
import {
    setAppToken,
    setUserAuthType,
    getSelfToken,
    setTempSsoToken,
    getAppToken,
    getUserAuthType
} from '../_helpers/auth/secureToken';
import { isLogin } from '../_helpers/auth/secureToken';

import { authentication } from '../config/auth/AppConstants'



function ChangePassword(props) {
    const dispatch = useDispatch();
    let history = useHistory();
    let location = useLocation();
    let {token} = useParams();

    let { from } = location.state || { from: { pathname: "/" } };
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState();
    const [password, setPassword] = useState("");
    const [passError, setPassError] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPassError, setNewPassError] = useState("");
    const [conPassword, setConPassword] = useState("");
    const [conPassError, setConPassError] = useState("");

    const [showPassword, setShowPassword] = useState(false);
    const [showConPassword, setShowConPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);



    async function onClickHandler(e) {
        e.preventDefault();
        let data = new FormData();
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        var regexp = "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
        var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
     
        if (email.match(mailformat)) {
            data.append('email', email);
            setEmailError("")
        } else {
            setEmailError(
                <label className="text-danger mt-1">Please enter valid Email</label>
            )
        }

        if (password.match(regexp) || format.test(password)) {
            data.append('password', password);
            setPassError("")
        } else {
            setPassError(
                <span className="text-danger mt-1">Please enter valid password </span>
            )
        }
        if (newPassword.match(regexp) || format.test(newPassword)) {
            data.append('new_password', newPassword);

            setNewPassError("")
        } else {
            setNewPassError(
                <span className="text-danger mt-1">Please enter valid password </span>
            )
        }
        if (conPassword.match(newPassword)) {
            data.append('c_password', conPassword);

            setConPassError("")
        } else {
            setConPassError(
                <span className="text-danger mt-1">Please enter valid confirm password </span>
            )
        }
        if (email.match(mailformat) && password.match(regexp) || format.test(password) && conPassword.match(newPassword) && newPassword.match(regexp) || format.test(newPassword)) {
            dispatch(setLoader(true))
            changePassword(data).then(function (response) {
                dispatch(setLoader(false))
            }).catch(function (error) {
                dispatch(setLoader(false))
                console.log(error);
                console.log(error.response);
                return error.response;
            })
        }
    }

    return (
        <div className="container-fluid p-0">
            <div className="row">
                <nav className="navbar navbar-light  border pb-0">
                    <div className="container-fluid">
                        <a className="navbar-brand p-3" href="https://admin.gncreators.com/"><img src={logo} /></a>
                    </div>
                </nav>
                <div className="col-md-6 offset-md-3">
                    <div className="login-form  mt-4 mb-4 p-4 border rounded shadow">
                        <form action="" method="" className="row g-3">
                            <h2 className="text-center">Change Your Password</h2>
                            <div className="col-md-6">
                            <input type="hidden" name="token" value="{{ $token }}"/>
                                <div className="border  d-flex p-2 rounded">
                                    <Email className="  text-secondary" />
                                    <InputBase
                                        type='text'
                                        className="ms-2 w-100"
                                        placeholder="Email Address *"
                                        name="email"
                                        onChange={e => { setEmail(e.target.value); setEmailError("") }}
                                    />
                                </div>
                                {emailError}
                            </div>

                            <div className="col-md-6">
                                <div className="border  d-flex p-2 rounded">
                                    <Lock className="  text-secondary" />
                                    <InputBase
                                        type={showPassword ? "text" : "password"}
                                        className="ms-2 w-100"
                                        placeholder=" Password *"
                                        name="password"
                                        onChange={e => { setPassword(e.target.value); setPassError("") }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={(e) => setShowPassword(!showPassword)}
                                                    edge="end"
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </div>
                                {passError}
                            </div>
                         
                            <div className="col-md-6">
                                <div className="border  d-flex p-2 rounded">
                                    <Lock className="  text-secondary" />
                                    <InputBase
                                        type={showNewPassword ? "text" : "password"}
                                        className="ms-2 w-100"
                                        placeholder=" New Password *"
                                        name="new password"
                                        onChange={e => { setNewPassword(e.target.value); setNewPassError("") }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={(e) => setShowNewPassword(!showNewPassword)}
                                                    edge="end"
                                                >
                                                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </div>
                                {newPassError}
                            </div>
                            <div className="col-md-6">
                                <div className="border  d-flex p-2 rounded">
                                    <Lock className="  text-secondary" />
                                    <InputBase
                                        type={showConPassword ? "text" : "password"}
                                        className="ms-2 w-100"
                                        placeholder=" Confirm New Password *"
                                        name="confirmPassword"
                                        onChange={e => { setConPassword(e.target.value); setConPassError("") }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={(e) => setShowConPassword(!showConPassword)}
                                                    edge="end"
                                                >
                                                    {showConPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </div>
                                {conPassError}
                            </div>
                            <div className='col-md-12 p-0 m-0'>
                                <ul className='ps-3 mt-2'>
                                    <li className='small'>Password must contain:</li>
                                    <li className='small'> At least 8 characters,one special characters, one number. </li>
                                    <li className='small'> At least one uppercase letter ,one lowercase letter .</li>
                                </ul>
                            </div>
                            <div className="col-md-12 m-0">
                                <span>Go to the:</span>  <Link to="/login" className="text-decoration-none">Login</Link>
                                <button type="submit" className="btn btn-danger  float-end px-4 py-2" onClick={onClickHandler}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}



export default ChangePassword;