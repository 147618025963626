
import {authentication} from '../../config/auth/AppConstants';
import {user} from '../../config/auth/AppConstants';


export function setUserId(id){
    localStorage.setItem("userId", id);
}

export function getUserId()
{
    return localStorage.getItem("userId");
}
export function getSelfToken(){
    var obj;
    var key=user.selfToken;
    obj=(localStorage.getItem(user.user));
    if(obj!=null){
        obj=JSON.parse(obj);
        return obj[key];
    }else{
        return obj;
    }
}
export function setSelfToken(token){
    var obj=localStorage.getItem(user.user);
    if(obj){
        obj=JSON.parse(obj);
    }else{
        obj={};
    }
    // var obj={};
    var key=user.selfToken;
    obj[key]=token;
    localStorage.setItem(user.user, JSON.stringify(obj));
}

// export function getSelfToken(){
//     var obj;
//     var key=user.selfToken;
//     obj=(localStorage.getItem(user.user));
//     if(obj!=null){
//         obj=JSON.parse(obj);
//         return obj[key];
//     }else{
//         return obj;
//     }
// }

export  function getSsoToken(){
    var obj;
    var key=user.ssoToken;
    obj=(localStorage.getItem(user.user));
    obj=JSON.parse(obj);
    return obj[key];
}

export function setSsoToken(token){
    var obj=(localStorage.getItem(user.user));
    obj=JSON.parse(obj);
    var key=user.ssoToken;
    obj[key]=token;
    localStorage.setItem(user.user, JSON.stringify(obj));
}

export function getAppToken(){
    var obj;
    var key=user.appToken;
    obj=(localStorage.getItem(user.user));
    obj=JSON.parse(obj);
    return obj[key];
}

export function setAppToken(token){
    var obj=localStorage.getItem(user.user);
    if(obj){
        obj=JSON.parse(obj);
    }else{
        obj={};
    }
    var key=user.appToken;
    obj[key]=token;
    localStorage.setItem(user.user, JSON.stringify(obj));

}
export function setUserAuthType(token){
    var obj=localStorage.getItem(user.user);
    if(obj){
        
        obj=JSON.parse(obj);
    }else{
        obj={};
    }
    var key=user.authType;
    obj[key]=token;
    localStorage.setItem(user.user, JSON.stringify(obj));
}

export function getUserAuthType(){
    var obj;
    var key=user.authType;
    obj=(localStorage.getItem(user.user));
    obj=JSON.parse(obj);
    if(obj)
        return obj[key];
    else
        return false;
}

export function isSsoLogin(){
    if(getSelfToken() && getSsoToken() && getAppToken)
        return true;
    else
        return false;    
}

export function isSelfLogin(){
    if(getSelfToken() )
        return true;
    else
        return false;    
}

export function isLogin(){
    switch(getUserAuthType()){
        
        case authentication.ssoAuthType:
            return isSsoLogin();
        case authentication.selfAuthType:
            return isSelfLogin();
        default:
            return false;
    }
}

export function removeUserToken(){
    console.log("remove auth called");
    localStorage.removeItem(user.user);
    //added 01122022 to remove the stores userId on localStorage need to upgrade the logic
    localStorage.removeItem("userId"); 
}

