import React, { useState, useEffect } from 'react'
import SearchBox from '../../components/reusableComponents/SearchBox';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLoginUser, setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { formSnackbar } from '../../_services/_constants/snackbar.constant';
import { useMediaQuery } from '@mui/material';
import DemoDataTable from './DemoDataTable';
import { getDemo } from '../../_services/creater/showAllDemo';
import { submitDemo } from '../../_services/creater/creatorDemo.service';
import { default_limit } from '../../_services/_constants/recordLimitset.constant';
import SwitcherBtn from '../../components/reusableComponents/SwitcherBtn';
import DemoCard from './DemoCard';


function DemoList(props) {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData.userData);
  const smScreen = useMediaQuery('(max-width:768px)')
  //to get the search input value
  const [searchInput, setSearchInput] = useState(null)
  const [layoutComponent, setLayoutComponent] = useState(true)
  const [demoList, setDemoList] = useState([])
  const [renderComponent, setRenderComponent] = useState()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(default_limit);

  useEffect(() => {
    if (userData.id) {
      // dispatch(fetchLoginUser(userData.id));
      if (searchInput) {
        getSearchDemoData(searchInput, userData.id, page)
      }
      else {
        getData(userData.id, page)
      }
    }
  }, [userData.id, rowsPerPage, page])

  const resetDemoDataList = () => {
    setDemoList(null)
    getData(userData.id, page)
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function getData(id, page) {
    const offset = page;
    const limit = rowsPerPage;
    dispatch(setLoader(true))
    getDemo(limit, offset).then(function (response) {
      dispatch(setLoader(false))
      if (response.status === 200) {
        setPage(page);
        setDemoList(response.data)
        copyHandleLayoutRerender(layoutComponent, response.data)
      }
      else {
        dispatch(setSnackBar(true));
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
        dispatch(setSnackBarVariant('error'));
        setTimeout(() => dispatch(setSnackBar(false)), 3000)
      }
    }).catch(function (error) {
      dispatch(setLoader(false))
      dispatch(setSnackBar(true));
      dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
      dispatch(setSnackBarVariant('error'));
      setTimeout(() => dispatch(setSnackBar(false)), 3000)
      return error.response;
    })
  }
  const getSearchDemoData = (value, userId, page) => {
    const offset = page;
    const limit = rowsPerPage;
    dispatch(setLoader(true))
    submitDemo({ 'searchValue': value }, 'SEARCH', limit, offset).then(function (response) {
      dispatch(setLoader(false))
      if (response.status === 200) {
        setPage(page);
        setDemoList(response.data)
        setRenderComponent(null)
        copyHandleLayoutRerender(layoutComponent, response.data)
      }
      else {
        dispatch(setSnackBar(true));
        dispatch(setSnackBarMessage(formSnackbar.errors.imgUploadSize));
        dispatch(setSnackBarVariant('error'));
        setTimeout(() => dispatch(setSnackBar(false)), 3000)
      }
    }).catch(function (error) {
      dispatch(setLoader(false))
      dispatch(setSnackBar(true));
      dispatch(setSnackBarMessage(formSnackbar.errors.imgUploadSize));
      dispatch(setSnackBarVariant('error'));
      setTimeout(() => dispatch(setSnackBar(false)), 3000)
      return error.response;
    })
  }

  useEffect(() => {
    const getData = setTimeout(() => {
      searchInput != null && getSearchDemoData(searchInput, userData.id, 0)
    }, 400)

    return () => clearTimeout(getData)
  }, [searchInput])

  const copyHandleLayoutRerender = (currentComp, demoData) => {
    setLayoutComponent(currentComp)
    currentComp ? setRenderComponent(<DemoDataTable demoListData={demoData}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
      page={page}
      rowsPerPage={rowsPerPage}
      getSearchDemoData={getSearchDemoData}
      getData={getData}
      searchValue={searchInput} />) : setRenderComponent(<DemoCard cardData={demoData}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
      />)

  }

  useEffect(() => {
    if (!layoutComponent) {
      setRenderComponent(<DemoDataTable demoListData={demoList} getDataList={resetDemoDataList}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        getSearchDemoData={getSearchDemoData}
        getData={getData}
        removeData={searchInput}
      />)
      setLayoutComponent(true)
    }
  }, [smScreen])

  const handleSearchInput = (value) => {
    setSearchInput(value)
  }
  const handleLayoutComponent = (currentComp) => {
    setLayoutComponent(currentComp)
    currentComp ? setRenderComponent(<DemoDataTable demoListData={demoList} getDataList={resetDemoDataList}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
      page={page}
      rowsPerPage={rowsPerPage}
      getSearchDemoData={getSearchDemoData}
      getData={getData}
      removeData={searchInput}
    />) : setRenderComponent(<DemoCard cardData={demoList}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      page={page}
      rowsPerPage={rowsPerPage}
    />)
  }


  return (
    <div className={`row ${smScreen ? 'p-2' : ''}`} style={{ padding: '1rem 1rem 3rem 1rem' }}>
      <h2 style={{ marginLeft: '10px' }} >Demo List</h2>
      <div className={`d-flex justify-content-between mt-2`}>
        <div className='flex-grow-1' style={{ maxWidth: '18rem', marginLeft: '10px' }}>
          <SearchBox placeholder="Search" handleSearchInput={handleSearchInput} />
        </div>
        <div className='switchView'>
          <SwitcherBtn layoutComponent={layoutComponent} handleLayoutComponent={handleLayoutComponent} />
        </div>
        {/* <div className='d-flex justify-content-end' style={{ marginRight: '10px' }}>
         <div><SongFilters isFilterApplied={isFilterApplied} applyAdvanceFilters={applyAdvanceFilters}/></div> 
          <div ><SwitcherBtn layoutComponent={layoutComponent} handleLayoutComponent={handleLayoutComponent}/></div> 
        </div>*/}
      </div>
      <div>
        {renderComponent}
      </div>
    </div>
  )
}

export default DemoList