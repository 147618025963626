import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from '@mui/material';

export default function MenuItemList({ demoListData, handleDelete, data, viewRevenueHandler }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };

    return (
        <Box>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MoreVertIcon />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem className='px-4 py-2 d-flex gap-3' onClick={(e) => viewRevenueHandler(e, data)}><RemoveRedEyeIcon sx={{ color: 'rgba(0, 0, 0, 0.6)' }} />View</MenuItem>
                <MenuItem className='px-4 py-2 d-flex gap-3' onClick={(e) => handleDelete(e, data.import_id,demoListData)}><DeleteIcon sx={{ color: 'rgba(0, 0, 0, 0.6)' }} />Delete</MenuItem>
            </Menu>
        </Box>
    );
}