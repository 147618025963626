import React, { useEffect, useState } from 'react';
import TableComponent from './Tablecomponent';
import { Avatar, Box, IconButton, Link, Paper, Tooltip, Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export default function UserDetail({ userDetail }) {

    const rows = userDetail ? [userDetail] : [];



    const columns = [
        {
            id: 'studio_name', label: 'Studio Name', headerName: '', flex: 1, template: (row) => {
                return (
                    <Box className='d-flex gap-2 align-items-center'>
                        <Avatar alt="user" src={row.avatar ? process.env.REACT_APP_STUDIOURL_MEDIA + 'media/' + row.avatar : ''} />{row.studio_name}</Box>
                );
            }
        },
        {
            id: 'address', label: 'Address', headerName: '', flex: 1, template: (row) => {
                return (
                    <Box>{userDetail?.address?.local_address + ", " + userDetail?.address?.city + " " + userDetail?.address?.zipcode}</Box>
                );
            }
        },
        {
            id: 'language', label: 'Language', headerName: '', flex: 1, template: (row) => {
                return (
                    <Box className='d-flex gap-2 align-items-center'>  {userDetail?.languages?.length ? (
                        <>
                            {userDetail.languages.slice(0, 2).map((d, i) => (
                                <Typography key={i} className='rounded-pill px-3 py-1' sx={{ background: "#EBEBEB", fontSize: '13px' }}>{d.language}</Typography>
                            ))}
                            {userDetail.languages.length > 2 && (
                                <Tooltip title={userDetail.languages.slice(2).map(d => d.language).join(', ')} arrow>
                                    <Typography className='rounded-pill px-3 py-1 cursorPointer' sx={{ background: "#EBEBEB", fontSize: '13px' }}>{"+" + (userDetail.languages.length-2)}</Typography>
                                </Tooltip>
                            )}
                        </>
                    ) : null}</Box>
                );
            }
        },
        {
            id: 'service', label: 'Services', headerName: '', flex: 1, template: (row) => {
                return (
                    <Box className='d-flex gap-2 flex-wrap align-items-center'>{userDetail?.services?.length ? (
                        <>
                            {userDetail.services.slice(0, 3).map((d, i) => (
                                <Typography key={i} className='rounded-pill px-3 py-1' sx={{ background: "#EBEBEB", fontSize: '13px' }}>{d.service}</Typography>
                            ))}
                            {userDetail.services.length > 3 && (
                                <Tooltip title={userDetail.services.slice(3).map(d => d.service).join(', ')} arrow>
                                    <Typography className='rounded-pill px-3 py-1 cursorPointer' sx={{ background: "#EBEBEB", fontSize: '13px' }}>{"+" + (userDetail.services.length - 3)}</Typography>
                                </Tooltip>
                            )}
                        </>
                    ) : null}</Box>
                );
            }
        },
        {
            id: 'website', label: 'Website', headerName: '', flex: 1, template: (row) => {
                return (
                    <Box className='d-flex gap-2'><Link href={userDetail?.website} variant="body2" >{userDetail?.website}</Link></Box>
                );
            }
        },
    ];

    return (
        <Paper sx={{ width: '100%', mb: 5, mt: 4 }}>
            <Typography variant='h6' className='p-3'>Studio Details</Typography>
            <TableComponent columns={columns} data={rows} />
        </Paper>
    );
}
