
export const validateISRC = (isrc) => {
    if (isrc.length !== 12) {
        return {
            isValid: true,
            errorMessage: "ISRC must be exactly 12 characters long."
        };
    }
    const isrcPattern = /^[A-Z]{2}[A-Z0-9]{3}\d{2}\d{5}$/;
    if (!isrcPattern.test(isrc)) {
        return {
            isValid: true,
            errorMessage: "ISRC must be in the format (e.g., USRC17607839)."
        };
    }

    return { isValid: false, errorMessage: "" };
}

export const validateUPC = (upc) => {
    if (!/^\d{12}$/.test(upc)) {
        return {
            isValid: true,
            errorMessage: "UPC must be exactly 12 digits long."
        };
    }

    const digits = upc.split('').map(Number);
    const checkDigit = digits.pop(); // Last digit is the check digit

    let sum = 0;
    for (let i = 0; i < digits.length; i++) {
        sum += i % 2 === 0 ? digits[i] * 3 : digits[i];
    }

    const expectedCheckDigit = (10 - (sum % 10)) % 10;

    if (checkDigit !== expectedCheckDigit) {
        return {
            isValid: true,
            errorMessage: "Invalid UPC check digit."
        };
    }

    return { isValid: false, errorMessage: "" };
}