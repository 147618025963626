import {
    FETCH_RELEASE_ALBUM_REQUEST,
    FETCH_RELEASE_ALBUM_SUCCESS,
    FETCH_RELEASE_ALBUM_FAILURE,
  } from './releaseAlbumDataTypes'
  // import {getStudioByID  } from '../../_services/Login/index'
  // import {getUserId} from '../../_helpers/auth/secureToken' //to pass the user id 
// import { getAllAlbumOfUser } from '../../_services/album/getAllAlbumOfUser'
import { getReleaseAlbum } from '../../_services/album/getReleaseAlbum.service';


  export const fetchReleaseAlbum = (pagination, previousData) => {
    return (dispatch) => {
      dispatch(fetchReleaseAlbumRequest());
      let albumData = []
         
      getReleaseAlbum(pagination).then(response=>{
            if(response.data){
              albumData = response.data.results
            let  totalCount = response.data.total_count
              if (previousData == undefined || previousData.length == 0) {
                // return albumData;
                dispatch(fetchReleaseAlbumSuccess({data:albumData,totalCount:totalCount}))
              } else {
          
                return dispatch(fetchReleaseAlbumSuccess({data:[...previousData, ...albumData],totalCount:totalCount}));
              }
            }
      
            }).catch(error => {
                      dispatch(fetchReleaseAlbumFailure(error.message))
                    })
        
      // }
   
    // }
    }
  }
  
  // export const fetchUserDetail = (id) => {
  //   return (dispatch) => {
  //     dispatch(fetchUserDetailRequest())
  //     getStudioByID(id)
  //     .then(response => {
  //         //const userData = response.data.data
  //         const userData = response.data;
  //         dispatch(fetchUserDetailSuccess(userData))
  //       })
  //       .catch(error => {
  //         dispatch(fetchUserDetailFailure(error.message))
  //       })
  //   }
  // }
  
  export const fetchReleaseAlbumRequest = () => {
    return {
      type: FETCH_RELEASE_ALBUM_REQUEST
    }
  }
  
  export const fetchReleaseAlbumSuccess = releaseAlbumData => {
    return {
      type: FETCH_RELEASE_ALBUM_SUCCESS,
      payload: releaseAlbumData
    }
  }
  
  export const fetchReleaseAlbumFailure = error => {
    return {
      type: FETCH_RELEASE_ALBUM_FAILURE,
      payload: error
    }
  }
  