import axios from "axios";
import { updateCategoryApi } from '../_constants/api.constant'
import {getSelfToken } from '../../_helpers/auth/secureToken'
export async function updateCategory(id,postData, type) {
    const postDetail = {user:postData.user} //updated code to send only category selected by the user
    const response = await axios.put(
        updateCategoryApi.ADD+'?id='+postData.category,
        postDetail,
        {
            headers: {
                Authorization: 'Token ' + getSelfToken(),
            },
        }
    ).then(function (response) {
        return response;
    }).catch(function (error) {
        // handle error
        console.log(error);
        console.log(error.response);
        return error.response;
    });
    return response;
}