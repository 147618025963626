import React, { useState } from 'react'
import { Box, Card, CardContent, CardMedia, Grid, Stack } from '@mui/material'
import artist from '../../img/thumbnail.png'
import { getDateAsPerFormat } from '../../_helpers/reusablefunctions/dateSetter' 
import { useHistory } from 'react-router-dom'
import { getSongStatusBgColor } from '../../_helpers/reusablefunctions/getColor';


const cardStyle={
    display: 'flex',
    minWidth: '12rem',
    minHeight:'18rem',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1) !important',
    borderRadius:'6px',
  }

const CardLayout = ({cardData,moduleName}) => {
  const history = useHistory()
  const routeChangeHandler = () =>{
    history.push(`/submitsong/${cardData.song_id}`)
  }
  
    
  return (
    <div>
    <Grid container   direction={'row'} alignItems="center"  justify="center" className='d-flex align-items-center' style={{cursor:'pointer'}} onClick={routeChangeHandler}>
       <Grid item xl={6} md={6} sm={12} xs={12}  style={{maxWidth:'18rem',paddingRight:'30px',paddingTop:'20px',}} >
                <Card variant="outlined" sx={cardStyle}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width:'100%',}} >
                            <Stack direction='row'  spacing={1} alignItems='center'>
                              <div >
                                <div className="item p-0" >
                                    <CardMedia
                                        component="img"
                                        sx={{ width:'16rem', height:'9rem'}}
                                        image={cardData.cover_image?process.env.REACT_APP_SONGAPIURL_MEDIA+'media/'+cardData.cover_image:artist}
                                    />
                                    <div style={{position:'absolute',bottom:'10px',left:'5%'}}> 
                                      {cardData.song_status!=''?<div style={{backgroundColor:getSongStatusBgColor(cardData.song_status)}} className='status text-light'>{cardData.song_status}</div>:''}
                                    </div>   
                                </div>
                              </div>
                            </Stack>
                       <CardContent className='p-2' sx={{ flex: '1 0 auto'}} >
                                <div>
                                  <div className='text-truncate'><strong title={cardData.song_name}>{cardData.song_name}</strong></div>
                                  <div className='text-truncate'><span title={cardData.album_name}>{cardData.album}</span></div>
                                  <div><span>{cardData.genre}</span></div>
                                  <div><span>{cardData.content_types}</span></div>
                                  <div className='text-secondary'><strong>Release Date: </strong><span>{cardData.release_date?getDateAsPerFormat(cardData.release_date,"DD Mon, YYYY"):''}</span></div> 
                                </div>
                        </CardContent>
                    </Box>  
                </Card>
            </Grid>  
    </Grid>
    </div>
  )
}

export default CardLayout