import * as React from 'react';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import playlist from '../../img/Frame 48096170.png'
import { useHistory } from 'react-router-dom';
import { getSongStatusBgColor } from '../../_helpers/reusablefunctions/getColor';
import { getDateAsPerFormat } from '../../components/reusableComponents/helperComponent';
import { Card } from '@mui/material';
export default function AlbumCard({ albumInfo, subGenreList, genre, assoArtistList }) {
  let history = useHistory();

  function handleClick(id) {
    history.push(`/albumdetail/${albumInfo.album_id}`);
  }
  const limitPlaylistName = (name, limit) => {
    if (name.length > limit) {
      return name.substring(0, limit) + '...';
    }
    return name;
  };

  function getArtistName(artist) {
    return artist.map(a => a.artist_name).join(',')
  }

  return (
    <Card className="box-album-card shadow-table" onClick={handleClick} role="button" tabIndex="0">
      <div style={{ position: 'relative' }}>
        <div>
          <div className='leftStatusCard' style={{ position: 'absolute', textAlign: "center", paddingRight: '15px', paddingTop: '3px', paddingBottom: '3px',  paddingLeft: '15px', left:"12px", bottom: '14px', background: getSongStatusBgColor(albumInfo.song_status), borderRadius: "15px", color: '#fff', fontSize: '12px' }}>
            {albumInfo.song_status == "draft" ? "Draft" : albumInfo.song_status}
          </div>
        </div>
        <CardMedia
          component="img"
          style={{
            minHeight: '320px',
          }}
          className="card-mediaAlbum"
          image={albumInfo.cover_image != null ? process.env.REACT_APP_SONGAPIURL_MEDIA + 'media/' + albumInfo.cover_image : playlist}
        />
      </div>
      <CardContent className='p-2 d-grid'>
        <span className='albumTitle'>{limitPlaylistName(albumInfo.album_name, 26)}</span>
        <span className=' fs14 fw-normal sub-text-color' >{getArtistName(albumInfo.primary_artists)}</span>

        {albumInfo.featuring_artists.length == 0 ? <span className=' fs14 fw-normal sub-text-color' >{albumInfo?.genre}</span> :
          <span className=' fs14 fw-normal sub-text-color' >{getArtistName(albumInfo.featuring_artists)}</span>}

        <div className=" fs14 fw-normal sub-text-color">Release Date: <span>{albumInfo.release_date == (null || '') ? '--' : getDateAsPerFormat(albumInfo.release_date, "DD Mon, YYYY")}</span></div>
      </CardContent >
    </Card >
  );
}