import React from 'react'
import {ToggleButton } from '@mui/material'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import WindowIcon from '@mui/icons-material/Window';
const SwitcherBtn = ({layoutComponent,handleLayoutComponent}) => {  
  const Icon = layoutComponent ?  <WindowIcon className='bg-white' fontSize="small" sx={{color:'#000000'}}/> : <FormatListBulletedIcon className='bg-white' fontSize="small" sx={{color:'#000000'}}/> 
  return (
    <div>
        <ToggleButton title={layoutComponent ? 'Card View':'Grid View'} onClick={()=>{handleLayoutComponent(!layoutComponent)}} value={layoutComponent} variant="outlined" className="switcherButton bg-white" size="small" aria-label='switchView' sx={{minWidth:'2.625rem',height:'40px'}}>
            {Icon}
        </ToggleButton>
    </div>
  )
}

export default SwitcherBtn