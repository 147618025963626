import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TableComponent from './Tablecomponent';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Breadcrumbs, Button, Divider, Grid, Paper, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import labels from '../../img/labels.svg'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import rupee from '../../img/rupee.svg'
import rupee1 from '../../img/rupee1.svg'
import currentbalance from '../../img/currentbalance.svg'

export default function PayoutListDetail({ hideTotal }) {
    const dispatch = useDispatch();
    const location = useLocation();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    // const rows = location?.state?.state?.data;
    const color = {
        color: 'rgba(76, 78, 100, 0.87)'
    }

    const viewDetails = () => {

    }

    const rows = [
        {
            id: 1, customer: 'GNStudio', payment_type: 'Bank Transfer', requested_date: '02 Jan, 2024 09:12 am', payment_date: '10 Dec, 2024 09:12 am',
            requested_amt: '400', status: 'Requested'
        }
    ];

    const columns = [
        {
            id: 'customer', label: 'CUSTOMER', headerName: '', template: (row) => {
                return (
                    <Box className='d-flex gap-2 align-items-center cursorPointer' onclick={() => viewDetails()} ><img src={labels} alt='label-icon' />{row.customer}</Box>
                )
            }
        },
        { label: 'PAYMENT TYPE', field: "payment_type", id: 'payment_type' },
        { label: 'REQUESTED DATE & TIME', field: "requested_date", id: 'requested_date' },
        { label: 'PAYMENT DATE & TIME', field: "payment_date", id: 'payment_date' },
        {
            id: 'requested_amt', label: 'REQUESTED AMOUNT', headerName: '', flex: 1, template: (row) => {
                return (
                    <Typography variant='h6' ><CurrencyRupeeIcon fontSize={'small'} />{row.requested_amt}</Typography >
                )
            }
        },
        {
            id: 'status', label: 'STATUS', headerName: '', flex: 1, template: (row) => {
                return (
                    <Button className='text-capitalize rounded-pill px-3' sx={{ backgroundColor: 'rgba(255, 234, 182, 1) !Important', fontSize: 12, color: 'rgba(58, 58, 58, 1)' }}>{row.status}</Button>
                )
            }
        },
        {
            id: 'action', label: 'ACTION', headerName: '', flex: 1, template: (row) => {
                return (
                    <Box className='text-decoration-none viewDetailgradient cursorPointer' ><MoreVertIcon /></Box>
                )
            }
        }
    ];

    return (
        <Box className="py-3 px-4">
            <Typography variant='h5' sx={{ fontSize: '20px', fontWeight: '500' }}> GNStudio Account Detail </Typography>
            <Grid container spacing={2} className='mt-0'>
                <Grid item xs={12} md={6}>
                    <Box className="bg-white shadow-sm rounded" minHeight="230px">
                        <Box className='px-3 py-3'><Typography style={color} variant='h6' >{'Account'}</Typography ></Box>
                        <Divider />
                        <Box className="d-flex">
                            <Box className='align-items-center justify-content-evenly w-50 d-flex flex-column'>
                                <Box className=" d-flex align-items-center justify-content-center gap-2">
                                    <img src={currentbalance} alt='currentbalance' />
                                    <Box>
                                        <Typography style={color} variant='h4' ><CurrencyRupeeIcon fontSize={'large'} />{'00'}</Typography >
                                        <Typography style={{ ...color, fontSize: 14 }} variant='span' >{'Your Current Balance'}</Typography >
                                    </Box>
                                </Box>
                            </Box>
                            <Divider sx={{ height: '165px !important' }} orientation="vertical" flexItem />
                            <Box className="w-50 d-flex flex-column justify-content-center">
                                <Box className="d-flex align-items-center gap-2 pb-3 ps-3">
                                    <img src={rupee} alt='rupee' />
                                    <Box>
                                        <Typography style={color} variant='h6' ><CurrencyRupeeIcon fontSize={'small'} />{'00'}</Typography >
                                        <Typography style={{ ...color, fontSize: 14 }} variant='span' >{'Available Balance'}</Typography >
                                    </Box>
                                </Box>
                                <Divider />
                                <Box className="d-flex align-items-center gap-2 pt-2 ps-3 ">
                                    <img src={rupee1} alt='rupee' />
                                    <Box>
                                        <Typography style={color} variant='h6' ><CurrencyRupeeIcon fontSize={'small'} />{'00'}</Typography >
                                        <Typography style={{ ...color, fontSize: 14 }} variant='span' >{'Pending'}</Typography >
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>

                </Grid>
            </Grid>

            {/* <Typography variant='h5' className='' >Payout</Typography> */}
            {/* <BreadcrumbsList /> */}
            <Paper sx={{ width: '100%', mb: 2, mt: 3 }}>
                <TableComponent removeStyle='removeStyle' columns={columns} data={rows} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
            </Paper>
        </Box>
    );
}
