import React from 'react'

export const verificationStatusColor =  (verificationStatus) =>{
    // if(userData && userData.verification_status && userData.verification_status.verificationStatus)
       switch(verificationStatus)
       {
        case 'Verified':
            {
            return 'text-success'
            }
        case 'Unverified':
            {
                return 'text-warning'
            }
        case 'Rejected':
              {
                  return 'text-danger'
              }
        case 'Pending':
          {
              return 'text-danger'
          }
        default:
            {return 'text-danger'}
       }
      

}
