import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import artist from '../../img/thumbnail.png';
import { getDateAsPerFormat } from '../../_helpers/reusablefunctions/dateSetter' 
import { useHistory, useParams } from 'react-router-dom';
import { getSongStatusBgColor } from '../../_helpers/reusablefunctions/getColor';
import {
  createTheme,
  ThemeProvider,
  Grid,
  IconButton,
  Slider,
  Typography,
} from "@mui/material";
import AudioPlayer from './customAudioPlayer';
import { verificationStatusColor } from './verificationStatusColor';
export default function ResponsiveCardList({cardData,moduleName}) {
    const history = useHistory()
    const verifyHandler = () =>{
      history.push(`/artistdetail/${cardData.userHandle}`)
    }
    // console.log('23',cardData)
    const showInListView = (module)=>{
        switch(module)
        {
          case 'songList':
            {
              return (
                <div className='my-3 responsive-list' onClick={()=>history.push(`/submitsong/${cardData.song_id}`)} style={{cursor:'pointer'}}>
                <Card sx={{ maxWidth: '500px', width:'100%'}}>        
                  <div className='d-flex w-100'>
                      <div style={{padding:'0.5rem'}}>        
                      <CardMedia
                        component="img"
                        sx={{ width:'60px', height:'60px',borderRadius:'15px'}}
                        image={cardData.cover_image?`${process.env.REACT_APP_SONGAPIURL_MEDIA}media/${cardData.cover_image}`:artist}
                        />        
                      </div>
                      <div style={{padding:'0.5rem',flexGrow:1}}>
                        <div className='d-flex justify-content-between'>
                            <strong className='cardheadingFont overflow-text'>{cardData.song_name}</strong>                
                            <div style={{backgroundColor:getSongStatusBgColor(cardData.song_status), minWidth:'72px'}} className='status text-light'>{cardData.song_status}</div>
                        </div>
                        <div className='cardheadingFont text-secondary cardLine'>{cardData.album}</div>
                        <div className='cardfont' style={{marginTop:"5px"}}>{cardData.content_types}</div>
                        <div className={'d-flex justify-content-between'} >
                            <div className='cardfont'>{cardData.genre}</div>
                            <div className='cardfont '>
                              {cardData.release_date?getDateAsPerFormat(cardData.release_date,"DD Mon, YYYY"):''}
                              </div>

                        </div>
                      </div>
                  </div>
                  </Card>
                </div>
              )
            }
            case 'artistList':
            {
              return (
                <div className='my-3 responsive-list' /*onClick={()=>history.push(`/submitsong/${cardData.song_id}`)}*/ style={{cursor:'pointer'}}>
                <Card sx={{ maxWidth: '500px', width:'100%'}}>        
                  <div className='d-flex w-100'>
                      <div style={{padding:'0.5rem'}}>        
                      <CardMedia
                        component="img"
                        sx={{ width:'60px', height:'60px',borderRadius:'15px'}}
                        // image={cardData?`${process.env.REACT_APP_SONGAPIURL}/media/${cardData.cover_image}`:artist}
                        image={cardData.avatar?process.env.REACT_APP_SONGAPIURL_MEDIA+'media/'+cardData.avatar:artist}

                        />        
                      </div>
                      <div style={{padding:'0.5rem',flexGrow:1}}>
                        <div className='d-flex justify-content-between'>
                        <div className='text-truncate artistCardName pt-2' onClick={()=>verifyHandler(cardData)}><span title={cardData.name}>{cardData.name}</span></div>
               
                            {/* <div style={{backgroundColor:getSongStatusBgColor(cardData.song_status), minWidth:'72px'}} className='status text-light'>{cardData.song_status}</div> */}
                            <div className={`${verificationStatusColor(cardData && cardData.verification_status && cardData.verification_status.verificationStatus&&cardData.verification_status.verificationStatus)} verifyStatus `} /*style={{border:'solid 2px', borderRadius:'20px', fontSize:'1rem', minWidth:'80px'}}*/>
                                       {cardData.verification_status ?cardData.verification_status.verificationStatus:'Unverified'}
                                      </div>
                        </div>
                        <div className='cardheadingFont text-secondary cardLine'>
                          <span
                                  title={cardData.genres.map((genre) => genre.genre).join(", ")}
                                  style={{ fontWeight: 400 }}
                                >
                                  {cardData.genres.map((genre, index) => (
                                    <React.Fragment key={genre.id}>
                                      <span>{genre.genre}</span>
                                      {index !== cardData.genres.length - 1 && <span>, </span>}
                                    </React.Fragment>
                                  ))}
                                </span></div>
                        <div className='cardfont'>{cardData.city}</div>
                        <div className={'d-flex justify-content-between'} >
                            <div className='cardfont'>{getDateAsPerFormat(cardData.date_joined,"DD Mon, YYYY")}</div>
                            <div className='cardfont '>{cardData.expertiseLevel && cardData.expertiseLevel.expertiseLevel}</div>
                        </div>
                      </div>
                  </div>
                  </Card>
                </div>
              )
            }
          default:{
            return;
          }
        }
    }
    return (
      showInListView(moduleName)
    )
}