import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import TableComponent from './Tablecomponent';
import { storeDynamic } from './TabConstData';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { formatIndianNumber, getMonthValue } from './formateRevenueData';
import { Paper, Tooltip, Typography } from '@mui/material';
import Search from './Search';
import { fetchRoyaltyDetail } from '../../redux/royalty/royaltyDataActions';

export default function Stores({ hideTotal }) {
    const history = useHistory();
    const { studioId } = useParams()
    const dispatch = useDispatch();
    const singlestoreData = useSelector((state) => state.royaltyDataReducer.singlestoreData);
    const userData = useSelector((state) => state.userData.userData);
    const storeData = useSelector((state) => state.royaltyDataReducer.storeData);
    const switchValue = useSelector((state) => state.royaltyDataReducer.switchRevenueValue);
    const location = useLocation();
    const isDetailsPage = location.pathname === '/details';
    const tabDynamic = location?.state?.state
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const total_count = storeData?.total_count
    const publisherId = userData.id;
    const partener_detail = { partner_type: 'studio', partner_id: studioId, share_type: 'contract_partner' }
    const partener_admin_detail = { partner_type: 'admin', partner_id: publisherId, share_type: 'publisher' }

    const redirectDetails = (data) => {
        if (studioId == undefined || studioId == null || studioId == "") {
            let pagecellUrl = switchValue == 1 ? 'adminallsinglestoreData' : 'singlestoreData'
            history.push('/details', { state: { data: storeDynamic, value: [data], pageCall: pagecellUrl, name: data?.platform, id: data?.platform_id } });
        } else {
            history.push(`/details/${studioId}`, { state: { data: storeDynamic, value: [data], pageCall: 'adminsinglestoreData', name: data?.platform, id: data?.platform_id } });
        }
    }

    function allRevenue() {
        if (studioId == undefined || studioId == null || studioId == "") {
            if (isDetailsPage) {
                const monthName = tabDynamic?.name;
                let songId = tabDynamic?.id ? tabDynamic?.id : getMonthValue(monthName)
                let parterdetail = switchValue == 1 ? { ...partener_admin_detail, publisher_type: 'admin' } : null
                dispatch(fetchRoyaltyDetail(tabDynamic.pageCall, { offset: page, limit: rowsPerPage, ...parterdetail }, songId + '/platform', `SINGLE_STORE`))
            } else {
                if (publisherId && switchValue == 1) {
                    dispatch(fetchRoyaltyDetail("adminstoreData", { page, limit: rowsPerPage, ...partener_admin_detail }, 'admin/', `STORE`));
                } else {
                    dispatch(fetchRoyaltyDetail("storeData", { offset: page, limit: rowsPerPage }, null, `STORE`))
                }
            }
        }
    }

    useEffect(function () {
        allRevenue()
    }, [dispatch, page, rowsPerPage, publisherId, switchValue]);

    useEffect(() => {
        if (studioId && publisherId) {
            const details = switchValue == 2 ? partener_detail : partener_admin_detail;
            if (location.pathname == `/details/${studioId}`) {
                const monthName = tabDynamic?.name;
                let songId = tabDynamic?.id ? tabDynamic?.id : getMonthValue(monthName)
                dispatch(fetchRoyaltyDetail(tabDynamic.pageCall, { offset: page, limit: rowsPerPage, publisher_type: 'studio', publisher_id: studioId, ...details }, songId + '/platform', `SINGLE_STORE`))
            }
            else {
                dispatch(fetchRoyaltyDetail("adminstoreData", { offset: page, limit: rowsPerPage, ...details }, `studio/${studioId}/`, `STORE`))
            }
        }
    }, [publisherId, switchValue])

    const rows = (isDetailsPage || location.pathname == `/details/${studioId}` ? singlestoreData?.counts_per_platform : storeData.total_data?.length ? storeData.total_data : []) || [];

    const columns = [
        {
            id: 'platform', label: 'Store', headerName: '', flex: 1, template: (row) => {
                return (
                    <Box className="d-flex align-items-center gap-2"><Box sx={{ height: 35, width: 35 }}><img className='w-100 h-100 rounded-pill ' style={{ objectFit: 'cover' }} src={process.env.REACT_APP_BASEURL + 'song' + row.platform_logo} alt='platform-image' /></Box>{row.platform}</Box>
                );
            }
        },
        // {
        //     id: 'primary_artist', label: 'Artist', headerName: '', flex: 1, template: (row) => {
        //         return (
        //             <Box className='d-flex gap-2 flex-wrap align-items-center'>
        //                 {row.primary_artist.length ? (
        //                     <>
        //                         {row.primary_artist.slice(0, 2).map((d, i) => (
        //                             <Typography key={i} className='rounded-pill px-3 py-1' sx={{ background: "#EBEBEB", fontSize: '12px' }}>{d}</Typography>
        //                         ))}
        //                         {row.primary_artist.length > 2 && (
        //                             <Tooltip title={row.primary_artist.slice(2).map(d => d).join(', ')} arrow>
        //                                 <Typography className='rounded-pill px-3 py-1' sx={{ background: "#EBEBEB", fontSize: '12px' }}> {"+" + row.primary_artist.length}</Typography>
        //                             </Tooltip>
        //                         )}
        //                     </>
        //                 ) : null}</Box>
        //         );
        //     }
        // },
        { id: 'total_stream_count', field: "total_stream_count", label: 'Streams' },
        { id: 'release_download_count', field: "release_download_count", label: 'Release Downloads' },
        { id: 'track_download_count', field: "track_download_count", label: 'Track Downloads' },
        { id: 'video_download_count', field: "video_download_count", label: 'Video Downloads' },
        {
            id: 'total_stream_earning', label: 'Earnings', headerName: '', flex: 1, template: (row) => {
                const totalDownloads = row.total_stream_earning + row.total_release_download_earning + row.total_track_download_earning + row.total_video_download_earning;
                return (
                    <Box className="d-flex align-items-center"><CurrencyRupeeIcon sx={{ fontSize: '15px' }} />{formatIndianNumber(totalDownloads)}</Box>
                );
            }
        },
        {
            id: 'action', label: 'Action', headerName: '', flex: 1, template: (row) => {
                return (
                    <Box className='text-decoration-none viewDetailgradient cursorPointer' onClick={() => redirectDetails(row)} >View Details</Box>
                )
            }
        },
    ];

    const headFooter = [
        { id: 'name', numeric: false, label: 'Totals' },
        // { id: 'artist', numeric: false, label: '-' },
        { id: 'streams', numeric: false, label: storeData.length != 0 ? formatIndianNumber(storeData.overall_total_streams) : 0 },
        { id: 'releasedownloads', numeric: true, label: storeData.length != 0 ? formatIndianNumber(storeData.overall_release_downloads) : 0 },
        { id: 'trackdownloads', numeric: true, label: storeData.length != 0 ? formatIndianNumber(storeData.overall_track_downloads) : 0 },
        { id: 'videodownloads', numeric: true, label: storeData.length != 0 ? formatIndianNumber(storeData.overall_video_downloads) : 0 },
        { id: 'earnings', numeric: false, label: <Box className="d-flex align-items-center"><CurrencyRupeeIcon sx={{ fontSize: '15px' }} />{formatIndianNumber(storeData.overall_release_downloads_earnings + storeData.overall_track_downloads_earnings + storeData.overall_streams_earning + storeData.overall_video_downloads_earnings)}</Box> },
        { id: 'action', numeric: false, label: '' }
    ];


    return (
        <Paper sx={{ width: '100%', mb: 2, mt: 1 }}>
            <Search headValue={'Stores'} />
            <TableComponent columns={columns} data={rows} headFooter={headFooter} hideTotal={hideTotal} total_count={total_count} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
        </Paper>
    );
}
