import { SportsRugbySharp } from '@mui/icons-material';
import React from 'react';

function Footer(props) {
  return (
    // <footer className=" footerBg  text-center text-lg-start">
    //     <div className="text-center p-3" >
    //         © 2021 GrooveNexus spotlight
    //     </div>
    // </footer>
    //   <footer className=" text-center text-lg-start fixed-bottom">
    //   <div className="text-center" >
    //     © 2020 Copyright:
    //   </div>
    // </footer>
    <footer className="main-footer text-center fixed-bottom  border footer" >
      <div className="pt-3" dangerouslySetInnerHTML={{ __html: process.env.REACT_APP_COPYRIGHT }} />
    </footer>
  );
}

export default Footer;