
import React, { useEffect, useState } from 'react';
import { Box, Button, Chip, Menu, MenuItem, Paper, Stack } from '@mui/material';
import { useHistory } from 'react-router-dom';
import ApiService from '../../_services/ApiService';
import { getSongStatusBgColor } from '../../_helpers/reusablefunctions/getColor';
import { useDispatch } from 'react-redux';
import { setLoader } from '../../redux';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/system';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import TableComponent from '../importRevenue/Tablecomponent';

const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: '10px',
  color: '#545454',
  background: '#fff',
  border: '0px',
  padding: '6px 20px 6px 20px',
  fontSize: '14px',
  '&:hover': {
    backgroundColor: '#ED1C24',
    color: '#fff',
  },
  '&.MuiChip-clickable:focus, &.MuiChip-clickable:hover': {
    backgroundColor: '#ED1C24',
    color: '#fff',
  },
  '&.MuiChip-clickable': {
    '&.Mui-active': {
      backgroundColor: '#ED1C24',
      color: '#fff',
    },
  },
}));
export default function SongpitchTable() {
  const dispatch = useDispatch()
  const [rows, setRows] = useState([]);
  const [menuRowId, setMenuRowId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalCount, setTotalCount] = useState(0)
  const [chipValue, setChipValue] = useState('')
  const [chipLabel, setChipLabel] = useState('')
  const history = useHistory();
  const [activeChip, setActiveChip] = useState('0-0');

  const filterTags = [
    { label: 'all', value: ["All"] },
    { label: 'pitching_type', value: ['New', 'RePitch'] },
    { label: 'pitching_status', value: ['Inprogress', 'Approved'] },
  ]

  useEffect(() => {
    dispatch(setLoader(true))
    if (activeChip != '0-0') {
      handleChipClick(activeChip, chipLabel, chipValue)
    } else {
      ApiService.get('songpitchList', { offset: page, limit: rowsPerPage }).then(res => {
        if (res.status == 200) {
          setRows(res.data.results)
          setTotalCount(res.data.total_count)
        }
        dispatch(setLoader(false))
      }).catch(error => {
        console.log("error")
        dispatch(setLoader(false))
      })
    }
  }, [page, rowsPerPage])

  const handleMenuClick = (event, id) => {
    event.stopPropagation()
    event.preventDefault()
    setAnchorEl(event.currentTarget);
    setMenuRowId(id);
  };

  function viewDetailHandler(e, data) {
    history.push(`/songpitchView/${data.id}`, { state: { songData: data } })
  }

  const handleClose = () => {
    setAnchorEl(null);
    setMenuRowId(null);
  };

  const columns = [
    { id: 'id', field: "id", numeric: true, label: 'Pitch Id' },
    { id: 'song_name', field: "Song", numeric: true, label: 'Song' },
    { id: 'pitching_type', field: "pitching_type", numeric: true, label: 'Pitch Type' },
    { id: 'album_name', field: "album_name", numeric: true, label: 'Album' },
    { id: 'singer', field: "singer", numeric: true, label: 'Artist Name' },
    { id: 'pitching_date', field: "pitching_date", numeric: true, label: 'Pitch Date' },
    {
      id: 'pitching_status', label: 'Pitch status', headerName: '', flex: 1, template: (row) => {
        return (
          <Stack direction="row" spacing={1}>
            <Chip className='p-2 text-white' sx={{ background: getSongStatusBgColor(row?.pitching_status) }} label={row.pitching_status} />
          </Stack>
        )
      }
    },
    {
      id: 'action', label: 'Action', headerName: '', flex: 1, template: (row) => {
        return (
          <Box className='text-decoration-none viewDetailgradient cursorPointer' ><Button
            id={`basic-button-${row.id}`}
            aria-controls={menuRowId === row.id ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={menuRowId === row.id ? 'true' : undefined}
            onClick={(event) => handleMenuClick(event, row.id)}
          >
            <MoreVertIcon />
          </Button>
            <Menu
              onClose={handleClose}
              id={`basic-menu-${row.id}`}
              anchorEl={anchorEl}
              open={menuRowId === row.id}
              MenuListProps={{
                'aria-labelledby': `basic-button-${row.id}`,
              }}
            >
              <MenuItem className='px-4 py-2 d-flex gap-3' onClick={(e) => viewDetailHandler(e, row)} ><RemoveRedEyeIcon />View Detail</MenuItem>
            </Menu>
          </Box>
        )
      }

    },
  ]

  const handleChipClick = (i, label, value) => {
    setActiveChip(i);
    setChipValue(value)
    setChipLabel(label)
    dispatch(setLoader(true))
    const labelValuePair = { [label]: value };
    ApiService.get('songpitchSearch', { offset: page, limit: rowsPerPage, ...labelValuePair }).then(res => {
      if (res.status == 200) {
        setRows(res.data.results)
        setTotalCount(res.data.total_count)
      }
      dispatch(setLoader(false))
    }).catch((error) => {
      console.log("error")
      dispatch(setLoader(false))
    })
  };

  return (
    <div className="container-padding">
      <Box>
        <h2>Pitching</h2>
      </Box>
      <Box className="d-flex align-items-center justify-content-between mb-3">
        <Stack direction="row" spacing={1}>
          {filterTags.map((d, i) =>
            d.value.map((v, index) => (
              <StyledChip
                key={`${i}-${index}`}
                label={v}
                component="a"
                variant="outlined"
                clickable
                onClick={() => handleChipClick(`${i}-${index}`, d.label, v)}
                className={activeChip === `${i}-${index}` ? 'Mui-active' : ''}
              />
            ))
          )}
        </Stack>
      </Box>
      <Paper className="shadow-table" sx={{ width: '100%', mb: '3rem' }}>
        <TableComponent columns={columns} data={rows} page={page} setPage={setPage} removeStyle={'removeStyle'} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} totalCount={totalCount} />
      </Paper>
    </div>
  );
}