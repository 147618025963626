
import ApiService from '../../_services/ApiService';
import {
  SET_TAB_VALUE,
  SET_REVENUE_VALUE
} from './royaltyDataTypes'
export const fetchRoyaltyDetail = (url, pagination, id, type) => {
  return (dispatch) => {
    import('./royaltyDataTypes').then(({ actionTypes }) => {
      dispatch(fetchRoyaltyDetailRequest(type, actionTypes));
      ApiService.get(url, pagination, id).then(res => {
        let response = res.data;
        if (response) {
          dispatch(fetchRoyaltyDetailSuccess(type, response, actionTypes));
        }
      }).catch(function (error) {
        console.log(error);
        console.log(error.response);
        dispatch(fetchRoyaltyDetailFailure(type, error.message, actionTypes));
      });
    });
  };
};

export const fetchRoyaltyDetailRequest = (type, actionTypes) => {
  return {
    type: actionTypes[`FETCH_${type}_DATA_REQUEST`]
  };
};

export const fetchRoyaltyDetailSuccess = (type, userData, actionTypes) => {
  return {
    type: actionTypes[`FETCH_${type}_DATA_SUCCESS`],
    payload: userData
  };
};

export const fetchRoyaltyDetailFailure = (type, error, actionTypes) => {
  return {
    type: actionTypes[`FETCH_${type}_DATA_FAILURE`],
    payload: error
  };
};

export const setTabValue = (data) => {
  return {
    type: SET_TAB_VALUE,
    payload: data
  }
}

export const setRevnueValue = (data) => {
  return {
    type: SET_REVENUE_VALUE,
    payload: data
  }
}