import React  from 'react'
import { ToggleButton } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList';

const SwitchFilter = () => {  
  const Icon = <FilterListIcon className='bg-white' fontSize="small" sx={{color:'#000000'}}/> 
  return (
    <div>
        <ToggleButton  variant="outlined" className="switcherButton bg-white" size="small" aria-label='switchView'  sx={{minWidth:'2.625rem',height:'40px'}}>
            {Icon}
        </ToggleButton>
    </div>
  )
}

export default SwitchFilter