
export const handleArtistData = (name, dataList, setDataList, dataKey, isNewArtist, artistData, artistCode, data, setData) => {
    const updatedList = isNewArtist ? [...dataList, artistData[0]] : artistData;
    const updatedData = isNewArtist
        ? { ...data, [dataKey]: `${data[dataKey]},${artistData.map(artist => artist.artist_code)}` }
        : { ...data, [dataKey]: artistData.map(artist => artist.artist_code).join(',') };

    switch (name) {
        case artistCode.primary_artist:
            setDataList(updatedList);
            setData(updatedData);
            break;
        case artistCode.feature_artist:
            setDataList(updatedList);
            setData(updatedData);
            break;
        case artistCode.lyricist:
            setDataList(updatedList);
            setData(updatedData);
            break;
        case artistCode.producer:
            setDataList(updatedList);
            setData(updatedData);
            break;
        case artistCode.composer:
            setDataList(updatedList);
            setData(updatedData);
            break;
        case artistCode.actor:
            setDataList(updatedList);
            setData(updatedData);
            break;
        case artistCode.remixers:
            setDataList(updatedList);
            setData(updatedData);
            break;
        default:
            break;
    }
};

export const artistCode = {
    primary_artist:'primarycd',
    feature_artist:'featurecd',
    lyricist:'lyricistcd',
    composer:'composercd',
    producer:'producercd',
    actor:'actorcd',
    remixer_artist:'remixercd',
}

export function getDateAsPerFormat(cDate, dateFormat) {
    const localDate = new Date(cDate); // Convert date to local timezone
    const dayOfTheMonth = localDate.getDate();
    const month = localDate.getMonth();
    const year = localDate.getFullYear();
  
    switch (dateFormat) {
      case 'dd/MM/yyyy':
        return `${dayOfTheMonth}/${month + 1}/${year}`;
      case 'MM/dd/yyyy':
        return `${month + 1}/${dayOfTheMonth}/${year}`;
      case 'DD Mon, YYYY':
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const monthName = months[month];
        return `${dayOfTheMonth} ${monthName}, ${year}`;
      default:
        return `${dayOfTheMonth}/${month + 1}/${year}`;
    }
  }