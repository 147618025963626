import React,{useEffect, useState} from 'react'
import TextField from '@mui/material/TextField';
import {useSelector, useDispatch} from 'react-redux';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import profileImge from '../../img/profileImage.png'
import InstagramImg from '../../img/socialHandles/instagram.png'
import YoutubeImg from '../../img/socialHandles/youtube.png'
import FacebookImg from '../../img/socialHandles/facebook2.png'
import LinkedinImg from '../../img/socialHandles/linkedin.png'
import TwitterImg from '../../img/socialHandles/twitter.png'
import SpotifyImg from '../../img/socialHandles/spotify.png'
import website from '../../img/socialHandles/website.png'
import Imbdlogo from '../../img/socialHandles/imbd-logo.png'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useMediaQuery from '@mui/material/useMediaQuery';
import VerifiedIcon from '@mui/icons-material/Verified';
import { useHistory} from 'react-router-dom';
import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { fetchUserDetail, setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { getArtistDataByUserHandle } from '../../_services/artist/getArtistDataByusrHandle';
import { formSnackbar } from '../../_services/_constants/snackbar.constant';
import SnackbarContainer from '../Snackbar';
import { verifyServiceHandler } from '../../_services/profile/verifyArtist/putVerifyArtist';
import { getCreaterData } from '../../_services/artist/ArtistService';
import { verificationStatusColor } from '../../components/reusableComponents/verificationStatusColor';


function ArtistVerification() {
    const userData = useSelector((state)=>state.userData.userData);
    const dispatch = useDispatch()
    const smallViewport = useMediaQuery('(max-width:768px)');
    const [open, setOpen] = useState(false)
    const [remark, setRemark] = useState('')
    const [remarkError, setRemarkError] = useState('')
    const [errorState, setErrorState] = useState(false)
    const history = useHistory();
    let params=useParams();
    const userHandle=params.userHandle
    const [data,setData]=useState([])
    const [isVerified, setIsVerified] = useState('');
    const [artistId,setArtistId]=useState()
    const [verificationStatus, setStatus] = useState('');
    const [reviewerDetails, setReviewerDetails] = useState({reviewerID:'',reviewerName:''});
    const [reqErr, setReqErr] = useState('');
    const [radiErrorState, setRadioErrorState] = useState(false);
    useEffect(()=>{
        console.log(data);
    },[data])
   
    function DecisionBtns(props) 
    {
        let decision= ((data !== null || data !== [] || data !== undefined) && data.map((data)=>
        {
            if(data.userHandle === userHandle )
                {
                    let decisionBtns=null;
                    data.verification_status && data.verification_status.verificationStatus ==='Verified' ?
                    decisionBtns = 
                        <FormControlLabel value="4" control={<Radio required />} label="Reject" onChange={selectStatus} />
                            :
                    decisionBtns =
                    <div className='d-flex'>
                        <FormControlLabel value="3" control={<Radio required />} label="Verify" onChange={selectStatus} />
                        <FormControlLabel value="4" control={<Radio required />} label="Reject" onChange={selectStatus} />
                    </div>
                    return decisionBtns;      
                }
        }
        ))
        return decision;
    }

    // useEffect(()=>{
    //     getUserDetails()
    //     .then(function (response) {
    //         if(response.status === 200)
    //         {   
    //             setReviewerDetails({reviewerID: response.data.id,reviewerName:response.data.name});
    //         }
    //     })  
    //  },[userData.id])

    // const getLoggedinUserDetails= ()=>{
       
    // }

    const getArtist = (userHandle) =>{
        dispatch(setLoader(true))
        getArtistDataByUserHandle(userHandle,'GET')
        .then(response=>{
            dispatch(setLoader(false))
            if(response.data)
            {
                setData(response.data)
                response.data.map((data)=>{
                setArtistId(data.id)
                })
            }
            else
            {
                setData([])
            }
            
        })
        .catch(err=>{
           dispatch(setLoader(false)) 
           dispatch(setSnackBar(true));
           setTimeout(()=>dispatch(setSnackBar(false),2000));
           dispatch(setSnackBarVariant('error'))
           dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
        })
      }

    const selectStatus = (e) =>{
        let statusVal = e.target.value === "3" ? {verification_status :'3',selectedValue:'True'}:{verification_status :'4',selectedValue:'False'}; 
        setIsVerified(statusVal.selectedValue);
        setStatus(parseInt(e.target.value));
        setReqErr('');  
        setRadioErrorState(false)
    }
    useEffect(()=>{
        if(userData.id)
        {   
            getArtist(userHandle)
        }
    },[userData.id])

    const validationHandlerRadiobtn = (e) =>{
        if(!verificationStatus){
            setReqErr('Please take a decision!');
            setRadioErrorState(true)
            return true;
        } else{
            setReqErr('');    
            setRadioErrorState(false)
            return false
        }
    }

    const handleRouteBack = () =>{
        history.push('/artistlist')
      }
   
    useEffect(()=>{
        dispatch(fetchUserDetail()) // to laod the latest values of artist
    },[])

    const getSocialIcon = (socialTypeCode) =>{
        switch (socialTypeCode){
            case 'facebook_1':{
                return <img style={{widht:'28px',height:'28px'}} src={FacebookImg} alt="Not found" />
            }
            case 'instagram_2':{
                return <img style={{widht:'28px',height:'28px'}} src={InstagramImg} alt="Not found" />
            }
            case 'twitter_3':{
                return <img style={{widht:'28px',height:'28px'}} src={TwitterImg} alt="Not found" />
            }
            case 'linkedin_4':{
                return <img style={{widht:'28px',height:'28px'}} src={LinkedinImg} alt="Not found" />
            }
            case 'youtube_5':{
                return <img style={{widht:'28px',height:'28px'}} src={YoutubeImg} alt="Not found" />
            }
            case 'spotify_6':{
                return <img style={{widht:'28px',height:'28px'}} src={SpotifyImg} alt="Not found" />
            }
            case 'website': {
                return <img style={{ widht: '32px', height: '32px' }} src={website} alt="Not found" />
            }
            case 'imdb': {
                return <img style={{ widht: '32px', height: '32px' }} src={Imbdlogo} alt="Not found" />
            }
            default:
                return
        }
    }

   const validationHandler = (e) =>{
    if(!remark)
      {
        setRemarkError('Please enter your remark!');
        setErrorState(true)
        return true;
      }
    else if(remark.length>3000)  
    {
        setRemarkError('Max character limit is 3000');
        setErrorState(true)
        return true;
    }
    else{
        setRemarkError('');
        setErrorState(false)
        return false
        }
   } 
   const verificationRequestHandler = (e) =>{
      e.preventDefault();
      e.stopPropagation()
      let isNotValid = validationHandler();
      if(isNotValid)
      { 
        return;
      }
      else{
        dispatch(setSnackBar(true))
        dispatch(setSnackBarMessage(formSnackbar.verifyArtist.verficationApply))
        dispatch(setSnackBarVariant('info'))
        let dataObj = {}
        dataObj.artist_id = artistId;
        dataObj.isVerified = isVerified
        dataObj.verification_status = verificationStatus
        dataObj.reviewer_remark = remark;
        dataObj.reviewer = reviewerDetails.reviewerID;
        dataObj.reviewer_name = reviewerDetails.reviewerName;


        verifyServiceHandler(dataObj,artistId)
        .then(response=>{
            if(response.status ===200){
                dispatch(setSnackBarMessage(formSnackbar.verifyArtist.verificationApplied))
                dispatch(setSnackBarVariant('success'))
                setTimeout(()=>{dispatch(setSnackBar(false))},2000) // to auto hide the message
                getArtist(userHandle); //to auto fetch the latest info
                //will be removed later
                getCreaterData()
                let verificationStatusObj ={}
                verificationStatusObj.isVerified = isVerified;
                verificationStatusObj.verification_status = verificationStatus;
                //updateUserprofileData(verificationStatusObj,artistId);
                setRemark('');
                drawerCloseHandler()
            }
            else{
                dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
                dispatch(setSnackBarVariant('error'))
                setTimeout(()=>{dispatch(setSnackBar(false))},3000)
            }  
        })
      }
   } 

   /* Function created  */
//    const updateUserInfo = () =>{
//         let updateObj = {
//             verification_status:'2',
//             isverified:false
//         }
//         updateProfile(updateObj,userData.id)
//         .then((response)=>{
//             dispatch(fetchUserDetail())
//         })
//    }
//    const updateUserprofileData = (data,id)=>{
//     updateProfile(data,id)
//     .then(function (response) {
//         if(response.status === 200)
//         {
//             getArtist(userHandle);
            
//         }
        
//     })  
// }
   const drawerCloseHandler = () =>{
        setRemarkError('');
        setErrorState(false)
        setRemark(null)
        setOpen(false)
   }
 
  useEffect(() => {
    const fetchedData = getArtist(userHandle);
    setData(fetchedData);
  }, []);
  
  return (
    <>
        <div >
            {data !==null && data!==undefined && data && data.map((artistData,index) =>( 
            <div key={artistData.id}>    
            <div className='p-3 d-flex justify-content-between align-items-center'>
                <div>
                    <button className= "icononly-btn" onClick={handleRouteBack}><ArrowBackIcon /> Back</button>
                </div>
                <div>
                    <div>
                        {artistData.verification_status && artistData.verification_status.verificationStatus === 'Verified'?
                        <button  className="gn-actionbtn" onClick={()=>setOpen(true)}>Unverify </button>:
                        <button  className="gn-actionbtn" onClick={()=>setOpen(true)}>VERIFY </button>}
                    </div>
                </div>
            </div>
            <div>
                <div className={`d-flex flex-wrap justify-content-between px-3 mb-5 pb-3`}>
                    <div className="verifyCol1 p-4 shadow bg-white rounded">
                        <div>
                            {/* header row 1 */}
                            <div className={`d-flex  align-items-center mb-4 flex-wrap ${smallViewport?'px-0':'px-0'}`}>
                                <div id="artist-avatar">
                                    <div className="item" >
                                        <div>
                                            {artistData && artistData.isVerified && <span className="notify-badge"><VerifiedIcon sx={{color:'#e02221',borderRadius:'50%',backgroundColor:'#fff'}}/></span>}
                                            {<img className = "rounded-circle" style={{height:'103px', width:'103px'}} src={artistData && artistData.avatar ? process.env.REACT_APP_CREATORURL_MEDIA+'media/'+artistData.avatar:profileImge} alt="Image not found"/>}
                                        </div>
                                    </div>
                                </div>
                                <div id="artist-status">
                                    <div style={{fontWeight:500,fontSize:'19px',marginLeft:'20px',marginBottom:'16px'}}>
                                        {artistData.name} 
                                        {/* {name} */}
                                    </div>
                                    <div style={{marginLeft:'16px'}}>
                                    <div  className={`${verificationStatusColor(artistData && artistData.verification_status && artistData.verification_status.verificationStatus&&artistData.verification_status.verificationStatus)} verifyStatus `} /*style={{border:'solid 2px', borderRadius:'20px', fontSize:'1rem'}}*/><span>{artistData.verification_status ?artistData.verification_status.verificationStatus:'Unverified'}</span></div> 
                                    </div>   
                                </div>
                            </div>
                            {/* body row 2 */}
                            <div style={{fontSize:'14px',fontWeight:'400'}}>
                                {/* Inner row 1 */}
                                <div className="mb-2">
                                    <div className='d-flex align-items-center'>
                                        <span className=" text-secondary">About</span>
                                        <hr className='ms-2' style={{flexGrow:'1',border: '1px solid rgba(0, 0, 0, 0.12)'}}/>
                                    </div>
                                    <div className="m-2">
                                        <p className='fw-bold'>
                                            {artistData && artistData.about?artistData.about:'No Information'}
                                        </p>
                                    </div>
                                </div>
                                {/* Inner row 2 */}
                                <div className="mb-2">
                                    <div className='d-flex align-items-center'>
                                        <span className=" text-secondary">Category</span>
                                        <hr className='ms-2' style={{flexGrow:'1',border: '1px solid rgba(0, 0, 0, 0.12)'}}/>
                                    </div>
                                    <div className="m-2 d-flex flex-wrap">
                                        {artistData && artistData.category && artistData.category.length>0?artistData.category.map(currentItem=><span className="me-3 fw-bold" key={currentItem.id}>{currentItem.category}</span>):<strong>No Information</strong>}
                                    </div>
                                </div>
                                {/* Inner Row 3 */}
                                <div className="mb-2">
                                    <div className='d-flex align-items-center'>
                                        <span className=" text-secondary">Genre</span>
                                        <hr className='ms-2' style={{flexGrow:'1', border: '1px solid rgba(0, 0, 0, 0.12)'}}/>
                                    </div>
                                    <div className="m-2 d-flex flex-wrap">
                                        {artistData && artistData.genres && artistData.genres.length>0?artistData.genres.map(currentItem=><span className="me-3 fw-bold" key={currentItem.id}>{currentItem.genre}</span>):<strong>No Information</strong>}
                                    </div>
                                </div>
                                {/*  Inner row 4 */}
                                <div className="mb-2">
                                    <div className='d-flex align-items-center'>
                                        <span className=" text-secondary">Social Platforms</span>
                                        <hr className='ms-2' style={{flexGrow:'1',border: '1px solid rgba(0, 0, 0, 0.12)'}}/>
                                    </div>
                                    <div className="m-2">
                                        {artistData && artistData.social && artistData.social.length>0?artistData.social.map(currentItem=><a href={currentItem.url} key={currentItem.id}><span className="me-2">{getSocialIcon(currentItem.socialType.socialTypeCode)}</span></a>):<strong>No Information</strong>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{width:'20px',height:'20px'}}></div>
                    <div className="verifyCol2 p-4 shadow bg-white rounded" style={{fontSize:'14px',fontWeight:'400'}}>

                        {/* <div> */}
                            <div>
                                {/* row 1 */}
                                <div className="mb-2">
                                    <div className='d-flex align-items-center'>
                                        <span className="text-secondary">Experience</span>
                                        <hr className='ms-2' style={{flexGrow:'1',border: '1px solid rgba(0, 0, 0, 0.12)'}}/>
                                    </div>
                                    <div className="m-2">
                                        {artistData && artistData.experience && artistData.experience.length>0?artistData.experience.map(currentItem=><div className="ms-2 fw-bold" key={currentItem.id}>{currentItem.companyName}</div>):<strong>No Information</strong>}
                                    </div>
                                </div>
                                {/*  row 2 */}
                                <div className="mb-2">
                                    <div className='d-flex align-items-center'>
                                        <span className="text-secondary">Language</span>
                                        <hr className='ms-2' style={{flexGrow:'1',border: '1px solid rgba(0, 0, 0, 0.12)'}}/>
                                    </div>
                                    <div className="m-2">
                                        {artistData && artistData.languages && artistData.languages.length>0?artistData.languages.map(currentItem=><span className="ms-2 fw-bold" key={currentItem.id}>{currentItem.language}</span>):<strong>No Information</strong>}
                                    </div>
                                </div>
                                {/* row 4 */}
                                <div className="mb-2">
                                    <div className='d-flex align-items-center'>
                                        <span className=" text-secondary">Contact Information</span>
                                        <hr className='ms-2' style={{flexGrow:'1',border: '1px solid rgba(0, 0, 0, 0.12)'}}/>
                                    </div>
                                    <div className="m-2">
                                        {artistData && artistData.contactNumber?<span className='ms-2 fw-bold'>
                                            {artistData && artistData.country && ''}
                                            {artistData.contactNumber}</span>:<strong>No Information</strong>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/* </div> */}
                </div>
            </div>
                </div>
           ) )} 
        </div>
 
    
     {/* Drawer for add platofrom */}
     
        <Drawer
            sx={{
                width: '400px',
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: smallViewport?'300px':'400px',
                    boxSizing: 'border-box',
                    backgroundColor:'#F6F6F6'
                },
                }}
                anchor="right"
                open={open}
            >  
       
                <div>
                    <div className="p-2 shadow d-flex justify-content-between align-items-center" style={{backgroundColor:'#e5e5e5'}}>
                        <div style={{marginLeft:'16px'}}><h5>Verify</h5></div>
                        <div style={{position:'relative',top:'-5px'}}><button title="Close" className="icononly-btn" onClick={drawerCloseHandler}><CloseIcon /></button></div> 
                    </div>
                    <div className="p-4" style={{backgroundColor:'#F6F6F6'}}>
                        <p style={{fontSize:'18px',fontWeight:400}}> Please Specify</p>
                        <div className='my-2'>
                            <form onSubmit={verificationRequestHandler}>
                                <div className='mb-4'>
                                    <TextField
                                        id="outlined-multiline-static"
                                        label="Type Your Comment"
                                        multiline
                                        rows={4}
                                        required
                                        value={remark}
                                        onChange={(e)=>setRemark(e.target.value)}
                                        sx={{width:'100%'}}
                                        error={errorState}
                                        helperText={remarkError}
                                        onBlur = {validationHandler}
                                    />
                                </div>
                                
                                <div className='col-md-6 mb-3'>
                                 <FormControl error={radiErrorState} onBlur = {validationHandlerRadiobtn}>
            <FormLabel id="demo-row-radio-buttons-group-label"><p className="mt-4" style={{color:'#000000de',fontSize:'18px',fontWeight:400}}>Take Decision </p></FormLabel>
            <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
               <DecisionBtns />
            </RadioGroup>
            <FormHelperText>{reqErr}</FormHelperText>
            </FormControl>
            </div>

                                <button type="submit" className='gn-actionbtn' /* onClick={verificationRequestHandler} */>Submit</button>
                                <button type="button" className='ms-4 gn-actionbtn outlined' onClick={drawerCloseHandler}>Cancel</button>

                            </form>
                        </div>
                    </div>
                </div>
        </Drawer>       
        <SnackbarContainer />               
    </>
  )
}
export default ArtistVerification
