import React, { useEffect, useState } from 'react';
import { Box, Paper, Tooltip, Typography } from '@mui/material';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import TableComponent from './Tablecomponent';
import { useDispatch, useSelector } from 'react-redux';
import { formatIndianNumber, getMonthValue } from './formateRevenueData';
import { useLocation, useParams } from 'react-router-dom';
import Search from './Search';
import { fetchRoyaltyDetail } from '../../redux/royalty/royaltyDataActions';

const VideoRoyalty = ({ hideTotal }) => {
    const dispatch = useDispatch();
    const { studioId } = useParams()
    const location = useLocation();
    const videoData = useSelector((state) => state.royaltyDataReducer.videoData);
    const singlevideoData = useSelector((state) => state.royaltyDataReducer?.singlevideoData);
    const switchValue = useSelector((state) => state.royaltyDataReducer.switchRevenueValue);
    const userData = useSelector((state) => state.userData.userData);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const total_count = videoData?.total_count
    const isDetailsPage = location.pathname === '/details';
    const tabDynamic = location?.state?.state
    const publisherId = userData.id;
    const partener_detail = { partner_type: 'studio', partner_id: studioId, share_type: 'contract_partner' }
    const partener_admin_detail = { partner_type: 'admin', partner_id: publisherId, share_type: 'publisher' }

    function allRevenue() {
        if (studioId == undefined || studioId == null || studioId == "") {
            if (isDetailsPage) {
                const monthName = tabDynamic?.name;
                let songId = tabDynamic?.id ? tabDynamic?.id : getMonthValue(monthName)
                let parterdetail = switchValue == 1 ? { ...partener_admin_detail, publisher_type: 'admin' } : null
                dispatch(fetchRoyaltyDetail(tabDynamic.pageCall, { offset: page, limit: rowsPerPage, ...parterdetail }, songId + '/video', `SINGLE_VIDEO`))
            } else {
                if (publisherId && switchValue == 1) {
                    dispatch(fetchRoyaltyDetail("adminvideoData", { page, limit: rowsPerPage, ...partener_admin_detail }, 'admin/', `VIDEO`));
                } else {
                    dispatch(fetchRoyaltyDetail("videoData", { offset: page, limit: rowsPerPage }, null, `VIDEO`))
                }
            }
        }
    }

    useEffect(function () {
        allRevenue()
    }, [dispatch, page, rowsPerPage, studioId, publisherId]);

    useEffect(() => {
        if (studioId && publisherId) {
            const details = switchValue == 2 ? partener_detail : partener_admin_detail;
            if (location.pathname == `/details/${studioId}`) {
                const monthName = tabDynamic?.name;
                let songId = tabDynamic?.id ? tabDynamic?.id : getMonthValue(monthName)
                dispatch(fetchRoyaltyDetail(tabDynamic.pageCall, { offset: page, limit: rowsPerPage, publisher_type: 'studio', publisher_id: studioId, ...details }, songId + '/video', `SINGLE_VIDEO`))
            } else {
                dispatch(fetchRoyaltyDetail("adminvideoData", { offset: page, limit: rowsPerPage, ...details }, `studio/${studioId}/`, `VIDEO`))
            }
        }
    }, [publisherId, switchValue])


    const rows = (isDetailsPage || location.pathname == `/details/${studioId}` ? singlevideoData?.counts_per_video : videoData?.total_data?.length ? videoData.total_data : []) || [];

    const columns = [
        { id: 'song_name', numeric: false, field: 'song_name', label: 'Video', },
        // {
        //     id: 'primary_artist', label: 'Artist', headerName: '', flex: 1, template: (row) => {
        //         return (
        //             <Box className='d-flex gap-2 flex-wrap align-items-center'>
        //                 {row.primary_artist.length ? (
        //                     <>
        //                         {row.primary_artist.slice(0, 2).map((d, i) => (
        //                             <Typography key={i} className='rounded-pill px-3 py-1' sx={{ background: "#EBEBEB", fontSize: '12px' }}>{d}</Typography>
        //                         ))}
        //                         {row.primary_artist.length > 2 && (
        //                             <Tooltip title={row.primary_artist.slice(2).map(d => d).join(', ')} arrow>
        //                                 <Typography className='rounded-pill px-3 py-1' sx={{ background: "#EBEBEB", fontSize: '12px' }}> {"+" + row.primary_artist.length}</Typography>
        //                             </Tooltip>
        //                         )}
        //                     </>
        //                 ) : null}</Box>
        //         );
        //     }
        // },
        { id: 'total_stream_count', field: "total_stream_count", numeric: true, label: 'Streams' },
        { id: 'total_video_downloads', field: "total_video_downloads", numeric: true, label: 'Video Downloads' },
        {
            id: 'total_stream_earning', label: 'Earnings', headerName: '', flex: 1, template: (row) => {
                const totalDownloads = row.total_stream_earning + row.total_video_download_earning;
                return (
                    <Box className="d-flex align-items-center"><CurrencyRupeeIcon sx={{ fontSize: '15px' }} />{formatIndianNumber(totalDownloads)}</Box>
                );
            }
        },
    ];

    const headFooter = [
        { id: 'name', numeric: false, label: 'Totals' },
        // { id: 'artist', numeric: false, label: '-' },
        { id: 'stream', numeric: true, label: videoData.length != 0 ? formatIndianNumber(videoData.overall_total_streams) : 0 },
        { id: 'download', numeric: true, label: videoData.length != 0 ? formatIndianNumber(videoData.overall_video_downloads) : 0 },
        { id: 'earning', numeric: true, label: <Box className="d-flex align-items-center"><CurrencyRupeeIcon sx={{ fontSize: '15px' }} />{formatIndianNumber(videoData.overall_video_downloads_earnings + videoData.overall_streams_earning)}</Box> },
    ];

    return (
        <Paper className="shadow-table" sx={{ width: '100%', mb: 2, mt: 1 }}>
            <Search headValue={'Video'} />
            <TableComponent columns={columns} data={rows} headFooter={headFooter} widthSet={true} total_count={total_count} page={page} setPage={setPage} hideTotal={hideTotal} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
        </Paper>
    );
};

export default VideoRoyalty;
