import axios from "axios";
import { songApproverApi } from '../_constants/api.constant'
import {  getSelfToken } from '../../_helpers/auth/secureToken'
export async function UpdateAlbumApprover(albumId,postData) 
{
        const response=await axios.put(
        songApproverApi.ALBUM_APPROVER+albumId+"/",
        postData,
        {
            headers:{
                Authorization: 'Token '+getSelfToken(),
            },
        }
        ).then(function(response) {
        return response;
        }).catch(function (error) {
        console.log(error);
        console.log(error.response);
        return error.response;
        });
        return response;
   
} 
