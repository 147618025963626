import * as React from 'react';
import List from '@mui/material/List';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import ApiService from '../../../_services/ApiService';
import { useState, useEffect } from 'react';
import { Box, Button, DialogActions, DialogContent, Grid, IconButton, ListItem, ListItemText, Skeleton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import { useSelector } from 'react-redux';
import { setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../../redux';
import { useDispatch } from 'react-redux';

export default function StatusDetailDialog({ rowId, open, setOpen }) {
    const [statusData, setStatusData] = useState([])
    const [loader, setLoader] = useState(true)
    const [ratio, setratio] = useState('')
    const [studioDetail, setStudioDetail] = useState([])
    const userData = useSelector((state) => state.userData.userData);
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState('');

    function skeletonList() {
        return (
            [1, 2, 3, 4, 5].map((v, i) => (
                <Grid item xs={12} sm={6} key={i}>
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </Grid>
            ))
        )
    }

    function getStudioStatus() {
        if (rowId.value == 'songStatus') {
            ApiService.get('studioSongStatus', {}, rowId.id).then(response => {
                setLoader(false)
                if (response.status == 200) {
                    let objectGet = response.data;
                    setStatusData([{ id: '1', label: 'In Review', value: objectGet?.song_status_counts.In_Review },
                    { id: '2', label: 'Ready for Release', value: objectGet?.song_status_counts.Ready_for_Release },
                    { id: '3', label: 'Rejected', value: objectGet?.song_status_counts.Rejected },
                    { id: '4', label: 'Released', value: objectGet?.song_status_counts.Released },
                    { id: '5', label: 'Draft', value: objectGet?.song_status_counts.draft }])
                }
            }).catch(function (error) {
                setLoader(false)
                return error.response;
            })
        } else {
            ApiService.get('studioDetail', {}, rowId.id).then(response => {
                setLoader(false)
                if (response.status == 200) {
                    setStudioDetail([response.data])
                    setratio(response.data?.contract_ratio[response.data?.contract_ratio.length - 1].contract_partner_ratio||'')
                }

            }).catch(function (error) {
                setLoader(false)
                return error.response;
            })
        }
    }

    useEffect(() => {
        if (rowId.id) {
            getStudioStatus()
        }

    }, [rowId])

    const handleClose = (value) => {
        setOpen(false);
    };

    const changeHandler = (event) => {
        const value = event.target.value;
        setErrorMessage('');
        if (isNaN(value) || value < 0 || value > 100) {
            setErrorMessage('Please enter a valid share percentage (0-100)');
            return; // Prevent invalid values from being set
        }
        setratio(value)
    }

    function updateRevenueShare() {
        let payload = {
            revenue_share_type: 'Distributor-Studio',
            publisher_type: 'admin',
            publisher_id: userData.id,
            publisher_ratio: (100 - ratio),
            contract_partner_id: rowId.id,
            contract_partner_type: 'studio',
            contract_partner_ratio: parseInt(ratio)
        }

        ApiService.post('contractRatio', payload).then(response => {
            if (response.status == 200 || response.status == 201) {
                dispatch(setSnackBarMessage('Contract Ratio Created Successfully'));
                dispatch(setSnackBarVariant('success'));
                dispatch(setSnackBar(true));
                setTimeout(() => dispatch(setSnackBar(false)), 2000);
                setOpen(false);
            }

        }).catch(function (error) {
            dispatch(setSnackBarMessage('Something went wrong'));
            dispatch(setSnackBarVariant('error'));
            setTimeout(() => dispatch(setSnackBar(false)), 2000);
            return error.response;
        })
    }

    return (
        <div>
            <Dialog fullWidth={true}
                maxWidth={'sm'} onClose={handleClose} open={open}>
                {rowId.value == 'songStatus' ?
                    <Box>
                        <DialogTitle className='bg-body-tertiary'>Song Status</DialogTitle>
                        <IconButton
                            onClick={handleClose}
                            aria-label="close"
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>

                        <List className='px-4 pb-4' sx={{ pt: 0 }}>
                            <Grid container spacing={2}>
                                {loader ?
                                    skeletonList()
                                    :
                                    statusData.map((d, i) => (
                                        <Grid item xs={6} sm={4} key={i}>
                                            <ListItem sx={{ paddingLeft: '0px' }}>
                                                <ListItemText
                                                    primary={<Typography className='fw-medium' sx={{ fontSize: '14px' }}>{d.label}</Typography>}
                                                    secondary={<Typography className='text-break w-100' sx={{ fontSize: '14px' }}>{d.value}</Typography>}
                                                />
                                            </ListItem>
                                        </Grid>
                                    ))}
                            </Grid>
                        </List>
                    </Box>
                    :
                    <Box>
                        <DialogTitle className='bg-body-tertiary'>Share Revenue</DialogTitle>
                        <DialogContent className='pt-3'>
                            <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>

                            <Typography variant='h6' className='border rounded-2 p-2 fs-6 bg-body-tertiary'>Distributor & Studio</Typography>
                            <Typography className='px-2 pt-3 fs-5'>Songs</Typography>
                            <Box gap={'5px'} className='d-flex flex-wrap border rounded-2 p-3 overflow-auto align-items-center' mt={2} sx={{ maxHeight: '200px' }} >

                                {!loader ? studioDetail[0]?.songs.map((d, i) => (
                                    <Box >
                                        <Typography key={i} className='text-white rounded-pill px-3 py-1 ' sx={{ background: "#1976D2", fontSize: '12px' }}>{d.song_name}</Typography>
                                    </Box>
                                )) : <Box className="w-25">{skeletonList()}</Box>}
                            </Box>
                            <TextField type='number'
                                value={ratio}
                                onChange={changeHandler}
                                className='mt-4 w-100' id="share"
                                label="Share %" variant="outlined"
                                inputProps={{ min: 0, max: 100 }}
                                error={!!errorMessage}
                                helperText={`Enter a value between 0 and 100`}
                            />
                        </DialogContent>
                        <DialogActions className='p-4'>
                            <Button
                                onClick={handleClose}
                                className='shadow-none text-black px-3'>CANCEL</Button>
                            <Button onClick={updateRevenueShare}
                                sx={{ backgroundColor: '#e41116' }}
                                className={`${ratio === '' || errorMessage != '' ? 'opacity-50' : ''} text-white shadow-none rounded-3 px-3`}
                                disabled={ratio && errorMessage == '' ? false : true}>SAVE</Button>
                        </DialogActions>
                    </Box>
                }
            </Dialog>
        </div>
    );
}
