import * as React from 'react';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, IconButton, Menu, Paper } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SnackbarContainer from '../Snackbar';
import DeleteModal from '../../components/reusableComponents/DeleteModal';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import { useRef } from 'react';
import { setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import { formSnackbar } from '../../_services/_constants/snackbar.constant';
import { submitDemo } from '../../_services/creater/creatorDemo.service';
import { getDateAsPerFormat } from '../../_helpers/reusablefunctions/dateSetter';
import AudioPlayerV3 from '../../components/reusableComponents/AudioPlayerV3';
import TableComponent from '../importRevenue/Tablecomponent';

export default function DemoDataTable({ demoListData, getData, setRowsPerPage, setPage, page, rowsPerPage, searchValue, getSearchDemoData }) {
  const [rows, setRows] = useState(demoListData ? demoListData.results : []);
  const [totalCount, setTotalCount] = useState('');
  const userData = useSelector((state) => state.userData.userData);
  const [selected, setSelected] = useState([]);
  const ref = useRef();
  const AudioPlayerRef = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [selectedSong, setSelectedSong] = useState(null)
  const dispatch = useDispatch();
  const [audioData, setAudioData] = React.useState(null)

  React.useEffect(() => {
    setRows(demoListData.results)
    setTotalCount(demoListData?.total_count)
  }, [demoListData])

  const columns = [
    { id: 'name', field: 'name', label: 'Creator Name ' },
    { id: 'audio_name', field: 'audio_name', label: 'Demo/Workpiece Title' },
    { id: 'description', field: 'description', label: 'Description' },
    {
      id: 'created', label: 'Submit Date', headerName: '', flex: 1, template: (row) => {
        return (
          <Box>
            {row.created ? getDateAsPerFormat(row.created, "DD Mon, YYYY") : ''}
          </Box>
        )
      }
    },
    {
      id: 'action', label: 'Action', headerName: '', flex: 1, template: (row) => {
        return (
          <Box>
            <DeleteModal cardId={row && row.id} ref={ref} deleteHandler={handleDeleteItem} />
            <IconButton
              onClick={(event) => handleClickActions(event, row)}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <MoreVertIcon />

            </IconButton>

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open && selectedSong?.id === row.id}
              // onClose={handleClosee}
              onClick={handleClosee}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  width: '120px',
                  filter: 'drop-shadow(0px 4px 8px rgba(0,0,0,0.1))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <div className=' p-2 '>
                <div className=' d-flex' >
                  <Button className='text-secondary' size="large" key={row.id} startIcon={<PlayCircleFilledIcon />} onClick={() => setAudioData(row)} disableFocusRipple>
                    Play
                  </Button>
                </div>
                <div className=' d-flex'>
                  <Button className='text-secondary' size="large" key={row.id} startIcon={<DeleteIcon />} onClick={() => ref.current.handleOpen()} disableElevation>
                    Delete
                  </Button>
                </div>

              </div>
            </Menu>
          </Box>
        )
      }
    }
  ];

  const callAudioPlayerMethod = (selectedSong) => {
    AudioPlayerRef.current.handleOpenPlayer(selectedSong);
  };
  const handleClickActions = (event, row) => {
    setSelected(row.id);
    setAnchorEl(event.currentTarget);
    setSelectedSong(row);
  };
  const handleClosee = () => {
    setAnchorEl(null);
  };

  const removeData = (data) => {
    if (selected) {
      dispatch(setSnackBarVariant('info'))
      dispatch(setSnackBarMessage(formSnackbar.submitDemo.DemoDelete))
      setTimeout(() => dispatch(setSnackBar(false)), 2000)
      dispatch(setSnackBar(true))
      let itemId = selected;
      submitDemo({ 'itemId': itemId }, 'DELETE').then(function (response) {
        if (response.status === 200 || response.status === 204) {
          dispatch(setSnackBarVariant('success'))
          dispatch(setSnackBarMessage(formSnackbar.submitDemo.DemoDeleteSuccess))
          dispatch(setLoader(false))
          ref.current.modalClose()
          setRows(prevRows => prevRows.filter(row => row.id !== itemId));
          if (searchValue) {
            getSearchDemoData(searchValue, userData.id, 0)
          } else {
            getData(userData.id, page)
          }
          setTimeout(() => dispatch(setSnackBar(false)), 2000)
        }
        else {
          dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure))
          dispatch(setSnackBarVariant('error'))
        }
      })
    }
  }

  const handleDeleteItem = (id) => {
    setSelected(id);
    removeData();
  };

  return (
    <div className="container-fluid py-4 creator-playlist-panel">
      <Paper className="shadow-table" sx={{ width: '100%', mb: '3rem' }}>
        <TableComponent columns={columns} data={rows} page={page} setPage={setPage} removeStyle={'removeStyle'} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} totalCount={totalCount} />
      </Paper>
      {audioData &&
        <AudioPlayerV3
          audioTitle={audioData.audio_name} audioDescription={audioData.description}
          audioSrc={audioData.audio_clip ? `${process.env.REACT_APP_CREATORURL_MEDIA}media/${audioData.audio_clip}` : ''}
          openState={audioData ? true : false}
          fullData={audioData}
          audioClipSetter={setAudioData} />
      }
      <SnackbarContainer />
    </div>
  );
}