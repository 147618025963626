import React, { useEffect, useState } from 'react';
import TableComponent from './Tablecomponent';
import { Box, Link, Paper, Typography } from '@mui/material';
export default function ContractDetail({ studioDetail }) {

    const [contractDetailList, setContractDetailList] = useState([])

    useEffect(() => {
        if (studioDetail.length) {
            setContractDetailList(studioDetail[0].contract_ratio)
        }
    }, [studioDetail])
    const rows = contractDetailList

    const columns = [
        { id: 'revenue_share_type', field: "revenue_share_type", label: 'Revenue Share Type ' },
        {
            id: 'contract_partner_ratio', label: 'Ratio', headerName: '', flex: 1, template: (row) => {
                return (
                    <Box>{row.contract_partner_ratio + "%"}</Box>
                );
            }
        },
    ];

    return (
        <Paper sx={{ width: '100%', mb: 5, mt: 4 }}>
            <Typography variant='h6' className='p-3'>Contract Detail</Typography>
            <TableComponent columns={columns} data={rows} />
        </Paper>
    );
}
