import { Box, Checkbox, Drawer, FormControl, Grid, IconButton, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField, useMediaQuery } from '@mui/material'
import React, { useEffect } from 'react'
import SwitchFilter from '../../components/reusableComponents/SwitchFilter'
import { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { getGenre } from '../../_services/profile';
import moment from 'moment-timezone';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      zIndex: '9999!important',
    },
  },
};

function SongFilters({ isFilterApplied, applyAdvanceFilters }) {
  const recordingStudio = [
    'Draft', 'In Review', 'Released', 'Ready For Release', 'Reject'
  ]
  const [open, setOpen] = useState(false)
  const smallViewport = useMediaQuery('(max-width:768px)');
  const [selectedStudio, setSelectedStudio] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [selectedArtist, setSelectedArtist] = useState([]);
  const [selectedAlbums, setSelectedAlbums] = useState([]);
  const [selectedSongStatus, setSelectedSongStatus] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [payloadData, setPayloadData] = useState({})

  const drawerCloseHandler = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (!isFilterApplied) {
      setSelectedStudio([])
      setSelectedGenres([])
      setToDate(null)
      setFromDate(null)
      setSelectedArtist([])
      setSelectedAlbums([])
      setSelectedSongStatus([])
    }
  }, [isFilterApplied])

  const handleSongChange = (event) => {
    setSelectedStudio(event.target.value)
  };

  const handleArtistChange = (event) => {
    setSelectedArtist(event.target.value)
  };
  const handleAlbumChange = (event) => {
    setSelectedAlbums(event.target.value)
  };
  const handleChangeStatus = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedSongStatus(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const dateZoneHandler = (date) => {
    const newDate = moment.utc(date.toISOString()).tz("Asia/Kolkata");
    const convertedDate = newDate.format('YYYY-MM-DD HH:mm:ss');
    return convertedDate
  }

  const fromDateChangeHandler = (newValue) => {
    setFromDate(newValue)
    const isReleaseDateInvalid = newValue === null || isNaN(Date.parse(newValue));
    if (!isReleaseDateInvalid) {
      const datePayload = dateZoneHandler(newValue)
      setPayloadData({ ...payloadData, release_date_from: datePayload })
    }
  }

  const toDateChangeHandler = (newValue) => {
    setToDate(newValue)
    const isReleaseDateInvalid = newValue === null || isNaN(Date.parse(newValue));
    if (!isReleaseDateInvalid) {
      const datePayload = dateZoneHandler(newValue)
      setPayloadData({ ...payloadData, release_date_to: datePayload })
    }
  }

  function applyFilter(e) {
    e.preventDefault();
    var postData = {}
    if (selectedStudio && selectedStudio.length > 0) {
      postData['studio_name'] = selectedStudio
    }
    if (selectedAlbums && selectedAlbums.length > 0) {
      postData['album_name'] = selectedAlbums
    }
    if (selectedArtist && selectedArtist.length > 0) {
      postData['artist_name'] = selectedArtist
    }
    if (selectedSongStatus && selectedSongStatus.length > 0) {
      postData['song_status'] = selectedSongStatus.join(',')
    }
    if (fromDate) {
      postData['release_date_from'] = payloadData.release_date_from
    }
    if (toDate) {
      postData['release_date_to'] = payloadData.release_date_to
    }

    if (selectedStudio.length > 0 || selectedGenres.length > 0 || selectedAlbums.length > 0
      || selectedArtist.length > 0 || selectedSongStatus.length > 0 || fromDate != null || toDate != null) {
      applyAdvanceFilters(postData)
      drawerCloseHandler()
    }

  }
  return (
    <div>
      {/* filter icon button */}
      <Box onClick={() => setOpen(true)} className='mx-2' style={{ padding: 0, borderRadius: 0, fontSize: 0 }}>
        <SwitchFilter />
      </Box>
      <Drawer
        sx={{
          width: '600px',
          zIndex: '9999',
          flexShrink: 0,
          '& .MuiDrawer-paper':
          {
            width: smallViewport ? '300px' : '600px',
            boxSizing: 'border-box',
            // backgroundColor: '#F6F6F6'
          },
        }}
        anchor="right"
        open={open}>
        <div>
          <div className="px-4 py-2 border d-flex justify-content-between align-items-center" >
            <div>
              <h4>Filters</h4>
            </div>
            <div style={{ position: 'relative', top: '-5px' }}>
              <button title="Close" className="icononly-btn" onClick={drawerCloseHandler}>
                <CloseIcon />
              </button>
            </div>
          </div>
          <div className="p-4">
            <Grid container spacing={2}>
              <Grid item xl={12} sm={12} xs={12} lg={12} >
                <h5 className='mb-2'>Studio Name</h5>
                <FormControl fullWidth size='small'>
                  <TextField
                    label="Enter studio name"
                    size="small"
                    value={selectedStudio}
                    onChange={handleSongChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xl={12} sm={12} xs={12} lg={12} >
                <h5 className='mb-2'>Artist</h5>
                <FormControl fullWidth size='small'>
                  <TextField
                    label="Enter artist name"
                    size="small"
                    value={selectedArtist}
                    onChange={handleArtistChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xl={12} sm={12} xs={12} lg={12} >
                <h5 className='mb-2'>Album</h5>
                <FormControl fullWidth size='small'>
                  <TextField
                    label="Enter album name"
                    size="small"
                    value={selectedAlbums}
                    onChange={handleAlbumChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xl={12} sm={12} xs={12} lg={12} >
                <h5 className='mb-2'>Status</h5>
                <FormControl fullWidth size='small'>
                  <InputLabel id="demo-multiple-checkbox-label">Select Status</InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    value={selectedSongStatus}
                    onChange={handleChangeStatus}
                    input={<OutlinedInput label="Select Status" />}
                    renderValue={(selected) => selected.join(', ')}
                    // MenuProps={MenuProps}
                    MenuProps={{
                      sx: {
                        zIndex: 9999, // Set your desired z-index value here
                      },
                    }}
                  >
                    {recordingStudio.map((recordingStudio) => (
                      <MenuItem key={recordingStudio} value={recordingStudio}>
                        <ListItemText primary={recordingStudio} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xl={12} sm={12} xs={12} lg={12} >
                <h5 className='mt-3'>Release Date</h5>
              </Grid>
              <Grid item xl={6} sm={12} xs={12} lg={6} >
                <FormControl fullWidth={true}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}  >
                    <DatePicker
                      views={['year', 'month', 'day']}
                      label="From Date"
                      maxDate={dayjs(toDate)}
                      value={fromDate}
                      /*  onChange={(newValue) => {
                           setFromDate(newValue);
                       }} */
                      onChange={fromDateChangeHandler}
                      renderInput={(params) => <TextField size="small" sx={{ minWidth: "100%", backgroundColor: '#F6F6F6', mt: 1 }} {...params} />}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xl={6} sm={12} xs={12} lg={6} >
                <FormControl fullWidth={true} >
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}>
                    <DatePicker
                      views={['year', 'month', 'day']}
                      label="To Date"
                      value={toDate}
                      onChange={toDateChangeHandler}
                      renderInput={(params) => <TextField size="small" sx={{ minWidth: "100%", backgroundColor: '#F6F6F6', mt: 1 }} {...params} />}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xl={12} sm={12} xs={12} lg={12} className='text-end py-5' >
                <button className='gn-actionbtn' onClick={applyFilter}>Submit</button>
                <button type="button" className='ms-4 gn-actionbtn outlined' onClick={drawerCloseHandler}>Cancel</button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Drawer>
    </div>
  )
}

export default SongFilters