import React, { useState, useEffect } from 'react'
import SearchBox from '../../components/reusableComponents/SearchBox';
import SwitcherBtn from '../../components/reusableComponents/SwitcherBtn';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLoginUser, setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant, fetchSongReleaseList } from '../../redux';
import { formSnackbar } from '../../_services/_constants/snackbar.constant';
import { Chip, useMediaQuery } from '@mui/material';
import ReleaseCard from './ReleaseCard';
import { default_limit } from '../../_services/_constants/recordLimitset.constant';
import { getReleaseAlbum } from '../../_services/album/getReleaseAlbum.service';
import ReleaseTable from './ReleaseTable';
import ApiService from '../../_services/ApiService';
import SongFilters from '../submitSong/SongFilters';

function ReleaseList(props) {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData.userData);
  const smScreen = useMediaQuery('(max-width:768px)')
  //to get the search input value
  const [searchInput, setSearchInput] = useState(null)
  const [layoutComponent, setLayoutComponent] = useState(true)
  const [albumList, setAlbumList] = useState([])
  const [renderComponent, setRenderComponent] = useState()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(default_limit);
  const [isFilterApplied, setIsFilterApplied] = useState(false)

  useEffect(function () {
    if (userData.id) {
      if (searchInput) {
        getSearchAlbumData(searchInput, page)
      } else {
        getData(userData.id, page);
      }
    }
  }, [userData.id, rowsPerPage, page]);

  const renderAlbumGrid = () => {
    return (
      <ReleaseTable albumListData={albumList}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    )
  }

  const renderAlbumCard = () => {
    return (
      <ReleaseCard albumListData={albumList}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
      />
    )
  }

  useEffect(() => {
    if (albumList?.results) {
      layoutComponent && setRenderComponent(renderAlbumGrid());
    }
  }, [albumList, layoutComponent]);

  function applyAdvanceFilters(payload) {
    const offset = page;
    const limit = rowsPerPage;
    setSearchInput(null);
    dispatch(setLoader(true))
    ApiService.get('advanceFilterSong', { ...payload, limit, offset }).then(function (response) {
      dispatch(setLoader(false))
      if (response.status === 200) {
        setIsFilterApplied(true)
        setAlbumList(response.data)
        setPage(page);
        // copyHandleLayoutRerender(layoutComponent, response.data)
      }
      else {
        dispatch(setSnackBar(true));
        dispatch(setSnackBarMessage(formSnackbar.errors.imgUploadSize));
        dispatch(setSnackBarVariant('error'));
        setTimeout(() => dispatch(setSnackBar(false)), 3000)
      }
    }).catch(function (error) {
      dispatch(setLoader(false))
      dispatch(setSnackBar(true));
      dispatch(setSnackBarMessage(formSnackbar.errors.imgUploadSize));
      dispatch(setSnackBarVariant('error'));
      setTimeout(() => dispatch(setSnackBar(false)), 3000)
      return error.response;
    })
  }

  function getData(userId, page) {
    const offset = page;
    const limit = rowsPerPage;
    dispatch(setLoader(true))
    getReleaseAlbum(limit, offset).then(function (response) {
      dispatch(setLoader(false))
      if (response.status === 200) {
        setAlbumList([])
        setAlbumList(response.data)
        setPage(page);
        // copyHandleLayoutRerender(layoutComponent, response?.data)
      }
      else {
        dispatch(setSnackBar(true));
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
        dispatch(setSnackBarVariant('error'));
        setTimeout(() => dispatch(setSnackBar(false)), 3000)
      }
    }).catch(function (error) {
      dispatch(setLoader(false))
      dispatch(setSnackBar(true));
      dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
      dispatch(setSnackBarVariant('error'));
      setTimeout(() => dispatch(setSnackBar(false)), 3000)
      return error.response;
    })
  }

  //to get the searchbox input and filter the data
  //to hit the search and display the result
  const getSearchAlbumData = (value, page) => {
    const offset = page;
    const limit = rowsPerPage;
    dispatch(setLoader(true))
    ApiService.get('searchSong', { 'search': value, limit, offset }).then(function (response) {
      dispatch(setLoader(false))
      if (response.status === 200) {
        setAlbumList([])
        setAlbumList(response.data)
        setPage(page);
        setRowsPerPage(limit)
        // setRenderComponent(renderAlbumGrid())
        // copyHandleLayoutRerender(layoutComponent, response?.data)

      }
      else {
        dispatch(setSnackBar(true));
        dispatch(setSnackBarMessage('Something went wrong'));
        dispatch(setSnackBarVariant('error'));
        setTimeout(() => dispatch(setSnackBar(false)), 3000)
      }
    }).catch(function (error) {
      dispatch(setLoader(false))
      dispatch(setSnackBar(true));
      dispatch(setSnackBarMessage('Something went wrong'));
      dispatch(setSnackBarVariant('error'));
      setTimeout(() => dispatch(setSnackBar(false)), 3000)
      return error.response;
    })
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchInput = (value) => {
    if (value) {
      setSearchInput(value)
    } else {
      getData(userData.id, page)
    }
    // getSearchAlbumData(value) //call the search to filter the data
  }

  useEffect(
    function () {
      if (searchInput) {
        let delaySearch;
        delaySearch = setTimeout(() => {
          getSearchAlbumData(searchInput, 0)
        }, 1000);
        return () => clearTimeout(delaySearch);
      }
    }, [searchInput])
  //get sets the list data

  //to set the current display component card or table view
  const handleLayoutComponent = (currentComp) => {
    setLayoutComponent(currentComp)
    currentComp ? setRenderComponent(renderAlbumGrid()) : setRenderComponent(renderAlbumCard())
  }

  const removeFilters = () => {
    setIsFilterApplied(false)
    getData(userData.id, 0)
  }


  return (
    <div className={`row ${smScreen ? 'p-2' : ''}`} style={{ padding: '1rem 1rem 3rem 1rem' }}>
      <h2 style={{ marginLeft: '10px' }}>Release Lists</h2>
      <div className={`d-flex align-items-center justify-content-between mt-2`}>
        <div className='d-flex gap-2 align-items-center' style={{ marginLeft: '10px' }}>
          <SearchBox placeholder="Search by Studio name & Status" handleSearchInput={handleSearchInput} />
          {isFilterApplied ? <Chip label="Clear all filters" onDelete={removeFilters} /> : null}
        </div>
        <div className='d-flex justify-content-end' >
          <div>
            <SongFilters title="Filter" isFilterApplied={isFilterApplied} applyAdvanceFilters={applyAdvanceFilters} />
          </div>
          <div className='switchView'>
            <SwitcherBtn layoutComponent={layoutComponent} handleLayoutComponent={handleLayoutComponent} />
          </div>
        </div>
      </div>
      <div>
        {renderComponent}
      </div>
    </div>
  )
}

export default ReleaseList