import React, { useState, useEffect } from 'react'
import SearchBox from '../../components/reusableComponents/SearchBox';
import SwitcherBtn from '../../components/reusableComponents/SwitcherBtn';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLoginUser, setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant, fetchSongReleaseList } from '../../redux';
import { formSnackbar } from '../../_services/_constants/snackbar.constant';
import { useMediaQuery } from '@mui/material';
import ArtistTable from './ArtistTable';
import ArtistCard from './ArtistCard';
import { getCreaterData } from '../../_services/artist/ArtistService';
import { default_limit } from '../../_services/_constants/recordLimitset.constant';

function ArtistList(props) {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData.userData);
  const smScreen = useMediaQuery('(max-width:768px)')
  const [searchInput, setSearchInput] = useState(null)
  const [layoutComponent, setLayoutComponent] = useState(true)
  const [artistList, setArtistList] = useState([])
  const [renderComponent, setRenderComponent] = useState()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(default_limit);

  useEffect(function () {
    if (userData.id) {
      if (searchInput) {
        getSearchArtistData(searchInput, userData.id, page)
      } else {
        getData(userData.id, page);
      }
    }
  }, [userData.id, rowsPerPage, page]);

  function getData(userId, page) {
    const offset = page;
    const limit = rowsPerPage;
    dispatch(setLoader(true))
    getCreaterData({}, 'GET', limit, offset).then(function (response) {
      dispatch(setLoader(false))
      if (response.status === 200) {
        setArtistList([])
        setArtistList(response.data)
        setPage(page);
        setRenderComponent(null)
        copyHandleLayoutRerender(layoutComponent, response.data)
      }
      else {
        dispatch(setSnackBar(true));
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
        dispatch(setSnackBarVariant('error'));
        setTimeout(() => dispatch(setSnackBar(false)), 3000)
      }
    }).catch(function (error) {
      dispatch(setLoader(false))
      dispatch(setSnackBar(true));
      dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
      dispatch(setSnackBarVariant('error'));
      setTimeout(() => dispatch(setSnackBar(false)), 3000)
      return error.response;
    })
  }
  useEffect(() => {
    if (!layoutComponent) {
      setRenderComponent(<ArtistTable artistListData={artistList}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage} />)
      setLayoutComponent(true)
    }
  }, [smScreen])

  //to get the searchbox input and filter the data
  //to hit the search and display the result
  const getSearchArtistData = (value, userId, page) => {
    const offset = page;
    const limit = rowsPerPage;
    dispatch(setLoader(true))
    getCreaterData({ 'searchData': value }, 'SEARCH', limit, offset).then(function (response) {
      dispatch(setLoader(false))
      if (response.status === 200) {
        setArtistList([])
        setArtistList(response.data)
        setPage(page);
        setRenderComponent(null)
        copyHandleLayoutRerender(layoutComponent, response.data)
      }
      else {
        dispatch(setSnackBar(true));
        dispatch(setSnackBarMessage(formSnackbar.errors.imgUploadSize));
        dispatch(setSnackBarVariant('error'));
        setTimeout(() => dispatch(setSnackBar(false)), 3000)
      }
    }).catch(function (error) {
      dispatch(setLoader(false))
      dispatch(setSnackBar(true));
      dispatch(setSnackBarMessage(formSnackbar.errors.imgUploadSize));
      dispatch(setSnackBarVariant('error'));
      setTimeout(() => dispatch(setSnackBar(false)), 3000)
      return error.response;
    })
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchInput = (value) => {
    setSearchInput(value)
  }
  useEffect(() => {
    const getData = setTimeout(() => {
      searchInput != null && getSearchArtistData(searchInput, userData.id, 0)
    }, 400)

    return () => clearTimeout(getData)
  }, [searchInput])

  //to set the current display component card or table view
  const handleLayoutComponent = (currentComp) => {
    setLayoutComponent(currentComp)
    currentComp ? setRenderComponent(<ArtistTable artistListData={artistList}
      page={page} setPage={setPage}
      setRowsPerPage={setRowsPerPage}
      rowsPerPage={rowsPerPage}
    />) : setRenderComponent(<ArtistCard artistListData={artistList}
      setPage={setPage} handleChangePage={handleChangePage}
      setRowsPerPage={setRowsPerPage}
      page={page}
      rowsPerPage={rowsPerPage}
    />)
  }
  //to render the component on search
  const copyHandleLayoutRerender = (currentComp, artistData) => {
    setLayoutComponent(currentComp)
    currentComp ? setRenderComponent(<ArtistTable artistListData={artistData}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
      page={page}
      rowsPerPage={rowsPerPage}
    />) : setRenderComponent(<ArtistCard artistListData={artistData}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      page={page}
      rowsPerPage={rowsPerPage}
    />)

  }

  return (
    <div className={`row ${smScreen ? 'p-2' : ''}`} style={{ padding: '1rem 1rem 3rem 1rem' }}>
      <h2 style={{ marginLeft: '10px' }}>Artist List</h2>
      <div className={`d-flex justify-content-between mt-2`}>
        <div className='flex-grow-1' style={{ maxWidth: '18rem', marginLeft: '10px' }}>
          <SearchBox placeholder="Search" handleSearchInput={handleSearchInput} />
        </div>
        <div className='d-flex justify-content-end' >
          {/* <div className=''>
              <SongFilters isFilterApplied={isFilterApplied} applyAdvanceFilters={applyAdvanceFilters}/>
            </div> */}
          <div className='switchView'>
            <SwitcherBtn layoutComponent={layoutComponent} handleLayoutComponent={handleLayoutComponent} />
          </div>
        </div>
      </div>
      <div>
        {renderComponent}
      </div>
    </div>
  )
}

export default ArtistList