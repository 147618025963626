import React, { useEffect, useState } from 'react';
import { Box, Paper, Tooltip, Typography } from '@mui/material';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import TableComponent from './Tablecomponent';
import { countryDynamic } from './TabConstData';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { formatIndianNumber, getMonthValue } from './formateRevenueData';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import Search from './Search';
import { fetchRoyaltyDetail } from '../../redux/royalty/royaltyDataActions';

const Countries = ({ hideTotal }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { studioId } = useParams()
    const singlecountryData = useSelector((state) => state.royaltyDataReducer.singlecountryData);
    const countryData = useSelector((state) => state.royaltyDataReducer.countryData);
    const switchValue = useSelector((state) => state.royaltyDataReducer.switchRevenueValue);
    const userData = useSelector((state) => state.userData.userData);
    const location = useLocation();
    const isDetailsPage = location.pathname === '/details';
    const tabDynamic = location?.state?.state
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const total_count = countryData?.total_count
    const publisherId = userData.id;
    const partener_detail = { partner_type: 'studio', partner_id: studioId, share_type: 'contract_partner' }
    const partener_admin_detail = { partner_type: 'admin', partner_id: publisherId, share_type: 'publisher' }

    const redirectDetails = (data) => {
        if (studioId == undefined || studioId == null || studioId == "") {
            let pagecellUrl = switchValue == 1 ? 'adminallsingleCountryData' : 'singleCountryData'
            history.push('/details', { state: {  data: countryDynamic, value: [data], pageCall: pagecellUrl, name: data?.country, id: data?.country_id } });
        } else {
            history.push(`/details/${studioId}`, { state: {  data: countryDynamic, value: [data], pageCall: 'adminsingleCountryData', name: data?.country, id: data?.country_id } });
        }
    }


    useEffect(function () {
        if (studioId == undefined || studioId == null || studioId == "") {
            if (isDetailsPage) {
                const monthName = tabDynamic?.name;
                let songId = tabDynamic?.id ? tabDynamic?.id : getMonthValue(monthName)
                let parterdetail = switchValue == 1 ? { ...partener_admin_detail, publisher_type: 'admin' } : null
                dispatch(fetchRoyaltyDetail(tabDynamic.pageCall, { offset: page, limit: rowsPerPage, ...parterdetail }, songId + '/country', `SINGLE_COUNTRY`))
            } else {
                if (publisherId && switchValue == 1) {
                    dispatch(fetchRoyaltyDetail("admincountryData", { page, limit: rowsPerPage, ...partener_admin_detail }, 'admin/', `COUNTRY`));
                } else {
                    dispatch(fetchRoyaltyDetail("countryData", { offset: page, limit: rowsPerPage }, null, `COUNTRY`))
                }
            }
        }
    }, [dispatch, page, rowsPerPage, publisherId, switchValue]);

    useEffect(() => {
        if (studioId && publisherId) {
            const details = switchValue == 2 ? partener_detail : partener_admin_detail;
            if (location.pathname == `/details/${studioId}`) {
                const monthName = tabDynamic?.name;
                let songId = tabDynamic?.id ? tabDynamic?.id : getMonthValue(monthName)
                dispatch(fetchRoyaltyDetail(tabDynamic.pageCall, { offset: page, limit: rowsPerPage, publisher_type: 'studio', publisher_id: studioId, ...details }, songId + '/country', `SINGLE_COUNTRY`))
            } else {
                dispatch(fetchRoyaltyDetail("admincountryData", { offset: page, limit: rowsPerPage, ...details }, `studio/${studioId}/`, `COUNTRY`))
            }
        }
    }, [publisherId, switchValue])

    const rows = (isDetailsPage || location.pathname == `/details/${studioId}` ? singlecountryData?.counts_per_country : countryData?.total_data?.length ? countryData.total_data : []) || [];

    const columns = [
        {
            id: 'country', label: 'Country', headerName: '', flex: 1, template: (row) => {
                return (
                    <Box className="d-flex align-items-center gap-2">
                        {row.country_logo || row.country_logo == 'None' ? <Box sx={{ height: 35, width: 35 }}>
                            <img className='w-100 h-100 rounded-pill'
                                style={{ objectFit: 'cover' }}
                                src={process.env.REACT_APP_BASEURL + 'song' + row.country_logo} alt='country-image' />
                        </Box> : <PublicRoundedIcon sx={{ opacity: '0.5' }} />}
                        {row.country}
                    </Box>
                );
            }
        },
        // {
        //     id: 'primary_artist', label: 'Artist', headerName: '', flex: 1, template: (row) => {
        //         return (
        //             <Box className='d-flex gap-2 flex-wrap align-items-center'>
        //                 {row.primary_artist.length ? (
        //                     <>
        //                         {row.primary_artist.slice(0, 2).map((d, i) => (
        //                             <Typography key={i} className='rounded-pill px-3 py-1' sx={{ background: "#EBEBEB", fontSize: '12px' }}>{d}</Typography>
        //                         ))}
        //                         {row.primary_artist.length > 2 && (
        //                             <Tooltip title={row.primary_artist.slice(2).map(d => d).join(', ')} arrow>
        //                                 <Typography className='rounded-pill px-3 py-1' sx={{ background: "#EBEBEB", fontSize: '12px' }}> {"+" + row.primary_artist.length}</Typography>
        //                             </Tooltip>
        //                         )}
        //                     </>
        //                 ) : null}</Box>
        //         );
        //     }
        // },
        { id: 'total_stream_count', field: "total_stream_count", numeric: true, label: 'Streams' },
        { id: 'release_download_count', field: "release_download_count", numeric: true, label: 'Release Downloads' },
        { id: 'track_download_count', field: "track_download_count", numeric: true, label: 'Track Downloads' },
        { id: 'video_download_count', field: "video_download_count", numeric: true, label: 'Video Downloads' },
        {
            id: 'total_stream_earning', label: 'Earnings', headerName: '', flex: 1, template: (row) => {
                const totalDownloads = row.total_stream_earning + row.total_release_download_earning + row.total_track_download_earning + row.total_video_download_earning;
                return (
                    <Box className="d-flex align-items-center"><CurrencyRupeeIcon sx={{ fontSize: '15px' }} />{formatIndianNumber(totalDownloads)}</Box>
                );
            }
        },
        {
            id: 'action', label: 'Action', headerName: '', flex: 1, template: (row) => {
                return (
                    <Box className='text-decoration-none viewDetailgradient cursorPointer' onClick={() => redirectDetails(row)} >View Details</Box>
                )
            }
        },
    ];
    const headFooter = [
        { id: 'name', numeric: false, label: 'Totals' },
        // { id: 'artist', numeric: false, label: '-' },
        { id: 'streams', numeric: true, label: countryData.length != 0 ? formatIndianNumber(countryData.overall_total_streams) : 0 },
        { id: 'releasedownloads', numeric: true, label: countryData.length != 0 ? formatIndianNumber(countryData.overall_release_downloads) : 0 },
        { id: 'trackdownloads', numeric: true, label: countryData.length != 0 ? formatIndianNumber(countryData.overall_track_downloads) : 0 },
        { id: 'videodownloads', numeric: true, label: countryData.length != 0 ? formatIndianNumber(countryData.overall_video_downloads) : 0 },
        { id: 'earnings', numeric: true, label: <Box className="d-flex align-items-center"><CurrencyRupeeIcon sx={{ fontSize: '15px' }} />{formatIndianNumber(countryData.overall_release_downloads_earnings + countryData.overall_track_downloads_earnings + countryData.overall_streams_earning + countryData.overall_video_downloads_earnings)}</Box> },
        { id: 'action', numeric: false, label: '' }
    ];

    return (
        <Paper sx={{ width: '100%', mb: 2, mt: 1 }}>
            <Search headValue={'Countries'} />
            <TableComponent columns={columns} data={rows} headFooter={headFooter} hideTotal={hideTotal} total_count={total_count} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
        </Paper>
    );
};

export default Countries;
