
import React, { useEffect } from 'react';
import {
	Route,
	Redirect,
	useLocation
} from "react-router-dom";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Sidebar from '../src/admin/header/sidebar';
import Footer from './admin/footer';
import { isSelfLogin } from './_helpers/auth/secureToken'
import ArtistBackgroundImg from "./img/bg_image_texture4.png"
import useMediaQuery from '@mui/material/useMediaQuery';
import { setSelectedValue } from './redux/loader/loaderActions';
import { useDispatch } from 'react-redux';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		// [theme.breakpoints.down('md')]: {
		// 	marginLeft: -300
		// },
		// marginLeft: `-${drawerWidth}px`,
		...(open && {
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginLeft: 0,
		}),
	}),
);

function PrivateRoute({ children, loginStatus, ...rest }) {
	let location = useLocation();
	const mediumViewport = useMediaQuery('(max-width:768px)');
	const [open, setOpen] = React.useState(true);
	const dispatch = useDispatch()
	const handleDrawerOpen = () => {
		setOpen(!open);
	};
	React.useEffect(() => {
		mediumViewport ? setOpen(false) : setOpen(true)
	}, [mediumViewport]) //check the view port and set the toggle open and true
	const DrawerHeader = styled('div')(({ theme }) => ({
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(0, 1),
		...theme.mixins.toolbar,
		justifyContent: 'flex-end',
	}));

	const handleTabSelect = (newValue) => {
		dispatch(setSelectedValue(newValue))
	};

	return (
		isSelfLogin() ?

			// checkCallback(authToken, appToken, authType, callback) ? (
			<Route
				{...rest}
				render={({ location }) =>
				(
					<div>
						<Box sx={{ display: 'flex' }} className="h-100">
							<Sidebar open={open} />
							<Main open={open} className="p-0 my-2 px-2 w-100 vh-100 overflow-auto" sx={{ background: `linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), url(${ArtistBackgroundImg})`, width: '100%', height: '100%' }}>
								<DrawerHeader className="p-0" />

								{children}
								<Footer />

							</Main>
						</Box>
					</div>
				)
				}
			/>
			: (
				<Redirect
					to={{
						pathname: "/login",
						state: { from: location }
					}}
				/>

			)


	);

}
export default PrivateRoute;
