import React,{useState,useEffect} from 'react'
import { useHistory } from 'react-router-dom';
import Songtable from './SongListTable';
import SubmitSongCard from './SongListCard';
import SearchBox from '../../components/reusableComponents/SearchBox';
import SwitcherBtn from '../../components/reusableComponents/SwitcherBtn';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLoginUser, setLoader,setSnackBar,setSnackBarMessage, setSnackBarVariant,fetchSongReleaseList } from '../../redux';
import { formSnackbar } from '../../_services/_constants/snackbar.constant';
import { submitSong } from '../../_services/submitAsong/submitAsong.service';
import {  Chip, useMediaQuery } from '@mui/material';
import SongFilters from './SongFilters';
import { default_limit } from '../../_services/_constants/recordLimitset.constant';
import { getSong } from '../../_services/submitAsong/showAllSong';


function SongList(props) {
    let history = useHistory();
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.userData.userData);
    const smScreen = useMediaQuery('(max-width:768px)')
    //to get the search input value
    const[searchInput, setSearchInput] = useState(null)
    const [layoutComponent,setLayoutComponent] = useState(true)
    const [songList, setSongList] = useState([])
    const [renderComponent, setRenderComponent] = useState()
    /* const [open, setOpen] = useState(false) */
    const [isFilterApplied, setIsFilterApplied] = useState(false)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(default_limit);
    const [isFilterIniated, setIsFilterInitiated] = useState(false)
    const [filterPayload, setFilterPayload] = useState(null)

    useEffect(function () 
    {
      if (userData.id) {
        dispatch(fetchLoginUser(userData.id));
        if (searchInput != null && searchInput.length > 0) {
          getSearchSongData(searchInput, userData.id);
        } else if (isFilterIniated) {
          applyAdvanceFilters(filterPayload);
        } else {
          getData(userData.id, page);
          //dispatch(fetchSongReleaseList());
        }
      }
      }, [userData.id, rowsPerPage, page]);

    function getData(userId,page) 
    {   
        const offset = page ;
        const limit = rowsPerPage;
        dispatch(setLoader(true))
        getSong('GET',userId,limit,offset).then(function (response) {
        dispatch(setLoader(false))  
        if(response.status===200) {
            setSongList(response.data)
            setPage(page);
            layoutComponent?setRenderComponent(<Songtable songListData = {response.data} 
              handleChangePage={handleChangePage} 
              handleChangeRowsPerPage={handleChangeRowsPerPage} 
              page={page} 
              rowsPerPage={rowsPerPage} />):setRenderComponent(<SubmitSongCard songListData = {response.data}
              handleChangePage={handleChangePage} 
              handleChangeRowsPerPage={handleChangeRowsPerPage} 
              page={page} 
              rowsPerPage={rowsPerPage}/> )
        }
        else{
            dispatch(setSnackBar(true));
            dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
            dispatch(setSnackBarVariant('error'));
            setTimeout(()=>dispatch(setSnackBar(false)),3000) 
        }
        }).catch(function (error) {
            dispatch(setLoader(false))
            dispatch(setSnackBar(true));
            dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
            dispatch(setSnackBarVariant('error'));
            setTimeout(()=>dispatch(setSnackBar(false)),3000) 
            return error.response;
        })
    }

    useEffect(()=>{
      if(!layoutComponent)
      { 
        setRenderComponent(<Songtable songListData = {songList} 
          handleChangePage={handleChangePage} 
          handleChangeRowsPerPage={handleChangeRowsPerPage} 
          page={page} 
          rowsPerPage={rowsPerPage}/>)
        setLayoutComponent(true)
      }
    },[smScreen])

    const getSearchSongData = (value)=>{
      const offset = page ;
      const limit = rowsPerPage;
    dispatch(setLoader(true))
      submitSong({'searchValue':value},'SEARCH',limit,offset).then(function (response) {
        dispatch(setLoader(false))
        if(response.status===200) {
            setSongList(response.data)
            setRenderComponent(null)
            setPage(page);
            copyHandleLayoutRerender(layoutComponent,response.data)
        }
        else{
            dispatch(setSnackBar(true));
            dispatch(setSnackBarMessage(formSnackbar.errors.imgUploadSize));
            dispatch(setSnackBarVariant('error'));
            setTimeout(()=>dispatch(setSnackBar(false)),3000) 
        }
        }).catch(function (error) {
            dispatch(setLoader(false))
            dispatch(setSnackBar(true));
            dispatch(setSnackBarMessage(formSnackbar.errors.imgUploadSize));
            dispatch(setSnackBarVariant('error'));
            setTimeout(()=>dispatch(setSnackBar(false)),3000) 
            return error.response;
        })
    }
    //   const handleChangeRowsPerPage = (event) => {
    //   const newRowsPerPage = parseInt(event.target.value, 10);
    //   const newPage = Math.floor((page * rowsPerPage) / newRowsPerPage);
    //   setRowsPerPage(newRowsPerPage);
    //   setPage(newPage);
    //   //getData(userData.id, newPage, newRowsPerPage);

    // };
    const handleChangeRowsPerPage = (event) => 
  {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);

    };

  
    const handleSearchInput = (value)=>{
     setSearchInput(value)     
    }

    useEffect(() => {
      const getData = setTimeout(() => {
        //searchInput!=null && getSearchSongData(searchInput,userData.id)  //commented the code to avoid multiple calls of same 
        if(page>0)
          page>0&&setPage(0)//to set offeset to 0 on every fresh search
        else
          searchInput!=null && getSearchSongData(searchInput,userData.id)
      }, 400)
  
      return () => clearTimeout(getData)
    }, [searchInput])

    const applyAdvanceFilters = (payload) => {
      const offset = page ;
      const limit = rowsPerPage;
      dispatch(setLoader(true))
     let dataObj = {...payload}
     // Clear the search input before applying filters
    setSearchInput(null);
      submitSong(dataObj,'ADVANCESEARCH',limit,offset).then(function (response) {
        dispatch(setLoader(false))
        if(response.status===200) {
            setIsFilterApplied(true)
            setSongList(response.data)
            setPage(page);
            //console.log('124',response.data.results)
            setRenderComponent(null)
            copyHandleLayoutRerender(layoutComponent,response.data)
          
        }
        else{
            dispatch(setSnackBar(true));
            dispatch(setSnackBarMessage(formSnackbar.errors.imgUploadSize));
            dispatch(setSnackBarVariant('error'));
            setTimeout(()=>dispatch(setSnackBar(false)),3000) 
        }
        }).catch(function (error) {
            dispatch(setLoader(false))
            dispatch(setSnackBar(true));
            dispatch(setSnackBarMessage(formSnackbar.errors.imgUploadSize));
            dispatch(setSnackBarVariant('error'));
            setTimeout(()=>dispatch(setSnackBar(false)),3000) 
            return error.response;
        })
    }
    const removeFilters = () =>{
      setIsFilterApplied(false)
      getSearchSongData(undefined,userData.id)
    }

    //to set the current display component card or table view
    const handleLayoutComponent = (currentComp)=>{
     setLayoutComponent(currentComp) 
     currentComp?setRenderComponent(<Songtable songListData = {songList} 
        handleChangePage={handleChangePage} 
        handleChangeRowsPerPage={handleChangeRowsPerPage} 
        page={page} 
        rowsPerPage={rowsPerPage}/>):setRenderComponent(<SubmitSongCard  songListData = {songList} 
        handleChangePage={handleChangePage} 
        handleChangeRowsPerPage={handleChangeRowsPerPage} 
        page={page} 
        rowsPerPage={rowsPerPage}/> )
    }
    //to render the component on search
    const copyHandleLayoutRerender = (currentComp, songData) =>{
      setLayoutComponent(currentComp) 
      currentComp?setRenderComponent(<Songtable songListData = {songData} 
        handleChangePage={handleChangePage} 
        handleChangeRowsPerPage={handleChangeRowsPerPage} 
        page={page} 
        rowsPerPage={rowsPerPage}/>):setRenderComponent(<SubmitSongCard  songListData = {songData} 
        handleChangePage={handleChangePage} 
        handleChangeRowsPerPage={handleChangeRowsPerPage} 
        page={page} 
        rowsPerPage={rowsPerPage}/> )
     
    }

  return (
    <div className={`row ${smScreen?'p-2':''}`} style={{padding:'1rem 1rem 3rem 1rem'}}>
        <h2 style={{marginLeft:'10px'}} >Song List</h2>
        <div className={`d-flex justify-content-between`}>
            <div className='flex-grow-1' style={{maxWidth:'18rem',marginLeft:'10px'}}><SearchBox  handleSearchInput={handleSearchInput} searchValue={searchInput!== null ? searchInput: ''}/></div>
            <div className='d-flex justify-content-end' style={{marginRight:'10px'}}>
              <div><SongFilters isFilterApplied={isFilterApplied} applyAdvanceFilters={applyAdvanceFilters}/></div>
              <div ><SwitcherBtn layoutComponent={layoutComponent} handleLayoutComponent={handleLayoutComponent}/></div>
            </div>
        </div>
        <div style={{display:'flex',justifyContent:'flex-end',marginTop:'20px'}}>
              {isFilterApplied?<Chip label="Clear all filters" onDelete={removeFilters} />:<p>Showing all data</p>}
        </div>
        <div>
          {/* {renderComponent} */}
        </div>  
    </div>
  )
}

export default SongList