import React, { useEffect, useState } from 'react';
import TableComponent from './Tablecomponent';
import { Box, Link, Paper, Typography } from '@mui/material';
export default function PaymentAddress({ studioDetail }) {

    const [payeeDetailList, setPayeeDetailList] = useState([])

    useEffect(() => {
        if (studioDetail.length) {
            const objectGet = studioDetail[0].payee_info.find(d => d.status == true)
            let responseData = objectGet != undefined ? [objectGet] : []
            setPayeeDetailList(responseData)
        }
    }, [studioDetail])
    const rows = payeeDetailList.length ? payeeDetailList : [];
    const columns = [
        { id: 'account_holder_name', field: "account_holder_name", label: 'Full Name' },
        { id: 'contact_email', field: "contact_email", label: 'Email' },
        { id: 'account_number', field: "account_number", label: 'Mobile No.' },
        { id: 'address', field: "address", label: 'Address' },
        { id: 'bank_city', field: "bank_city", label: 'City' },
        { id: 'zipcode', field: "zipcode", label: 'Zip Code' },
        { id: 'country_name', field: "country_name", label: 'Country' },
    ];

    return (
        <Paper sx={{ width: '100%', mb: 5, mt: 4 }}>
            <Typography variant='h6' className='p-3'>Payment Address</Typography>
            <TableComponent columns={columns} data={rows} />
        </Paper>
    );
}
