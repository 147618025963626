import axios from "axios";
import { getSelfToken } from "../../_helpers/auth/secureToken";
import { submitAsongURL } from '../_constants/api.constant'
export async function getSingleSongDetails(dataObj,actiontype){
    switch(actiontype)
    {
        case 'GET_SPECIFIC':
            {
                const response=await axios.get(
                submitAsongURL.GETSINGLESONG+'/'+dataObj.userId+'/',
                {
                    params:{song_id:dataObj.song_id},
                    headers:{
                        Authorization: 'Token '+getSelfToken(),
                    },
                }
                ).then(function(response) {
                return response;
                }).catch(function (error) {
                console.log(error);
                console.log(error.response);
                return error.response;
                });
                return response;
            }
        case 'GET_SPECIFIC_DISPLAY':
            {
                const response=await axios.get(
                submitAsongURL.GETSINGLESONGDISPLAY,
                {
                    params:{song_id:dataObj.song_id},
                    headers:{
                        Authorization: 'Token '+getSelfToken(),
                    },
                }
                ).then(function(response) {
                return response;
                }).catch(function (error) {
                console.log(error);
                console.log(error.response);
                return error.response;
                });
                return response;
            }
            default:{return}
    }
}