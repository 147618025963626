import { Grid, TablePagination } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ResponsiveCardList from '../../components/reusableComponents/songResponsiveList';
import useMediaQuery from '@mui/material/useMediaQuery'
import NoResults from '../../components/reusableComponents/NoResults';
import ArtistListCardLayout from '../../components/reusableComponents/ArtistListCardLayout';
import AlbumCard from './AlbumCard';
import { useSelector } from 'react-redux';
import { record_limits } from '../../_services/_constants/recordLimitset.constant';


const ReleaseCard = ({ albumListData, handleChangePage, handleChangeRowsPerPage, page, rowsPerPage }) => {
  const smScreen = useMediaQuery('(max-width:768px)');
  const [albumList, setAlbumList] = useState(albumListData.results)
  const subGenreList = useSelector((state) => state.musicCategorgyReducer?.subGenreData ? state.musicCategorgyReducer?.subGenreData.data : []);
  const genre = useSelector((state) => state.musicCategorgyReducer?.genreData ? state.musicCategorgyReducer?.genreData.data : []);
  useEffect(() => {
    setAlbumList(albumListData.results)
  })

  return (
    <div >
      {albumList && albumList.length > 0 ? (
        !smScreen ? (
          <div>
            <div className='wrapper-card py-4'>
              {albumList
                .map((album, index) => (

                  <AlbumCard
                    key={index}
                    albumInfo={album}
                    subGenreList={subGenreList}
                    genre={genre}
                  />

                ))}
            </div>
            <TablePagination
              rowsPerPageOptions={record_limits}
              colSpan={3}
              sx={{ width: '100%', marginTop: '20px', padding: '32px 0px 32px 32px' }}
              className="cardPagination"
              component="div"
              count={albumListData.total_count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
            />
          </div>
          // </Grid>
        ) : (
          <React.Fragment>
            {albumList
              .map((artist, index) => (
                <div key={artist.artist_id}>
                  <ResponsiveCardList
                    cardData={artist}
                    moduleName="artistList"
                  />
                </div>
              ))}
            <TablePagination
              rowsPerPageOptions={record_limits}
              colSpan={3}
              sx={{ width: '100%', marginTop: '20px', padding: '32px 0px 32px 32px' }}
              className="cardPagination"
              component="div"
              count={albumListData.total_count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
            />
          </React.Fragment>
        )
      ) : (
        <NoResults />
      )}
    </div>

  )
}//end

export default ReleaseCard