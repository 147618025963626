import React, { useEffect, useState } from 'react';
import TableComponent from './Tablecomponent';
import { Box, Link, Paper, Typography } from '@mui/material';
import { getSongStatusBgColor } from '../../../_helpers/reusablefunctions/getColor';
export default function PaymentDetail({ studioDetail }) {

    const [contractDetailList, setContractDetailList] = useState([])

    useEffect(() => {
        if (studioDetail.length) {
            const objectGet = studioDetail[0].payment.filter(d => d.status == "Success")
            setContractDetailList(objectGet)
        }
    }, [studioDetail])
    const rows = contractDetailList

    const columns = [
        { id: 'name', field: "name", label: 'Name ' },
        { id: 'payment_id', field: "payment_id", label: 'Payment Id ' },
        {
            id: 'Amount', label: 'Amount', headerName: '', flex: 1, template: (row) => {
                return (
                    <Box>{"Rs. " + row.Amount}</Box>
                );
            }
        },
        {
            id: 'status', label: 'Status', headerName: '', flex: 1, template: (row) => {
                return (
                    <Box className="status text-light p-2" style={{ background: getSongStatusBgColor(row.status), minWidth: '80px' }}>
                        {row.status}
                    </Box>
                );
            }
        },
    ];

    return (
        <Paper sx={{ width: '100%', mb: 5, mt: 4 }}>
            <Typography variant='h6' className='p-3'>Payment Detail</Typography>
            <TableComponent columns={columns} data={rows} />
        </Paper>
    );
}
