import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Radio, IconButton, Modal, Skeleton, useMediaQuery, TextField, Typography } from '@mui/material';
import album from '../../img/Frame 48096170.png';
// import { Edit, Public, Share as ShareIcon } from '@mui/icons-material';
// import SortSharpIcon from '@mui/icons-material/SortSharp';
// import CloseIcon from '@mui/icons-material/Close';
// import { Link, useHistory } from 'react-router-dom';
import AlbumSongItem from './AlbumSongItem';
// import AddSongToAlbum from './AddSongToAlbum';
import DeleteIcon from '@mui/icons-material/Delete';
import { setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
// import { formSnackbar } from '../../_services/_constants/snackbar.constant';
import { useHistory, useParams } from 'react-router-dom';
import { getSongListOfUser } from '../../_services/album/getSongListofUser';
import DeleteModal from '../../components/reusableComponents/DeleteModal';
import { useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import LockIcon from '@mui/icons-material/Lock';
import { getSingleAlbum } from '../../_services/album/getSingleAlbum';
import { formSnackbar } from '../../_services/_constants/snackbar.constant';
import AlbumApprovalModal from '../../components/reusableComponents/AlbumApprovalModal';
import { albumApprover } from '../../_services/songApprover/albumApprover';
import { getSongStatusBgColor } from '../../_helpers/reusablefunctions/getColor';
// import { artistCode } from '../submitSong/associatedArtist/artistStaticCodes';

function AlbumDetail({ }) {
  const userData = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch()
  const ref = useRef();
  // Get the userId param from the URL.
  let { albumId } = useParams();
  const lgScreen = useMediaQuery('(max-width:1280px)')
  const history = useHistory()
  const mdScreen = useMediaQuery('(max-width:768px)');
  const smScreen = useMediaQuery('(max-width:600px)');
  const widthValue = !mdScreen ? '40%' : '100%';
  const heightValue = !mdScreen ? 'calc(100vh - 200px)' : 'auto';
  const [open, setOpen] = React.useState(false);
  const [checkedCount, setCheckedCount] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [albumData, setAlbumData] = useState([]);
  const [isCopied, setIsCopied] = useState(false);
  const [albumSongLibrary, setAlbumSongLibrary] = useState([])
  const [isUpArrow, setIsUpArrow] = useState(false);
  const scrollRef = useRef(null);
  const [artistList, setArtistList] = useState([])
  const [primaryArtList, setPrimaryArtList] = React.useState([])
  const [producerList, setProducerList] = React.useState([])
  const [buttonText, setButtonText] = useState('Lock-in');
  const [selectedValue, setSelectedValue] = useState('True');
  const [comment, setComment] = useState('');
  const [isCommentValid, setIsCommentValid] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [error, setError] = useState('');
  const [openApprovalModal, setOpenApprovalModal] = useState(false)

  const handleOpen = () => {
    history.push("/addreleasesong/" + albumId)
  }

  const NoSongs = (<div className="d-flex flex-column align-items-center justify-content-center h-50">
    {/* <h4>No songs in this album yet</h4>
    <button className="gn-actionbtn m-4" onClick={handleOpen}>
      Add Track
    </button> */}
  </div>)


  // Function to get unmatched items from array1 compared to array2 based on id
  const getAlbumData = async () => {
    try {
      const response = await getSingleAlbum({ "album_id": albumId })
      const albumSongList = await getSongListOfUser({ "album_id": albumId })
      if (albumSongList) {
        console.debug("albumSongList", response.data)
        setAlbumData(response.data.data) //get the albumdata
        // sort based on re-ordered value
        let arrayData = albumSongList.data[0].song;
        const data = arrayData.sort(function (a, b) {
          return parseInt(a.order) - parseInt(b.order);
        });
        setAlbumSongLibrary(data)
      }
    }
    catch (error) {
      dispatch(setSnackBar(true))
      dispatch(setSnackBarVariant('error'))
      dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
      setTimeout(function () {
        dispatch(setSnackBar(false))
      }, 2000)
    }
  }
  //call the api to get the single album data
  React.useEffect(function () {
    if (userData.id) {
      getAlbumData()
      // getAssociatedArtist()
    }
  }, [userData.id, albumId])

  const handleLockInClick = () => {
    scrollRef.current.scrollIntoView({ behavior: 'smooth' });
  };


  const editSongFromPlayList = (songId, albumId) => {

    history.push("/submitsong/" + songId + "/" + albumId)
  }

  const handleCommentChange = (event) => {
    const inputValue = event.target.value;
    setComment(inputValue);
    setIsCommentValid(inputValue.length >= 30 && inputValue.length <= 1000);
  };

  const handleRadioButtonChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const frameWidth = 100; // Set your desired frame width
  const frameHeight = 200; // Set your desired frame height

  const frameStyle = {
    width: `${frameWidth}%`,
    height: `${frameHeight}px`,
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden', // Hide any overflowing content
  };

  const imageStyle = {
    maxWidth: '100%',
    maxHeight: '100%',
  };

  let submitButtonText = 'Ready For Release';
  if (selectedValue === 'True') {
    submitButtonText = 'Ready For Release';
  } else if (selectedValue === 'False') {
    submitButtonText = 'Reject';
  }

  //  if(albumData.song_status == "Ready for Release"){
  //   postData['comment'] = "Album is approved and released successfully";
  //   postData['song_status'] = 'Released';
  //   setButtonText('Ready for Release');
  // }
  const releaseSongLockinClickHandler = (e) => {
    e.preventDefault();
    if (albumId) {
      setButtonText('Ready for Release');
      var postData = {};
      postData['comment'] = "Album is ready for release";
      postData['song_status'] = 'Ready for Release';
      postData['album_id'] = albumId;
      dispatch(setSnackBar(true));
      dispatch(setSnackBarVariant('info'));
      dispatch(setSnackBarMessage(formSnackbar.songAprrovedStatus.statusUpdating));
      dispatch(setLoader(true));
      albumApprover(postData)
        .then(function (response) {
          if (response.status === 200) {
            dispatch(setLoader(false));
            setButtonText('Ready for Release')
            dispatch(setSnackBarVariant('success'));
            dispatch(setSnackBarMessage(formSnackbar.songAprrovedStatus.statusUpdatingSuccess));
            setTimeout(() => dispatch(setSnackBar(false)), 2000);
            // dispatch(fetchUserDetail());
            // getAlbumData()
          } else {
            dispatch(setLoader(false));
            dispatch(setSnackBarVariant('error'));
            dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure));
            setTimeout(() => dispatch(setSnackBar(false)), 3000);
          }
        })
        .catch((error) => {
          dispatch(setLoader(false));
          dispatch(setSnackBarVariant('error'));
          dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure));
          setTimeout(() => dispatch(setSnackBar(false)), 3000);
        });
    }
  }

  const releaseAlbumFinalClickHandler = (e) => {
    e.preventDefault();
    if (albumId) {
      var postData = {};
      // if(albumData.song_status == "In Review"){ 
      // postData['comment'] = "Album is ready for release";
      // postData['song_status'] = 'Ready for Release';

      // setButtonText('Ready for Release');
      postData['comment'] = "Album is approved and released successfully";
      postData['song_status'] = 'Released';
      // postData['approver_user_id'] = userData.id;
      postData['album_id'] = albumId;
      setButtonText('Ready for Release');
      // }
      // if(albumData.song_status == "Ready for Release"){
      //   postData['comment'] = "Album is approved and released successfully";
      //   postData['song_status'] = 'Released';
      //   setButtonText('Ready for Release');
      // }
      // postData['approver_user_id'] = userData.id;
      // postData['album_id'] = albumId;
      dispatch(setSnackBar(true));
      dispatch(setSnackBarVariant('info'));
      dispatch(setSnackBarMessage(formSnackbar.songAprrovedStatus.statusUpdating));
      dispatch(setLoader(true));
      albumApprover(albumId, postData)
        .then(function (response) {
          if (response.status === 200) {
            dispatch(setLoader(false));
            dispatch(setSnackBarVariant('success'));
            dispatch(setSnackBarMessage(formSnackbar.songAprrovedStatus.statusUpdatingSuccess));
            setTimeout(() => dispatch(setSnackBar(false)), 2000);
            // dispatch(fetchUserDetail());
            getAlbumData()
          } else {
            dispatch(setLoader(false));
            dispatch(setSnackBarVariant('error'));
            dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure));
            setTimeout(() => dispatch(setSnackBar(false)), 3000);
          }
        })
        .catch((error) => {
          dispatch(setLoader(false));
          dispatch(setSnackBarVariant('error'));
          dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure));
          setTimeout(() => dispatch(setSnackBar(false)), 3000);
        });
    }
  }


  return (
    <div className="container-fluid py-4 creator-album-panel" style={{ overflowY: 'hidden' }}>
      <div className="row m-0">
        <div className="col-12">
          <div>
            <h2>Release Album</h2>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            {albumData.song_status == "In Review"? <button
              className="gn-actionbtn"
              onClick={(e) => releaseSongLockinClickHandler(e)}
              disabled={albumSongLibrary.length > 0 && albumSongLibrary.every(item => item.song.song_status === 'Ready for Release') ? false : true}>
              Lock In
            </button> : <><button
              className="gn-actionbtn"
              // onClick={(e) => releaseAlbumFinalClickHandler(e)}
              disabled={albumSongLibrary.length > 0 && albumSongLibrary.every(item => item.song.song_status === 'Ready for Release') ? false : true}>
              {albumData.song_status == "In Review" ? "Lock in" : albumData.song_status == "Ready for Release" ? "Ready for Release" : ""}
            </button>
            <div className='fs-08 status text-light' style={{ backgroundColor: getSongStatusBgColor(albumData.song_status) }}>{albumData.song_status}</div></>}
          </div>
          <div className='d-flex' style={{ flexWrap: mdScreen ? 'wrap' : 'nowrap' }}>
            <Box
              sx={{

                width: widthValue,
                // height: heightValue,
                minWidth: smScreen ? '100%' : '350px',
                maxWidth: smScreen ? '100%' : '500px',
                border: '1px solid #fff',
                borderRadius: '6px',
                background: 'linear-gradient(rgba(75, 68, 83, 1),rgba(132, 94, 194, 0.97),rgba(198, 114, 179, 0.68),rgba(255, 128, 102, 0.61))'
                // background: 'linear-gradient(180.31deg, rgb(0 0 0 / 88%) 31.39%, #41464bd6 70.6%)'
              }}
            >
              <div className='p-2'>
                <div style={frameStyle}>
                  <img
                    src={albumData && albumData.cover_image ? process.env.REACT_APP_SONGAPIURL_MEDIA + 'media/' + albumData.cover_image : album}
                    alt=""
                    style={imageStyle}
                  />
                </div>
                <div className='text-light mt-4 d-flex justify-content-between'>
                  <div className="d-flex align-items-center">
                    <h6 style={{ fontSize: '20px', margin: '0', width: '180px' }}>{albumData?.album_name}</h6>
                  </div>
                </div>
                <div className='mt-2 d-flex text-light'>
                  <div>
                    <span style={{ fontSize: '16px' }}>{albumData && albumData.song_status != undefined && `Status: ${albumData.song_status}`}</span>
                  </div>

                </div>
              </div>
            </Box>
            <div style={{ paddingLeft: mdScreen ? '' : '2rem', width: '100%' }}>
              <Box
                sx={{
                  width: '100%',
                  border: '1px solid #fff',
                  borderRadius: '6px',
                  overflowX: 'hidden',
                  maxHeight: 'calc(100vh - 200px)'
                }}
              >
                {albumSongLibrary && Object.values(albumSongLibrary).length > 0 ? <>{albumSongLibrary.length ? <>
                  <DragDropContext >
                    <Droppable droppableId="card-list">
                      {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          {albumSongLibrary && albumSongLibrary.map((card, index) => (
                            <Draggable key={index} draggableId={index.toString()} index={index}>
                              {(provided) => (
                                <div >
                                  <AlbumSongItem songData={card} editSongFromPlayList={editSongFromPlayList} albumData={albumData} albumId={albumId} provided={provided} />
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </> : NoSongs}</>
                  :
                  NoSongs
                }
              </Box>
            </div>
          </div>
        </div>
      </div>
      {/* <AlbumApprovalModal openApprovalModal={openApprovalModal} setOpenApprovalModal={setOpenApprovalModal} albumData={albumData} handleRadioButtonChange={handleRadioButtonChange} selectedValue={selectedValue} 
      buttonText={buttonText}error={error} comment={comment} isSubmitDisabled={isSubmitDisabled} handleCommentChange={handleCommentChange} 
      submitButtonText={submitButtonText} smScreen={smScreen} lgScreen={lgScreen} scrollRef={scrollRef} />  */}
    </div>
  );
}

export default AlbumDetail;
