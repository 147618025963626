import * as React from 'react';
import Box from '@mui/material/Box';
import { Paper, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import SnackbarContainer from '../Snackbar';
import { getDateAsPerFormat } from '../../_helpers/reusablefunctions/dateSetter';
import MenuItemList from './MenuItemList';
import { useHistory, } from 'react-router-dom';
import TableComponent from './Tablecomponent';

export default function ImportRevenueDataTable({ demoListData, setRowsPerPage, setPage, page, rowsPerPage, handleDelete }) {
  const [rows, setRows] = useState(demoListData?.results);
  const [totalCount, setTotalCount] = useState('');
  const history = useHistory()

  useEffect(() => {
    setRows(demoListData?.results)
    setTotalCount(demoListData?.total_count)
  }, [demoListData])

  const columns = [
    {
      id: 'uploader_name', label: 'Name', headerName: '', flex: 1, template: (row) => {
        return (
          <Box className="cursorPointer" onClick={(e) => viewRevenueHandler(e, row)}>
            <Typography variant='span'>{row.uploader_name}</Typography>
          </Box>
        )
      }
    },
    {
      id: 'created_at', label: 'IMPORT DATE', headerName: '', flex: 1, template: (row) => {
        return (
          <Box >
            <Typography variant='span'>{row.created_at ? getDateAsPerFormat(row.created_at, "DD Mon, YYYY") : ''}</Typography>
          </Box>
        )
      }
    },
    { id: 'file', field: 'file', label: 'EXCEL FILE', width: 250, },
    {
      id: 'statement_period', label: 'STATEMENT PERIOD', headerName: '', flex: 1, template: (row) => {
        return (
          <Box >
            <Typography variant='span'>{row.from_date ? getDateAsPerFormat(row.from_date, "Mon YYYY") + " - " + getDateAsPerFormat(row.to_date, "Mon YYYY") : ''}</Typography>
          </Box>
        )
      }
    },
    {
      id: 'action', label: 'Action', headerName: '', flex: 1, template: (row) => {
        return (
          <MenuItemList demoListData={demoListData} data={row} viewRevenueHandler={viewRevenueHandler} handleDelete={handleDelete} />
        )
      }
    }
  ];

  const viewRevenueHandler = (e, data) => {
    localStorage.setItem("revenueData", JSON.stringify(data));
    history.push('/viewrevenue');
  }

  return (
    <div className="container-fluid creator-playlist-panel">
      <Paper className="shadow-table" sx={{ width: '100%', mb: '3rem' }}>
        <TableComponent columns={columns} data={rows} page={page} setPage={setPage} removeStyle={'removeStyle'} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} totalCount={totalCount} />
      </Paper>
      <SnackbarContainer />
    </div>
  );
}