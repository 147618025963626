import React, { useState, useEffect } from 'react';
import { Box, ThemeProvider, Typography, createTheme, useMediaQuery, useTheme } from '@mui/material';
import './customStyle.css'
import { getCreatorAnalyticsData } from '../../_services/dashbaord/creator/creatorData.service';
import { getStudioAnalyticsData } from '../../_services/dashbaord/studio/studioData.server';
import login from '../../img/dashboardIcons/login.png'
import microphone from '../../img/dashboardIcons/microphone.png'
import user from '../../img/dashboardIcons/user.png'
import registration from '../../img/dashboardIcons/registration.png'
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from '../../redux';
import Royalty from '../royalty/Royalty';
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      padding: 4
    },
  },
});
const gridStyle = {
  width: '65px',
  height: '65px',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}
function Dashboard(props) {
  const smScreen = useMediaQuery('(max-width:768px)')
  const [creatorAnalyticData, setCreatorAnalyticData] = useState([]);
  const [studioAnalyticData, setStudioAnalyticData] = useState([]);
  const userData = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch()
  const selectedValue = useSelector((state) => state.loader.switchedValue)

  function formateCreatorStudio(value, label) {
    const formattedData = [
      {
        'label': label,
        'value': label == "Active Creators" ? value.active_artists : value.active_studios,
        'icon': label == "Active Creators" ? user : microphone,
        'cardbg': label == "Active Creators" ? '#E6EEEF' : '#F1E7E8',
        'bgcolor': label == "Active Creators" ? '#13ACCD' : '#ED3237',
        'padding': ''
      },
      {
        'label': label == "Active Creators" ? 'Creator Total registrations' : 'Studio Total registrations',
        'value': label == "Active Creators" ? value.total_registered_artists : value.total_registered_studios,
        'icon': label == "Active Creators" ? login : registration,
        'cardbg': label == "Active Creators" ? '#F1EFE6' : '#F1EAE7',
        'bgcolor': label == "Active Creators" ? '#FF0D37' : '#FF7B08',
        'padding': ''
      }
    ]
    return formattedData;
  }

  useEffect(function () {
    dispatch(setLoader(true))
    getCreatorAnalyticsData()
      .then(function (response) {
        if (response.data && Object.values(response.data).length > 0) {
          const formattedData = formateCreatorStudio(response.data, 'Active Creators');
          setCreatorAnalyticData(formattedData)
          dispatch(setLoader(false))
        }
      })
      .catch(function (error) {
        console.log('Something went wrong!')
        dispatch(setLoader(false))
      })

    getStudioAnalyticsData()
      .then(function (response) {
        if (response.data && Object.values(response.data).length > 0) {
          const formattedData = formateCreatorStudio(response.data, 'Active Studios');
          setStudioAnalyticData(formattedData)
          dispatch(setLoader(false))
        }
      })
      .catch(function (error) {
        console.log('Something went wrong!')
        dispatch(setLoader(false))
      })
  }, [])


  const dasboardCard = (item, index) => {
    return (
      <Box sx={{ width: '25%', display: 'flex', alignItems: 'center', gap: '10px', backgroundColor: item.cardbg, padding: '15px', borderRadius: '16px' }}>
        <Box className={`rounded-pill ${item.padding}`} sx={{ ...gridStyle, backgroundColor: item.bgcolor }}><img src={item.icon} alt='table-view-icon' /></Box>
        <Box>
          <Typography variant="h6" fontSize={'14px'} fontWeight={'500'}>{item.label}</Typography>
          <Typography className='' variant="h4" fontSize={'24px'} fontWeight={'600'}>{item.value}</Typography>
        </Box>
      </Box>
    )
  }

  return (
    <div className={smScreen ? 'p-2' : 'p-4'}>
      <ThemeProvider theme={theme}  >
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div>
            <h2>Dashboard</h2>
          </div>
        </div>
        <div className="d-flex align-items-center gap-3">
          {studioAnalyticData && studioAnalyticData.map((item, index) => (
            dasboardCard(item, index)
          ))}
          {creatorAnalyticData && creatorAnalyticData.map((item, index) => (
            dasboardCard(item, index)
          ))}
        </div>
      </ThemeProvider>
      <Box className="mt-3">
        <Royalty hidePadding={true} title="Royalty" />
      </Box>
    </div>

  );
}

export default Dashboard;
