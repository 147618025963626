import React, { useEffect, useState } from 'react';
import TableComponent from './Tablecomponent';
import { Box, Link, Paper, Typography } from '@mui/material';
export default function AccountDetail({ studioDetail }) {

    const [payeeDetailList, setPayeeDetailList] = useState([])

    useEffect(() => {
        if (studioDetail.length) {
            const objectGet = studioDetail[0].payee_info.find(d => d.status == true)
            let responseData = objectGet != undefined ? [objectGet] : []
            setPayeeDetailList(responseData)
        }
    }, [studioDetail])
    const rows = payeeDetailList.length ? payeeDetailList : [];

    const columns = [
        { id: 'payment_currency', field: "payment_currency", label: 'Currency Type ' },
        // { id: 'swift', field: "swift", label: 'Swift' },
        { id: 'account_holder_name', field: "account_holder_name", label: 'Name on Account' },
        { id: 'bank_name', field: "bank_name", label: 'Bank Name' },
        { id: 'account_number', field: "account_number", label: 'Account Number' },
        { id: 'bank_address', field: "bank_address", label: 'Bank Address' },
        { id: 'bank_phone_number', field: "bank_phone_number", label: 'Bank Phone Number' },
        { id: 'bank_city', field: "bank_city", label: 'Account City' },
    ];

    return (
        <Paper sx={{ width: '100%', mb: 5, mt: 4 }}>
            <Typography variant='h6' className='p-3'>Account Detail</Typography>
            <TableComponent columns={columns} data={rows} />
        </Paper>
    );
}
