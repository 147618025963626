import axios from "axios";
import {getSelfToken} from '../../_helpers/auth/secureToken'
export async function forgotPassword(postData){
    const response=await axios.post(
        process.env.REACT_APP_BASEURL + 'user/reset-password',//" https://userauth_gnapi.eela.tech/api/v1/password/email",
       postData,
        {
            /* headers:{
                Authorization: 'Token '+getSelfToken(),
            }, */
        }
        ).then(function(response) {
        return response;
        }).catch(function (error) {
        // handle error
        console.log(error);
        console.log(error.response);
        return error.response;
        });
    return response;
}