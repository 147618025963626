
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Chip, Paper, Tooltip, Typography } from '@mui/material';
import SnackbarContainer from '../Snackbar';
import { getDateAsPerFormat } from '../../_helpers/reusablefunctions/dateSetter';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import artist from '../../img/userAdmin.png'
import { verificationStatusColor } from '../../components/reusableComponents/verificationStatusColor';
import TableComponent from '../importRevenue/Tablecomponent';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

export default function ArtistTable({ artistListData, page, rowsPerPage, setRowsPerPage, setPage }) {
  const [rows, setRows] = useState(artistListData.results);
  const [totalCount, setTotalCount] = useState('');
  const history = useHistory();
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    setRows(artistListData.results)
    setTotalCount(artistListData?.total_count)
  }, [artistListData])

  const columns = [
    {
      id: 'name',
      label: 'Name',
      headerName: '',
      flex: 1,
      template: (row) => {
        return (
          <Box className="d-flex align-items-center gap-2 cursorPointer" onClick={() => verifyHandler(row.userHandle)}>
            <img className="rounded-pill" src={row.avatar ? process.env.REACT_APP_CREATORURL_MEDIA + 'media/' + row.avatar : artist} style={{ width: '40px', height: '40px' }} />
            <Typography variant='span'>{row.name}</Typography>
          </Box>
        );
      },
    },
    {
      id: 'calim',
      label: 'Claimed Profile',
      headerName: '',
      flex: 1,
      template: (row) => {
        return (
          <Box>
           {row.claim == true ? <CheckCircleIcon style={{ color: 'green' }} />:
            <CancelIcon style={{ color: 'red' }} />}
          </Box>
        );
      },
    },
    {
      id: 'email',
      label: 'Email',
      headerName: '',
      flex: 1,
      template: (row) => {
        return (
          <Box>
            {row.email && row.email}
          </Box>
        );
      },
    },
    {
      id: 'date_joined',
      label: 'Date',
      headerName: '',
      flex: 1,
      template: (row) => {
        return (
          <Box>
            {getDateAsPerFormat(row.date_joined, "DD Mon, YYYY")}
          </Box>
        );
      },
    },
    {
      id: 'verificationStatus',
      label: 'Status',
      headerName: '',
      flex: 1,
      template: (row) => {
        return (
          <Box className={`${verificationStatusColor(row && row.verification_status && row.verification_status.verificationStatus && row.verification_status.verificationStatus)} verifyStatus  `}
          >
            {row.verification_status ? row.verification_status.verificationStatus : 'Unverified'}
          </Box>
        );
      },
    },
  ];

  const verifyHandler = (userHandle) => {
    history.push(`/artistdetail/${userHandle}`)
  }


  return (
    <div className="container-fluid py-4 creator-playlist-panel">
      <Paper className="shadow-table" sx={{ width: '100%', mb: '3rem' }}>
        <TableComponent columns={columns} data={rows} page={page} setPage={setPage} removeStyle={'removeStyle'} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} totalCount={totalCount} />
      </Paper>
      <SnackbarContainer />
    </div>
  );
}